import React from 'react';
import {ConfirmButton} from "../../PlattixUI/core/components/Buttons";
import {doPost, isHttpError} from "../../PlattixUI/PlattixReactCore/api/Api";
import {Endpoints} from "../../configuration/ApiEnpointMap";

interface test {
    name: string
}

export function OcrTest() {
    
    async function doTest() {
        const Response = await doPost<{name: string}>(Endpoints.Admin.TestOcr)
        
        if (isHttpError(Response)) {
            return;
        }
        
        
    }
    
    return (
        <>
            <h1>Test OCR</h1>
            <ConfirmButton onClick={doTest}>hallo</ConfirmButton>
        </>
    )


}