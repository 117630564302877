import {DocumentScanDocument, DocumentScanProduct} from "./types/Document";
import React, {useCallback} from "react";
import {PlattixSubmitField, PlattixSubmitForm} from "../../PlattixUI/core/components/form/Form";
import {FormGridColDef} from "./types/FormColDef";
import {PlattixCard} from "../../PlattixUI/core/components/ContentCard";
import {PlattixTextTitle} from "../../PlattixUI/core/components/Text";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {styled} from "goober";
import {dataGridStyling} from "./DocumentScanGridStyling";
import DocumentScanProductPanelContent from "./DocumentScanProductPanelContent";


const DataGridDetailContainer = styled('div')`
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    gap: 25px;
`;

interface DocumentScanDetailCardProps<TModel extends DocumentScanDocument> {
    data?: TModel,
    readonly: boolean,
    formTitle?: string,
    formDescription?: string,
    documentDetailsTitle?: string,
    documentDetailsDescription?: string,
    header: string,
    fields: PlattixSubmitField<TModel>[],
    gridColumns?: FormGridColDef<DocumentScanProduct>[],
    postUrl?: string,
    onSuccess?: (data: any) => void,
}

function DocumentScanDetailCard<TModel extends DocumentScanDocument>(props: DocumentScanDetailCardProps<TModel>) {
    const getDetailPanelHeight = useCallback(() => 600, []);

    const getDetailPanelContent = useCallback(
        (props) => <DocumentScanProductPanelContent row={props.row} columns={props.columns}/>, [],
    );

    return <PlattixCard header={props.header} collapsible className={'content-column-span2'}>
        {
            props.formTitle &&
            <PlattixTextTitle alignment={'center'} description={props.formDescription ? props.formDescription : ''}>
                {props.formTitle}
            </PlattixTextTitle>
        }
        <DataGridDetailContainer>
            <PlattixSubmitForm<TModel, TModel>
                formName={props.readonly ? 'documentScanDetailForm' : ''}
                fields={props.fields}
                readOnly={props.readonly}
                defaultValue={props.data}
                postUrl={props.postUrl ? props.postUrl : ''}
                onSuccess={props.onSuccess}
            />
            <hr/>
            {
                props.documentDetailsTitle &&
                <PlattixTextTitle
                    alignment={'center'}
                    description={props.documentDetailsDescription ? props.documentDetailsDescription : ''}
                >
                    {props.documentDetailsTitle}
                </PlattixTextTitle>
            }
            <DataGridPro
                loading={false}
                rows={props?.data?.documentDetails ?? []}
                columns={props.gridColumns ?? []}
                autoHeight
                sx={dataGridStyling}
                rowHeight={40}
                headerHeight={40}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
            />
        </DataGridDetailContainer>
    </PlattixCard>
}

export default DocumentScanDetailCard;