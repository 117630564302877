import {
    DataGridPro,
    DataGridProProps,
    deDE,
    enUS,
    frFR,
    GridActionsCellItem,
    GridCallbackDetails,
    GridDensity,
    GridFilterModel,
    GridInputSelectionModel,
    GridRowOrderChangeParams,
    GridRowParams,
    GridSelectionModel,
    GridSlotsComponentsProps,
    GridSortModel,
    MuiEvent,
    nlNL
} from '@mui/x-data-grid-pro';
import {useQuery, useQueryClient} from '@tanstack/react-query'
import * as React from "react";
import {useEffect, useState} from "react";
import {GridConfig, ServerSideDataModel, ServersideDataProps} from "PlattixUI/core/grid/types/GridTypes";
import {fetchGridConfig, fetchGridFilterOperatorsConfig, fetchServersideData} from './gridApi';
import {useAppSelector} from "PlattixUI/PlattixReactCore/hooks";
import {isInRoleSelector, userLangCodeSelector} from "PlattixUI/PlattixReactCore/UserSlice";
import {GridRowOptions} from "PlattixUI/core/grid/gridStyling/GridOptions";
import {GetRowOptions} from "PlattixUI/core/grid/CellRenderers";
import {GridActionsColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import useDebounce from "PlattixUI/util/useDebounce";
import {SearchToolbar} from "PlattixUI/core/grid/SearchToolbar";
import {GridFilterConfig} from "PlattixUI/core/grid/types/GridFilterTypes";
import {dataGridStyling} from "./gridStyling/GridStyling";
import {QueryApiCallWithConfirmProps} from "PlattixUI/PlattixReactCore/api/ApiWithSwal";
import {history} from 'PlattixUI/PlattixReactCore/store'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {Popover, Typography,} from "@mui/material";
import {doPost, isHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {toast} from "react-toastify";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {GridCellParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {GridEventListener} from "@mui/x-data-grid/models/events";
import {DeveloperInfo} from "PlattixUI/core/components/ContentCard";
import {GridApiPro} from "@mui/x-data-grid-pro/models/gridApiPro";
import {generatePath} from 'react-router-dom';
import {DeveloperRouteMap} from "../DefaultMenu/RouteMap";

export type GridProps = {
    gridCode: string,
    parameters?: { [key: string]: string }
    rowOptions?: GridRowOptions,

    /**
     * Doorgeven van Mui Data Grid Selection Model
     */
    selectionModel?: GridInputSelectionModel,
    /**
     * Doorgeven van Mui onSelectionChanged
     */
    onSelectionChanged?: (selectionModel: GridSelectionModel, details: GridCallbackDetails) => void,
    /**
     * Mag meer dan 1 rij geselecteerd wroden
     */
    allowMultiSelect?: boolean,
    /**
     * Moet er een kolom met checkboxes toegegoegd worden
     */
    showCheckbox?: boolean,

    /**
     * Focus de quick search bij het renderen van de grid
     */
    focusQuickSearch?: boolean,

    /**
     * Set the default page size
     */
    defaultPageSize?: number,

    /**
     * Set the default grid density
     */
    defaultDensity?: GridDensity,

    /**
     * Include the language code as a parameter on SS Request
     */
    includeLanguageCode?: boolean,
    /**
     * Show a simple delete button that will do a DELETE on the url returned by the {@link deleteUrlGenerator}
     */
    showDeleteButton?: boolean,
    /**
     * Should the delete button be shown as an option in the dropdown
     * instead of an always visible trashcan icon (default)
     */
    showDeleteInDropdown?: boolean,
    /**
     * Function that will generate the DELETE route given the row data
     * @param row: Row data
     */
    deleteUrlGenerator?: (row: GridRowParams) => string,
    /**
     * Provide a custom confirmation message
     */
    deleteTitle?: string,
    /**
     * Set more options to the Delete Swal. {@link QueryApiCallWithConfirmProps}
     */
    deleteWithConfirmProps?: QueryApiCallWithConfirmProps<GridRowParams>

    /**
     * Show a simple edit button
     */
    showEditButton?: true,
    /**
     * Should the edit button be shown as an option in the dropdown
     * instead of an always visible trashcan icon (default)
     */
    showEditInDropdown?: boolean,
    /**
     * If the function returns a string, the user will be redirected to the URL represented by the string.
     * If provided function returns nothing, the function will be called on the button click
     * @param row: Grid Row data
     */
    onEdit?:
        | ((row: GridRowParams) => string)
        | ((row: GridRowParams) => void)
    /**
     * Should the {@link onEdit} event be fired when a row is double clicked
     * Default: false
     */
    editOnRowDoubleClick?: boolean,
    
    getDetailPanelContent?: (params: GridRowParams) => React.ReactNode,
    getDetailPanelHeight?: (params: GridRowParams) => number | 'auto';

    /**
     * Hide the toolbar
     */
    hideToolbar?: boolean;
    
    /**
     * Callback fired when a double click event comes from a row container element.
     * @param {GridRowParams} params With all properties from [[RowParams]].
     * @param {MuiEvent<React.MouseEvent>} event The event object.
     * @param {GridCallbackDetails} details Additional details for this callback.
     */
    onRowDoubleClick?: GridEventListener<'rowDoubleClick'>;
    
    /**
     * Run a function when a row is double clicked
     */
    onCellDoubleClick?: (params: GridCellParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => void,
    componentsProps?:  GridSlotsComponentsProps,
    
    apiRef?: React.MutableRefObject<GridApiPro> | undefined;
} & (
    {
        showDeleteButton: true,
        showDeleteInDropdown?: boolean,
        deleteUrlGenerator: (row: GridRowParams) => string,
        deleteTitle?: string,
        deleteWithConfirmProps?: QueryApiCallWithConfirmProps<GridRowParams>
    } | { showDeleteButton?: false }
    ) & (
    {
        showEditButton: true,
        showEditInDropdown?: boolean,
        onEdit:
            | ((row: GridRowParams) => string)
            | ((row: GridRowParams) => void)
        editOnRowDoubleClick?: boolean
    } | { showEditButton?: false }
    ) & (
    {
        /**
         * Allow the rows to be reordered
         *
         * Enabling this, will disable filters and pagination
         */
        rowReordering: true,

        /**
         * Column that should be used for the row ordering
         */
        reorderColumn: string,

        /**
         * Url naarwaar een post gedaan moet worden om de nieuwe volgorde op te slaan
         */
        rowReorderUrl?: string,

        /**
         * Callback die uitgevoerd wordt na een reorder
         * @param params
         * @param event
         * @param details
         */
        onRowOrderChange?: (params: GridRowOrderChangeParams, event: MuiEvent<{}>, details: GridCallbackDetails) => void,

        onRowDragStart?: () => void,
        onRowDragOver?: () => void,
        onRowDragEnd?: () => void,
    } | { rowReordering?: false }
    )

export function PlattixDataGrid(props: GridProps) {
    
    // region States en hooks

    /* Ophalen van de taal van de gebruiker. */
    const userLang = useAppSelector(userLangCodeSelector);

    /* States voor de huidige paginering. */
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(props.defaultPageSize ?? 20);

    /* State voor de sortering van een column. */
    const [gridSortModel, setGridSortModel] = useState<GridSortModel>([]);

    /* States voor het zoeken en de filtering. */
    const [useQuickSearch, setUseQuickSearch] = useState(true);
    const [advancedFilter, setAdvancedFilter] = useState<GridFilterModel>({items: []});
    const [advancedFilterJson, setAdvancedFilterJson] = useState("");
    const [quickSearchText, setQuickSearchText] = React.useState('');
    const [quickSearchRegex, setQuickSearchRegex] = React.useState(false);
    const [quickSearchCaseSensitive, setQuickSearchCaseSensitive] = React.useState(false);

    /* Opvangen van zoeken en filtering. */
    const debouncedQuickSearchText = useDebounce(quickSearchText, 250);
    const debouncedAdvancedFilterJson = useDebounce(advancedFilterJson, 250);

    /* State voor de gridId. */
    const [gridId, setGridId] = useState<number | null>(null);
    
    // endregion
    
    // region Queries

    /* Hook om te communiceren met useQuery. */
    const queryClient = useQueryClient();

    /* Functie met doGet request voor het ophalen van de ingestelde configuratie van het grid, op basis van de gridcode. */
    async function getGridConfig() {
        /* Ophalen van de data. */
        const response = await fetchGridConfig(props.gridCode, filterConfig.data)

        /* Instellen van de actions-column wanneer er kolommen zijn ingesteld en er buttons getoond mogen worden. */
        if (!!response.columns?.length && (props.rowOptions?.length || props.showEditButton || props.showDeleteButton)) {
            const actionColumn: GridActionsColDef = {
                field: 'actions',
                type: "actions",
                width: 100,
                getActions: (cell) => GetRowOptions(props, cell, reloadData),
            }

            response.columns.push(actionColumn)
        }

        /* Instellen van de minWidth voor elke column. */
        response.columns.forEach((col) => {
            col.minWidth = 100;
        });

        /* Als de rowReordering ingesteld staat, de column sortering en filtering uitschakelen. De rowReordering zorgt ervoor dat de rows verplaatst kunnen worden van volgorde. */
        if (props.rowReordering) {
            response.columns.forEach((col) => {
                col.sortable = false;
                col.filterable = false;
            });
        }

        /* Instellen van de gridId. */
        setGridId(response.gridId);

        return response;
    }

    /* Configuratie ophalen voor de filtering. */
    const filterConfig = useQuery<GridFilterConfig>(
        ['gridFilterConfig', userLang],
        fetchGridFilterOperatorsConfig,
        {
            // staleTime
            initialData: undefined,
            refetchOnWindowFocus: false,
            staleTime: 30 * 60 * 1000,
            cacheTime: 300 * 60 * 1000,

        }
    );

    /* Ophalen van de configuratie van de grid. */
    const configQuery = useQuery<GridConfig>(
        ['gridConfig', props.gridCode, filterConfig.data?.operators],
        getGridConfig,
        {
            // enabled: !!filterConfig.data?.operators?.length
            refetchOnWindowFocus: false,
            enabled: !!filterConfig.data?.dataTypes?.length,
            staleTime: 300_000,
            cacheTime: 30 * 60 * 1000
        }
    );

    useEffect(() => {
        console.log(`configQuery.data:`, configQuery.data);
    }, [configQuery.data]);

    /* Instellen van de parameters om de data voor in de grid te kunnen ophalen. */
    function getServersideDataParameters(): ServersideDataProps {
        const params: ServersideDataProps = {
            gridCode: props.gridCode,
            page: page,
            pageSize: pageSize,
            sort: gridSortModel,
            parameters: props.parameters ?? {},

            useQuickSearch: useQuickSearch,
            quickSearch: {
                regex: quickSearchRegex,
                caseSensitive: quickSearchCaseSensitive,
                value: debouncedQuickSearchText
            },
            advancedSearch: {
                logic: advancedFilter.linkOperator ?? "or",
                criteria: advancedFilter.items.map(item => {
                    return {
                        value: Array.isArray(item.value) ? item.value : [item.value],
                        columnName: item.columnField,
                        condition: Number(item.operatorValue)
                    }
                })
            },
        }
        if (props.includeLanguageCode) {
            params.parameters['paramlanguagecode'] = userLang
        }

        return params
    }

    /* Ophalen van de data om weer te geven in de datagrid. */
    const dataQuery = useQuery<ServerSideDataModel>(
        [
            'gridData',
            props.gridCode,
            // page, // Dit geeft problemen met de paginering, workaround rond geschreven in een useEffect
            pageSize,
            gridSortModel,
            userLang,
            props.parameters,
            debouncedQuickSearchText,
            quickSearchRegex,
            quickSearchCaseSensitive,
            debouncedAdvancedFilterJson
        ],
        () => fetchServersideData(getServersideDataParameters(), props),
        {
            keepPreviousData: true,
            initialData: {
                data: [],
                recordsFiltered: pageSize,
                recordsTotal: pageSize
            },
            enabled: !!configQuery?.data?.columns?.length,
            // enabled: false,
            // staleTime: 5_000,
            cacheTime: 30_000
        });

    // useEffect(() => {
    //     console.log(`dataQuery:`, props.gridCode, dataQuery.data, dataQuery.data?.data[0]?.selected !== undefined);
    // }, [dataQuery.data]);
    
    // endregion
    
    // region Handlers

    /* Functie om de grid data te herladen. */
    function reloadData() {
        queryClient.invalidateQueries(['gridData', props.gridCode])
    }

    /* Functie dat de dubbelklik op een row afhandelt */
    function handleDoubleClick(row: GridRowParams) {
        if (props.showEditButton && props.editOnRowDoubleClick) {
            const response = props.onEdit(row)
            if (response) history.push(response)
        }
    }

    /* Functie dat de volgorde van de rows afhandelt, wanneer deze van volgorde veranderen. */
    async function handleRowOrderChange(params: GridRowOrderChangeParams, event: MuiEvent<{}>, details: GridCallbackDetails) {
        if (props.rowReordering) {
            if (params.oldIndex === params.targetIndex) return;
            props.onRowOrderChange?.(params, event, details)

            if (props.rowReorderUrl) {
                const response = await doPost(props.rowReorderUrl, {id: params.row.id, newIndex: params.targetIndex})
                if (isHttpError(response)){
                    toast.error(t('ChangeOrder.Failed'))
                    // reset order to before change
                    console.log(`handleRowOrderChange response error`, response);
                    reloadData()
                } else {
                    toast.success(t('ChangeOrder.Success'))
                    console.log(`handleRowOrderChange response success`, response);
                }
            }
        }
    }

    /* Nakijken of het pagineren ingesteld staat. */
    const allowPagination = !props.rowReordering && !configQuery.data?.disablePagination;
    /* Nakijken of het filteren disabled moet worden. */
    const disableFilter = props.rowReordering || configQuery.data?.disableFilter;
    /* Nakijken of checkboxes getoond mogen worden. */
    // const showCheckboxes = props.rowReordering || dataQuery.data?.data[0]?.selected !== undefined;

    /* Info over de grid dat weergegeven moet worden in de Developer Info. */
    const developerInfoListItems = [
        {
            title: t('GridInfo.Title'),
            id: 'GridInfo.Title',
            list: [
                {
                    title: 'gridCode',
                    description: props.gridCode,
                    id: 'gridCode',
                    url: !gridId ? undefined : generatePath(DeveloperRouteMap.Grid.Detail(), {gridId: gridId}),
                    urlOpenInNewTab: true,
                }
            ]
        }
    ];

    /* Wanneer de pagina veranderd is, de data refetchen. */
    useEffect(() => {
        dataQuery.refetch();
    }, [page]);

    /* Handler dat de paginering gaat afhandlen. */
    const pageHandler = (p) => {
        if (p !== (page + 1) || p !== (page - 1)) setPage(p);
    };
    
    // endregion
    
    return (
        <>
            <DeveloperInfo
                listItems={developerInfoListItems}
            />
            
            <DataGridPro 
                error={configQuery.error}
                onSelectionModelChange={props.onSelectionChanged}
                selectionModel={props.selectionModel}
                checkboxSelectionVisibleOnly={!configQuery.data?.disablePagination ?? false}
                disableMultipleSelection={!props.allowMultiSelect}
                checkboxSelection={props.showCheckbox}

                pagination={allowPagination}
                page={page}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 20, 50, 100]}
                onPageChange={(page) => pageHandler(page)}
                // onPageChange={(page) => setPage(page)}
                onPageSizeChange={pageSize => setPageSize(pageSize)}
                paginationMode={"server"}
                rowCount={dataQuery.data?.recordsFiltered ?? 0}

                disableColumnFilter={disableFilter}
                filterMode={"server"}
                filterModel={advancedFilter}
                onFilterModelChange={(filter) => {
                    setAdvancedFilter(filter)
                    setAdvancedFilterJson(JSON.stringify(filter))
                }}
                getDetailPanelContent={props.getDetailPanelContent}
                getDetailPanelHeight={props.getDetailPanelHeight}

                // density={props.defaultDensity}

                rowReordering={props.rowReordering}
                onRowOrderChange={handleRowOrderChange}

                sortingMode={"server"}
                sortModel={gridSortModel}
                onSortModelChange={(model) => setGridSortModel(model)}

                loading={configQuery.isLoading || dataQuery.isLoading || dataQuery.isFetching}

                rows={dataQuery.data?.data ?? []}
                columns={configQuery.data?.columns ?? []}
                onRowDoubleClick={props.onRowDoubleClick ?? handleDoubleClick}

                onCellDoubleClick={props.onCellDoubleClick}

                components={props.hideToolbar ? {} : {Toolbar: SearchToolbar}}
                componentsProps={{
                    toolbar: {
                        meta: {
                            columns: configQuery.data?.columns ?? []
                        },
                        disableFilter: disableFilter,
                        useQuickSearch: useQuickSearch,
                        setUseQuickSearch: setUseQuickSearch,
                        quickSearch: {
                            hasQuickSearch: configQuery.data?.hasQuickSearch ?? false,
                            hasRegexQuickSearch: configQuery.data?.hasRegexQuickSearch ?? false,
                            hasCaseSensitiveQuickSearch: configQuery.data?.hasCaseSensitiveQuickSearch ?? false,

                            value: quickSearchText,
                            onChange: (event) => setQuickSearchText(event.target.value),
                            clearSearch: () => setQuickSearchText(''),
                            regex: quickSearchRegex,
                            toggleRegex: () => setQuickSearchRegex(!quickSearchRegex),
                            caseSensitive: quickSearchCaseSensitive,
                            toggleCaseSensitive: () => setQuickSearchCaseSensitive(!quickSearchCaseSensitive),

                            focusQuickSearch: props.focusQuickSearch,
                        },
                        advancedSearch: {
                            filter: advancedFilter,
                            savedFilters: configQuery.data?.savedFilters,
                            setAdvancedFilter: setAdvancedFilter
                        }
                    },
                    ...props.componentsProps
                }}

                apiRef={props.apiRef}

                autoHeight={true}

                localeText={getGridLocaleText(userLang)}
                sx={dataGridStyling}
            />
        </>
    );
}

/**
 * Non-Serverside Data Grid with Plattix styling and translated messages
 * @param props: {@link DataGridProProps: MUI data grid props}
 */
export function PlattixGrid(props: DataGridProProps & React.RefAttributes<HTMLDivElement>) {
    const userLang = useAppSelector(userLangCodeSelector)

    return (
        <DataGridPro
            {...props}

            autoHeight={props.autoHeight ?? true}

            localeText={props.localeText ?? getGridLocaleText(userLang)}
            sx={dataGridStyling}
        />
    )
}


/**
 * Get the data grid translations for the language
 * @param language
 */
function getGridLocaleText(language: string) {
    let localization = enUS;
    switch (language.toLowerCase()) {
        case "nl":
            localization = nlNL;
            break;
        case "fr":
            localization = frFR;
            break;
        case "de":
            localization = deDE;
            break;
    }

    return localization.components.MuiDataGrid.defaultProps.localeText
}


type PlattixGridActionsCellItemProps2 = {
    label: string;
    onClick?: () => void;
    icon: IconProp;
    showInMenu?: boolean
}

export function PlattixGridActionsCellItem(props: PlattixGridActionsCellItemProps2) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if (props.showInMenu) return <GridActionsCellItem
        label={props.label}
        showInMenu={true}
        icon={<FontAwesomeIcon icon={props.icon}/>}
        onClick={props.onClick}
    />

    return (
        <GridActionsCellItem
            showInMenu={false}
            label={props.label}
            onClick={props.onClick}
            icon={<>
                <span
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                >
                    <FontAwesomeIcon icon={props.icon}/>
                </span>
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Typography sx={{p: 1}}>{props.label}</Typography>
                </Popover>
            </>}
        />
    )
}

export function useGridReloader(gridCode: string) {
    const queryClient = useQueryClient();

    return {
        reloadGrid: () => queryClient.invalidateQueries(['gridData', gridCode])
    }
}