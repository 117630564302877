import * as React from "react";
import {useState} from "react";
import {useTranslation} from "../../PlattixUI/PlattixReactCore/i18n";
import {FileUploader} from "../../PlattixUI/core/components/FileUploader";
import {FilePondFile} from "filepond";
import {doPostFormDataXhr, isHttpError} from "../../PlattixUI/PlattixReactCore/api/Api";
import {HttpError} from "../../PlattixUI/PlattixReactCore/CoreTypes";
import {ActionsRight, ErrorMessage} from "../../PlattixUI/core/components/ActionBar";
import {ConfirmButton} from "PlattixUI/core/components/Buttons";
import {LinearProgress} from '@mui/material';
import {toast} from "react-toastify";
import {round} from "../../PlattixUI/util/numberUtil";
import {ToastContent} from "../../PlattixUI/core/components/ToastContent";
import {styled} from "goober";
import {DocScanEndpoints} from "./DocScanEndpoints";

const acceptedDocumentFileTypes = ['image/jpeg', 'image/*', 'application/pdf']

const UploadFileContainer = styled('div')`
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
    width: 100%;
    height: 'fit-content';
`;

const UploadFileLoader = styled('div')`
    width: 100%;
`;

const UploadFileLoaderStyling = {
    background: '#CCC',
    borderRadius: '10px',
    '&>span': {
        background: 'var(--styleColor1)',
    },
};

interface DocumentUploadFormProps {
    onUploadComplete: () => void
}
export function DocumentUploadForm(props: DocumentUploadFormProps) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState<FilePondFile[]>([])
    const [error, setError] = useState<HttpError | string | null>()
    const [uploadProgress, setUploadProgress] = useState(0)

    async function uploadFile() {
        const form = new FormData();

        if (files.length === 0) {
            setError("no file provided")
            return
        }

        setLoading(true)
        files.forEach(file => form.append('files', file.file as File, file.filename))

        setError(null)
        const response = await doPostFormDataXhr<{ existingFiles: string[], failed: string[] }>(DocScanEndpoints.Upload, form, {onUploadProgress: setUploadProgress})
        if (isHttpError(response)) {
            setError(response)
        } else {
            props.onUploadComplete()
            
            const successCount = files.length - response.existingFiles.length - response.failed.length;
            
            if (response.failed.length === 0 && response.existingFiles.length === 0){
                // Everything processed successfully
                toast.success(<ToastContent title={t('DocScan.Ocr.ProcessingSuccess', {successCount:successCount})} />)
            } else {
                if (response.existingFiles.length > 0) {
                    toast.warn(
                        <div>
                            <h4>{t('DocScan.Ocr.ProcessingSuccess', {successCount:successCount})}</h4>
                            <h4>{t('DocScan.DuplicateFileName.Msg')}</h4>
                            <ul>{ response.existingFiles.map(x => <li key={x}>{x}</li>) }</ul>
                        </div>
                    )
                }
                if (response.failed.length > 0) {
                    toast.warn(
                        <div>
                            <h4>{t('DocScan.Ocr.ProcessingFailure')} </h4>
                            <ul>{ response.failed.map(x => <li key={x}>{x}</li>) }</ul>
                        </div>
                    )
                }
            }

            setFiles([])
            setUploadProgress(0)
        }
        setLoading(false)
    }


    return (
        // <div style={{width: '100%', minHeight: '100px'}}>
        <UploadFileContainer>
            {
                error && <ErrorMessage>
                    {
                        isHttpError(error) ? error.title : error
                    }
                </ErrorMessage>
            }
            <FileUploader
                acceptedFileTypes={acceptedDocumentFileTypes}
                setFiles={setFiles}
                files={files.map(f => f.file)}
                maxFiles={100}
                required={true}
                dropOnPage={true}
                disabled={loading}
            />
            {
                loading && <UploadFileLoader>
                {/*true && <>*/}
                    { uploadProgress < 1 && <p>{t('Upload.Busy')}: ({round(uploadProgress*100, 0)}%)</p>}
                    { uploadProgress === 1 && <p>{t('Upload.Processing')}</p>}
                    <LinearProgress variant={uploadProgress === 1 ? "indeterminate" : "determinate"} value={uploadProgress * 100} sx={UploadFileLoaderStyling} />
                </UploadFileLoader>
            }

            {
                !!files.length && !loading &&
                <ActionsRight>
                    <ConfirmButton loading={loading} onClick={uploadFile}>{t('Upload')} </ConfirmButton>
                </ActionsRight>
            }
        </UploadFileContainer>

    )
}

export interface PlattixFileUploadProps {
    acceptedFileTypes?: string[];
    filesLimit?: number;
    maxFileSize?: number;
}

