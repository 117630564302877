import {PlattixCard} from "../../PlattixUI/core/components/ContentCard";
import {PlattixText} from "../../PlattixUI/core/components/Text";
import {DataGridPro} from "@mui/x-data-grid-pro";
import React from "react";
import {GridEventListener} from "@mui/x-data-grid/models/events";
import {dataGridStyling} from "./DocumentScanGridStyling";

export interface DocumentGridProps {
    className: string | null,
    header: string,
    description: string,
    isLoading: boolean,
    rows: any[] | null,
    columns: any[],
    onDoubleClickHandler: GridEventListener<'cellDoubleClick'>
}

function DocumentScanGrid(props: DocumentGridProps) {
    return (
        <PlattixCard header={props.header} collapsible className={props.className ?? ''}>
            <PlattixText alignment={'center'}>
                {props.description}
            </PlattixText>
            <DataGridPro
                loading={props.isLoading}
                rows={props.rows ?? []}
                columns={props.columns}
                autoHeight sx={dataGridStyling}
                rowHeight={40}
                headerHeight={40}
                getRowId={row => row.documentId}
                onCellDoubleClick={props.onDoubleClickHandler}
            />
        </PlattixCard>
    );
}

export default DocumentScanGrid;