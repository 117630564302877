import {t} from "PlattixUI/PlattixReactCore/i18n";
import {AddIconButton, CancelIconButton, DeleteIconButton, EditIconButton} from "PlattixUI/core/components/Buttons";
import {ChooseFromGridModal} from "PlattixUI/core/grid/ChooseFromGridModal";
import {UseFormReturn} from "react-hook-form";
import {useEffect, useState} from "react";
import {GridProps} from "PlattixUI/core/grid/PlattixDataGrid";
import {AccountModel, AccountNameModel} from "PlattixUI/types/AccountTypes";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons/faUserPlus";
import {faUserPen} from "@fortawesome/free-solid-svg-icons/faUserPen";
import {BaseModal} from "PlattixUI/core/components/Modal";
import {CreateAccountWizard} from "PlattixCrmUI/AccountComponent";
import {styled} from "goober";
import {faUserXmark} from "@fortawesome/free-solid-svg-icons/faUserXmark";
import {PlattixValidatedInput} from "PlattixUI/core/components/form/Input";
import {PlattixTooltip} from "PlattixUI/core/components/ContentCard";


export type AccountPlattixInputProps = {
    form: UseFormReturn,
    name: string,
    readonly?: boolean,
    required?: boolean,
    options?: ChooseAccountOptions,
}

export interface ChooseAccountOptions extends Pick<GridProps, "includeLanguageCode" | "parameters"> {
    gridCode?: string,
    title?: string,
    description?: string,

    allowCreateAccount?: boolean
}

export function AccountPlattixInput(props: AccountPlattixInputProps) {
    const [showChooseAccountModal, setShowChooseAccountModal] = useState(false);
    const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
    const account = props.form.watch(props.name) as AccountNameModel | undefined;

    function onSelect(id: any, row: any) {
        props.form.setValue(props.name, {
            accountId: id,
            fullName: row.accountname,
            accountNumber: row.accountnumber,
            accountTypeDescription: row.accounttypedescription,
        }, {shouldDirty: true, shouldTouch: true, shouldValidate: true})
    }
    async function onCreate(accountId: number, account: AccountModel) {
        setShowCreateAccountModal(false);
        
        props.form.setValue(props.name, account as AccountNameModel, {shouldDirty: true, shouldTouch: true, shouldValidate: true})
    }
    
    function clearUser(){
        props.form.setValue(props.name, undefined, {shouldDirty: true, shouldTouch: true, shouldValidate: true})
    }
    
    useEffect(()=>{
        props.form.register(props.name, {required: props.required})
        props.form.trigger(props.name)
    },[props.form, props.name, props.required])
    
    return <>
        <AccountPlattixInputContainer>
            <AccountPlattixInputContent>
                <PlattixValidatedInput type={"hidden"} formHook={props.form} name={props.name} required={props.required} />
                {account?.accountId && 
                    <>
                        {account.accountNumber} - {account.fullName ?? account.name}
                    </>
                }
                {!account?.accountId && 
                    <>
                        {t('Input.Account.NoAccountSelected')}
                    </>
                }
            </AccountPlattixInputContent>

            {!props.readonly && 
                <AccountPlattixInputButtons>
                    {!props.required && account &&
                        <PlattixTooltip title={t('Input.Account.ClearUser')}>
                            <DeleteIconButton
                                icon={faUserXmark}
                                onClick={() => clearUser()}
                            />
                        </PlattixTooltip>
                    }
                    <PlattixTooltip title={t('Input.Account.ChooseAccountModal')}>
                        <EditIconButton
                            icon={faUserPen}
                            onClick={() => setShowChooseAccountModal(true)}
                        />
                    </PlattixTooltip>

                    {props.options?.allowCreateAccount &&
                        <PlattixTooltip title={t('Input.Account.CreateAccountModal')}>
                            <AddIconButton
                                icon={faUserPlus}
                                onClick={() => setShowCreateAccountModal(true)}
                            />
                        </PlattixTooltip>
                    }

                </AccountPlattixInputButtons>
            }
        </AccountPlattixInputContainer>

        {!props.readonly && 
            <>

                <ChooseFromGridModal
                    gridCode={"Accounts"}
                    title={t('chooseAccount')}
                    includeLanguageCode={props?.options?.includeLanguageCode ?? true}
                    onSelect={onSelect}
                    show={showChooseAccountModal} onClose={() => setShowChooseAccountModal(false)}
                    {...props.options}
                />

                {props.options?.allowCreateAccount && 
                    <>
                        <BaseModal
                            show={showCreateAccountModal}
                            onClose={() => setShowCreateAccountModal(false)}
                        >
                            <CreateAccountWizard
                                onSuccess={onCreate}
                                onCancel={() => setShowCreateAccountModal(false)}
                            />
                        </BaseModal>

                    </>
                }

            </>
        }
    </>

}

export const AccountPlattixInputContainer = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
    `;
});

export const AccountPlattixInputButtons = styled('div')(() => {
    return `
        width: fit-content;
        display: flex;
        gap: 10px;
        flex-flow: row nowrap;
        margin-left: auto;
        
        .content-btn {
            margin: 0;
        }
    `;
});

export const AccountPlattixInputContent = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        gap: 10px;
        flex-flow: row wrap;
        padding: var(--padding5);
        padding-left: 0;
    `;
});