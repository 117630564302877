import React, {useEffect, useState} from 'react';
import {PageHeader} from "../../PlattixUI/core/Header";
import {Actions} from "../../PlattixUI/core/components/ActionBar";
import {GridRenderCellParams} from '@mui/x-data-grid';
import {PlattixCardContainer} from "../../PlattixUI/core/components/ContentCard";
import {styled} from "goober";
import {
    DocumentList,
    DocumentListItem,
    DocumentScanDocument    
} from "./types/Document";
import {HttpError} from "../../PlattixUI/PlattixReactCore/CoreTypes";
import {
    doDelete,
    doGet,
    doPost,
    getFullUrl,
    isHttpError,
    usePlattixQuery
} from "../../PlattixUI/PlattixReactCore/api/Api";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEllipsisH} from '@fortawesome/free-solid-svg-icons';
import {DocumentUploadForm} from "./DocumentUploadForm";
import {generatePath} from "react-router";
import {history} from 'PlattixUI/PlattixReactCore/store';
import {DocScanEndpoints} from "./DocScanEndpoints";
import {ClickAwayListener, Popper} from "@mui/material";
import {Endpoints} from "../../configuration/ApiEnpointMap";
import {FormGridColDef} from "./types/FormColDef";
import {t, useTranslation} from "../../PlattixUI/PlattixReactCore/i18n";
import {HubConnectionBuilder, HubConnectionState} from "@microsoft/signalr";
import { DocumentScanDetailUrl } from './DocumentScanRoutes';
import DocumentScanGrid from "./DocumentScanGrid";
import {columnConfig, columnEqualWidth, statusCellDocumentState, statusCellOCRState} from "./DocumentScanGridStyling";
import {useQueryClient} from "@tanstack/react-query";
import { signalRConnection } from 'configuration/SignalRHubs';

const goToDocument = (documentId) => {
    return history.push(generatePath(DocumentScanDetailUrl, {documentId: documentId}));
}

//region styling 

const OptionsBtn = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

const DataGridDropdown = styled('div')`
    display: block;
    min-width: 150px;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    right: 0;
    background: #FFF;
    box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
    padding: 0;
    border-radius: 10px;
    z-index: 100;
    
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        
        li {
            margin: 0;
            padding: 0;
            
            &:first-child {
                margin-top: 10px;
            }
            
            &:last-child {
                margin-bottom: 10px;
            }
            
            &:hover {
                background: #eee;
            }
            
            & > * {
                font-weight: 700;
                width: 100%;
                height: 100%;
                display: block;
                padding: 5px 20px;
                text-align: right;
                cursor: pointer;
                color: var(--textColorDark);
            }
        }
    }
`;

//endregion

function DocumentScan() {
    const {t} = useTranslation();
    const queryClient = useQueryClient();

    const [err, setError] = useState<HttpError | null>(null);
    const [loadingToProcess, setLoadingToProcess] = useState(false);
    const [loadingProcessed, setLoadingProcessed] = useState(false);
    
    const documentsToProcessQueryKey = ["state", "ToProcess"];
    const documentsProcessedQueryKey = ["state", "Processed"];
    
    const documentsToProcessQuery = usePlattixQuery<DocumentList>(
            documentsToProcessQueryKey,
            DocScanEndpoints.Document(),
            { documentState: 'ToProcess'});
    const documentsProcessedQuery = usePlattixQuery<DocumentList>(
            documentsProcessedQueryKey,
            DocScanEndpoints.Document(),
            { documentState: 'Processed', maxDaysOld: 30 });
    
    let toProcessDocuments = documentsToProcessQuery.data;
    let processedDocuments = documentsProcessedQuery.data;

    //perform an optimistic update of the documents currently in cache
    const updateDocumentInCache = (updatedDocument: DocumentListItem) => {
        queryClient.setQueryData<DocumentList>(documentsToProcessQueryKey, (oldData) => {
            if (oldData)
            {
                //replace item with updated version
                return oldData.map((item) =>
                    (item.documentId === updatedDocument.documentId) ? updatedDocument : item);
            }

            return oldData;
        });
    }
    
    const removeDocumentFromCache = (removedDocumentId: number, queryKey: string[]) => {
        queryClient.setQueryData<DocumentList>(queryKey, (oldData) => {
            if (oldData)
            {
                //remove document from list
                return oldData.filter((item) => item.documentId !== removedDocumentId);
            }

            return oldData;
        });
    }

    const removeDocumentFromToProcessCache = (removedDocumentId: number) => {
        removeDocumentFromCache(removedDocumentId, documentsToProcessQueryKey);
    }

    const removeDocumentFromProcessedCache = (removedDocumentId: number) => {
        removeDocumentFromCache(removedDocumentId, documentsProcessedQueryKey)
    }

    const UpdateDocumentInfo = async (documentId, message) => {
        //no loading is done on purpose, as this is a "real-time update" triggered by signalR events
        const updatedDocument = await doGet<DocumentListItem>(Endpoints.OCR.GetDocumentInfo(documentId));
        if (isHttpError(updatedDocument)) {
            return;
        }

        updateDocumentInCache(updatedDocument);
    }    

    const updateOCR = async (documentId) => {
        setLoadingToProcess(true);
        const updatedDocument = await doPost<DocumentListItem>(Endpoints.OCR.UpdateOCR(documentId))

        if (isHttpError(updatedDocument)) {
            setLoadingToProcess(false);
            return;
        }

        updateDocumentInCache(updatedDocument);

        setLoadingToProcess(false);
    }
    
    const doDeleteDocument = async (documentId, setLoadingState: React.Dispatch<React.SetStateAction<boolean>>, removeFromCache: (removedDocumentId: number) => void) => {
        setLoadingState(true);
        const response = await doDelete(Endpoints.OCR.DeleteDocument(documentId));

        if (isHttpError(response)) {
            setLoadingState(false);
            return;
        }

        removeFromCache(documentId);
        setLoadingState(false);
    }

    const deleteDocument = async (documentId) => {        
        if (toProcessDocuments && toProcessDocuments.findIndex(d => d.documentId === documentId) > -1)
        {
            await doDeleteDocument(documentId, setLoadingToProcess, removeDocumentFromToProcessCache);            
        }
        else if (processedDocuments && processedDocuments.findIndex(d => d.documentId === documentId) > -1)
        {
            await doDeleteDocument(documentId, setLoadingProcessed, removeDocumentFromProcessedCache);
        }
    }
    
    const optionsCell = {
        renderCell: (params) => {
            return RowOptions(params, updateOCR, deleteDocument);
        },
    };
    
    const scannedDocumentsColumns: FormGridColDef<DocumentScanDocument>[] = [
        {
            field: 'invoiceNumber',
            headerName: t('DocumentScan.InvoiceNumber'),
            ...columnConfig,
            ...columnEqualWidth
        },
        {
            field: 'documentName',
            headerName: t('DocumentScan.DocumentName'),
            ...columnConfig,
            ...columnEqualWidth
        },
        {
            field: 'supplierName',
            headerName: t('DocumentScan.SupplierName'),
            ...columnConfig,
            ...columnEqualWidth
        },
        {
            field: 'invoiceDate',
            headerName: t('DocumentScan.InvoiceDate'),
            ...columnConfig,
            ...columnEqualWidth,
            renderCell: (params: GridRenderCellParams) => (
                <span>
                    {new Date(params.value).toLocaleDateString()}
                </span>
            )

        },
        {
            field: 'documentStateCode',
            headerName: t('DocumentScan.DocumentStateCode'),
            ...columnConfig,
            ...columnEqualWidth,
            ...statusCellDocumentState
        },
        {
            field: 'ocrStateCode',
            headerName: t('DocumentScan.OCRStateCode'),
            ...columnConfig,
            ...columnEqualWidth,
            ...statusCellOCRState
        },
        {
            field: 'options',
            headerName: '',
            sortable: false,
            ...columnConfig,
            ...optionsCell,
            width: 50,
            flex: 0,
            minWidth: 50
        },
    ];
        
    if (signalRConnection.state === HubConnectionState.Disconnected) {
        signalRConnection.start()
            .then(() => signalRConnection.invoke("joinGroup", ("documentScan"))
                .catch((err) => console.error(err)));
    }

    useEffect( () => {
        setLoadingToProcess(documentsToProcessQuery.isFetching);
        setLoadingProcessed(documentsProcessedQuery.isFetching);
    }, [documentsToProcessQuery.isFetching, documentsProcessedQuery.isFetching]);
    
    useEffect(() => {
        signalRConnection.on("stateChange", UpdateDocumentInfo);

        return function cleanup() {
            signalRConnection.off("stateChange", UpdateDocumentInfo);
        }
    });

    return (
        <div>
            <PageHeader title={t('DocumentScan.Title')} description={t('DocumentScan.Description')}/>

            <Actions>
                <DocumentUploadForm onUploadComplete={async () => await documentsToProcessQuery.refetch()}/>
            </Actions>

            <PlattixCardContainer>
                <DocumentScanGrid
                    className={'content-column-1_-1'}
                    header={t('DocumentScan.OverviewToProcess')}
                    description={t('DocumentScan.OverviewToProcess.Description')}
                    isLoading={loadingToProcess}
                    rows={toProcessDocuments ?? []}
                    columns={scannedDocumentsColumns}
                    onDoubleClickHandler={(params, event) => {
                        if (event.ctrlKey) return;
                        if (params.field === 'options') return;
                        event.defaultMuiPrevented = true;
                        goToDocument(params.id);
                    }}   
                />

                <DocumentScanGrid
                    className={'content-column-1_-1'}
                    header={t('DocumentScan.OverviewProcessed')}
                    description={t('DocumentScan.OverviewProcessed.Description')}
                    isLoading={loadingProcessed}
                    rows={processedDocuments ?? []}
                    columns={scannedDocumentsColumns}
                    onDoubleClickHandler={(params, event) => {
                        if (event.ctrlKey) return;
                        if (params.field === 'options') return;
                        event.defaultMuiPrevented = true;
                        goToDocument(params.id);
                    }}
                />
            </PlattixCardContainer>

        </div>
    );
}

function RowOptions(props, onUpdate: (documentId: string) => Promise<void>, onDelete: (documentId: string) => Promise<void>) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const toggleOptions = (e) => {
        e.stopPropagation();
        setAnchorEl(anchorEl ? null : e.currentTarget);
    }
    
    const editHandler = () => {
        goToDocument(props.id);
    }
    
    const updateHandler = async () => {
        setAnchorEl(null);
        await onUpdate(props.id);
    };

    const deleteHandler = async () => {
        setAnchorEl(null);
        await onDelete(props.id);
    };    

    const clickAwayHandler = () => {
        setAnchorEl(null);
    };    

    return (
        <>
            <OptionsBtn onClick={toggleOptions}>
                <FontAwesomeIcon icon={faEllipsisH} className='module-icon'/>
            </OptionsBtn>

            <ClickAwayListener onClickAway={clickAwayHandler}>
                <Popper
                    id={open ? 'simple-popper' : undefined}
                    open={open}
                    anchorEl={anchorEl}
                    placement="bottom-end"
                    onResize={undefined}
                    onResizeCapture={undefined}
                >
                    <DataGridDropdown>
                        <ul>
                            <li>
                                <p onClick={editHandler}>{t('Edit')}</p>
                            </li>
                            <li>
                                <a href={getFullUrl(DocScanEndpoints.Download(props.id))}
                                   target={"_blank"}>{t('Download.File')}</a>
                            </li>
                            <li>
                                <p onClick={updateHandler}>{t('DocScan.MarkAsReadyForImport')}</p>
                            </li>
                            <li>
                                <p onClick={deleteHandler}>{t('Delete')}</p>
                            </li>
                        </ul>
                    </DataGridDropdown>
                </Popper>
            </ClickAwayListener>

        </>
    );
}

export default DocumentScan;