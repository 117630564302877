import React, {useEffect, useState} from "react";
import htmlRaw from "../../util/HtmlRaw";
import {Spinner} from "./Loader";
import {ErrorMessage} from "./ActionBar";
import {useTranslation} from "../../PlattixReactCore/i18n";
import {doGet, isHttpError} from "../../PlattixReactCore/api/Api";
import {ConfirmButton} from "./Buttons";
import {ComparisonList, ComparisonListItem} from "./ComparisonList";
import {formatDateTime} from "../../util/DateUtil";
import {styled} from "goober";
import {HttpError} from "../../PlattixReactCore/CoreTypes";

interface CangelogModel {
    log: string,
    buildDate: string,
    version: string
}

const ChangelogStyle = styled('div') `
    h1 {
        font-size: 1.5em;
    }
    h2 {
        margin-top: 1em;
        margin-bottom: 0;
        font-size: 1.2em;
    }
    h3 {
        font-size: 1em;
        margin-bottom: 0;
        margin-left: 1em;
    }
    
    ul {
        margin: 0;
    }
`

export function Changelog() {
    const {t} = useTranslation();
    const [log, setLog] = useState<CangelogModel | null>(null);
    const [loading, setLoading] = useState<boolean | null>(false);
    const [error, setError] = useState<HttpError | null>(null);

    async function FetchLog() {
        if (loading) return;
        setLoading(true)
        const response = await doGet<CangelogModel>("/changelog")

        setLoading(false)
        if (isHttpError(response)) {
            setError(response)
            return;
        }
        setError(null)
        setLog(response)
    }

    useEffect(() => {
        if (!log && !loading && !error) {
            FetchLog()
        }

    })

    if (loading) return <Spinner/>
    if (error) return <ErrorMessage>{error.title} <ConfirmButton onClick={FetchLog}>{t('Reload')}</ConfirmButton></ErrorMessage>

    if (log) return <ChangelogStyle>
        <ComparisonList>
            <h1>{t('Version')}</h1>
            <ComparisonListItem label={t('Version')} value={log.version}/>
            <ComparisonListItem label={t('BuildDate')} value={formatDateTime(new Date(log.buildDate) )}/>
        </ComparisonList>
        {htmlRaw(log.log)}
    </ChangelogStyle>

    return <h4>{t('NoChangelog')}</h4>
}