import React, {PropsWithChildren, useEffect, useState} from 'react';
import {t, useTranslation} from '../PlattixReactCore/i18n';

import {CookieBannerComponent} from './Partials/CookieBanner';
import {FeedbackComponent} from './Partials/Feedback';
import {LanguageMenuComponent} from './Partials/LanguageMenu';

import logoVlaio from '../images/branding/external/vlaio/logo/logo.png';

import {applicationSelectorClick} from '../js/Components/General/Menu/Navigation';
import {Link, useHistory} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {userSelector} from "../PlattixReactCore/UserSlice";
import {useAppSelector} from "../PlattixReactCore/hooks";
import {LogoutLink} from "./components/Buttons";
import {RequireRole} from "./PermissionComponents";
import {InfoModal} from "./components/Modal";
import {Changelog} from "./components/Changelog";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {filterProps} from "../util/ElementProperties";
import {styled} from "goober";
import {PlattixConfiguration} from "configuration/Configuration";
import {useClientSettings} from "../PlattixReactCore/api/Api";
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import {Spinner} from "PlattixUI/core/components/Loader";
import {NotificationsRouteMap} from "PlattixUI/configuration/Configuration";
import {rootSelector} from "PlattixUI/PlattixReactCore/selectors";
import {PlattixImage} from "PlattixUI/util/thumbor/PlattixImage";

export function SidebarComponent() {
    /* Alle beschikbare info van de redux state */
    const state = useAppSelector(rootSelector);
    /* Ophalen van de gebruikersinformatie */
    const {user} = useAppSelector(userSelector);
    /* Hook om te navigeren naar andere pagina's. */
    const history = useHistory();
    /* State van de changelog. */
    const [showChangelog, setShowChangelog] = useState(false);

    // region App visibility icons
    
    // region ClientSettings
    const clientSettings = useClientSettings();
    const appSettings = clientSettings.data;
    const platformSettings = appSettings?.Platforms;
    const multipleApps = Object.keys(platformSettings ?? {}).length > 1;
    const platforms = Object.values(platformSettings ?? {});
    // endregion

    /* State dat bijhoudt of de icoontjes van de andere platformen getoond mogen worden. */
    const [showApps, setShowApps] = useState(false);
    /* State dat bepaald of het icoontje getoond mag worden op basis van de clientsettings.json */
    const [appVisibilty, setAppVisibilty] = useState<{[key: string]: boolean}>({});
    /* State dat bepaald of het icoontje getoond mag worden op basis van de Configuration.ts */
    const [stateAppVisibilty, setStateAppVisibilty] = useState<{[key: string]: boolean}>({});
    
    /* Nakijken welke apps verborgen moeten worden op basis van het hideInMenu object in de Configuration.ts settings. */
    useEffect(() => {
        if (!PlattixConfiguration) return;

        Object.keys(PlattixConfiguration.hideInMenu).forEach(p => {
            if (!platformSettings) return;

            const visible = PlattixConfiguration.hideInMenu[p](state)
            const name = platformSettings[p].name

            if (stateAppVisibilty[name] !== visible) setStateAppVisibilty({...stateAppVisibilty, [name]: visible})
        })
    }, [stateAppVisibilty, platformSettings, platforms, state]);

    /* Nakijken welke apps verborgen moeten worden op basis van het hideInMenu object in de clientsettings.json settings. */
    useEffect(() => {
        if (!platformSettings) return;

        Object.values(platformSettings).forEach(p => {
            if (appVisibilty[p.name] !== !p.hideInMenu) setAppVisibilty({...appVisibilty, [p.name]: !p.hideInMenu})
        })
    }, [platformSettings, appVisibilty]);

    /* Past de stateAppVisibilty state aan en gaat alle platformen die getoond mogen worden daar definiëren. */
    useEffect(() => {
        if (!platformSettings || Object.keys(stateAppVisibilty).length !== 0) return;
        setStateAppVisibilty(Object.values(platformSettings).reduce((a,b) => ({...a, [b.name]: true}), {} ))
    }, [platformSettings, stateAppVisibilty]);

    /* Handler dat de icons van de andere platformen toont/verbergt als er op de expand knop wordt gedrukt. */
    const showAppsHandler = () => {
        if (multipleApps) setShowApps(!showApps);
    };

    /* Handler om naar andere platforms te navigeren. */
    const appRedirectHandler = (e, url) => {
        if (!multipleApps) return history.push('/');
        if (!url) return;
        return window.location.assign(url.pathname);
    }
    
    // endregion
    
    // region theCurrentOpenItem
    // Deze code dient om bij te houden en het afhandelen van de open en gesloten sidebar menu items.
    
    //TODO: put this somewhere else
    const cookieAcceptedString: string = 'cookies-accepted';
    
    const initalOpenSidebarItem = localStorage.getItem(cookieAcceptedString) === 'true' ? null : 'cookies'

    const currentOpenItem = useState<string | null>(initalOpenSidebarItem);
    const [theCurrentOpenItem, setTheCurrentOpenItem] = currentOpenItem;

    const processOutOfBodyClick = () => {
        if (theCurrentOpenItem) {
            if (!needsConfirmations[theCurrentOpenItem]) setTheCurrentOpenItem(null)
        }
    }

    /* Wanneer er geklikt wordt, nakijken of het op het element zelf is. Als dat niet het geval is; de state "theCurrentOpenItem" op null zetten. */
    useEffect(() => {
        document.body.addEventListener('click', processOutOfBodyClick);

        return function cleanup() {
            document.body.removeEventListener("click", processOutOfBodyClick);
        };
    })
    // endregion
    
    return (
        <section className="sidebar">
            <div className="application" onClick={(e) => applicationSelectorClick(e.currentTarget)}>
                <div className="application-current" onClick={showAppsHandler}>
                    <PlattixImage
                        width={30}
                        height={30}
                        fileSource={PlattixConfiguration.appIcon}
                        alt={PlattixConfiguration.platformName}
                        onClick={(e) => appRedirectHandler(e, null)}
                    />
                    {(multipleApps) &&
                        <div className={'dropdown-indicator'}>
                            <FontAwesomeIcon icon={showApps ? faAngleUp : faAngleDown}/>
                        </div>
                    }
                </div>
                {
                    (multipleApps && showApps) && <>
                        {clientSettings.isLoading && <Spinner size={"small"}/>}
                        {
                            !clientSettings.isLoading &&
                            <div className={`application-list`}>
                                {
                                    platforms
                                        .filter(app => PlattixConfiguration.platformName.toLowerCase() !== app.name.toLowerCase()) // Verbergen icon van huidige platform.
                                        .filter(app => appVisibilty[app.name]) // Filteren van icons op basis van de appVisibilty state
                                        .filter(app => stateAppVisibilty[app.name]) // Filteren van icons op basis van de stateAppVisibilty state
                                        .map(app => {
                                        return (
                                            <div key={app.name}
                                                     onClick={(e) => appRedirectHandler(e, {pathname: app.url})}
                                                     className="application-option">
                                                <PlattixImage
                                                    width={30}
                                                    height={30}
                                                    fileSource={app.icon}
                                                    alt={app.name}
                                                />
                                                <span className="explanation right">Open {app.name}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </>

                }
            </div>

            {/*{servicepoint !== null &&*/}
            <SidebarItem
                name={'inbox'}
                icon={'inbox'}
                explanation={t('Notifications')}
                hideOptions={true}
                // notification={3}
                href={NotificationsRouteMap.overview}
                show={currentOpenItem}
            >
            </SidebarItem>
            {/*}*/}

            {platformSettings?.['Shop'] &&
                <SidebarItem
                    name={'marketplace'}
                    icon={'shop'}
                    explanation={t('Marketplace')}
                    hideOptions={true}
                    href={platformSettings?.['Shop'].url ?? '/'}
                    // onClick={(e) => appRedirectHandler(e, {pathname: platformSettings?.['Shop'].url})}
                    show={currentOpenItem}
                    externallink
                >
                </SidebarItem>
            }

            <SidebarSpacer/>

            <SidebarItem
                name={'feedback'}
                icon={'comment-dots'}
                explanation={t('Feedback.Form.Headline')}
                circle={true}
                needsConfirmation={true}
                show={currentOpenItem}
                style={{width: '500px', maxWidth: '500px'}}
                
            >
                <FeedbackComponent/>
            </SidebarItem>
            <RequireRole role={"Administrator"}>
                <SidebarItem
                    name={'changelog'}
                    icon={'bug-slash'}
                    explanation={t('Changelog')}
                    circle={true}
                    hideOptions={true}
                    onClick={() => setShowChangelog(true)}
                    show={currentOpenItem}
                >
                    <InfoModal
                        title={t('Changelog')}
                        onClose={() => setShowChangelog(false)}
                        show={showChangelog}
                    >
                        <Changelog/>
                    </InfoModal>
                </SidebarItem>
            </RequireRole>
            <SidebarItem name={'help'} icon={'question'} explanation={t('Plattix.HelpCenter')} circle={true} show={currentOpenItem}>
                {/*<p className="option" target="_blank">{t('Plattix.HelpCenter')}</p>*/}
                <Link className="option" to="/Legal/CompanyDetails" target="_blank">{t('Plattix.CompanyDetails')}</Link>
                <Link className="option" to="/Legal/TermsAndConditions"
                      target="_blank">{t('Plattix.TermsAndConditions')}</Link>
                <Link className="option" to="/Legal/PrivacyPolicy" target="_blank">{t('Plattix.PrivacyPolicy')}</Link>
                <Link className="option" to="/Legal/CookiePolicy" target="_blank">{t('Plattix.CookiePolicy')}</Link>
                <Link className="option" to="/Legal/ReturnPolicy" target="_blank">{t('Plattix.ReturnPolicy')}</Link>
                <hr/>
                <div className="sponsors">
                    <div className="sponsor">
                        <b>{t('Sponsor.SupportFrom')}</b>
                        <img src={logoVlaio} alt="Logo Vlaio"/>
                    </div>
                </div>
            </SidebarItem>

            <SidebarItem name={'cookies'} icon={'cookie-bite'} explanation={t('Cookies')} circle={true}
                         needsConfirmation={true}
                         show={currentOpenItem}>
                <CookieBannerComponent show={currentOpenItem}/>
            </SidebarItem>

            {/*<SidebarItem name={'search'} icon={'search'} explanation={t('Search')} circle={true}/>*/}

            <SidebarItem name={'Profile'} icon={'user'} explanation={t('Profile')} circle={true} show={currentOpenItem}>
                <p className="name">{user?.firstName} {user?.lastName}</p>
                <p className="email">{user?.email}</p>
                {/*<p className="role">{user?.roles.join(', ')}</p>*/}
                <hr/>
                <div className="option">
                    {/*<Popper*/}
                    {/*    placement="right-end"*/}
                    {/*    disablePortal={true}*/}
                    {/*    modifiers={[*/}
                    {/*        {*/}
                    {/*            name: 'flip',*/}
                    {/*            enabled: false,*/}
                    {/*            options: {*/}
                    {/*                altBoundary: false,*/}
                    {/*                rootBoundary: 'document',*/}
                    {/*                padding: 8,*/}
                    {/*            },*/}
                    {/*        },*/}
                    {/*        {*/}
                    {/*            name: 'preventOverflow',*/}
                    {/*            enabled: true,*/}
                    {/*            options: {*/}
                    {/*                altAxis: false,*/}
                    {/*                altBoundary: true,*/}
                    {/*                tether: true,*/}
                    {/*                rootBoundary: 'document',*/}
                    {/*                padding: 8,*/}
                    {/*            },*/}
                    {/*        },*/}
                    {/*        {*/}
                    {/*            name: 'arrow',*/}
                    {/*            enabled: false,*/}
                    {/*            options: {*/}
                    {/*                element: arrowRef,*/}
                    {/*            },*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*>*/}
                    <OptionsMenu label={t('Language')}>
                        <LanguageMenuComponent/>
                    </OptionsMenu>
                    {/*</Popper>*/}
                </div>
                {/*<p className="option">{t('MenuUserSettings')}</p>*/}
                <LogoutLink className="option link"/>
            </SidebarItem>
        </section>
    );
}

export interface LinkWrapperProps extends React.HTMLAttributes<HTMLDivElement | HTMLAnchorElement> {
    onClick?: (e?: any) => void,
    // href?: LocationDescriptor<unknown> | ((location: Location<unknown>) => LocationDescriptor<unknown>) | undefined,
    href?: string,
    externallink?: boolean,
}


export function LinkWrapper(props: PropsWithChildren<LinkWrapperProps>) {
    let linkElement = <div {...filterProps(props, ['externallink'])} onClick={props.onClick}>{props.children}</div>;

    if (props.href) {
        if (props.externallink) {
            linkElement = <a {...filterProps(props, ['externallink'])} onClick={props.onClick} href={props.href} target={'_blank'}>{props.children}</a>;
        } else {
            linkElement = <Link {...filterProps(props, ['externallink'])} onClick={props.onClick} to={props.href}>{props.children}</Link>;
        }
    } else {
        linkElement = <div {...filterProps(props, ['externallink'])} onClick={props.onClick}>{props.children}</div>;
    }

    return linkElement;
}

// export function LinkWrapper(props: PropsWithChildren<LinkWrapperProps>) {
//     return (
//         props.href ?
//             <Link {...props} onClick={props.onClick} to={props.href}>{props.children}</Link>
//             :
//             <div {...props} onClick={props.onClick}>{props.children}</div>
//     );
// }

export interface OptionsMenuProps extends React.HTMLAttributes<HTMLDivElement> {
    label: string,
}

export function OptionsMenu(props: PropsWithChildren<OptionsMenuProps>) {
    const [show, setShow] = useState(false);

    const onClickFunc = (e) => {
        e.stopPropagation()
        setShow(!show);
    }

    return (
        <>
            <p className="link" onClick={onClickFunc}>{props.label}</p>
            {show &&
                <div className="options">
                    {props.children}
                </div>
            }
        </>
    );
}

export type Positioning = 'top' | 'bottom' | 'left' | 'right';

export interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {
    icon: IconProp,
    iconElement?: Element,
    explanation: string,
    explanationPos?: Positioning,
    circle?: boolean,
    name: string,
    onClick?: (e?: any) => void,
    bottomStart?: boolean,
    hideOptions?: boolean,
    optionsPos?: Positioning,
    needsConfirmation?: boolean,
    notification?: number,
    href?: string,
    externallink?: boolean,
    show: any,
}

export const InvalidHtmlSidebarProps = ['style', 'icon', 'iconElement', 'explanation', 'circle', 'name', 'onClick', 'bottomStart', 'hideOptions', 'needsConfirmation', 'notification', 'href', 'externallink']

export function SidebarItem(props: PropsWithChildren<SidebarProps>) {
    const [show, setShow] = useState(false);

    const [isOpen, setIsOpen] = props.show;

    const closeOnBodyClick = () => {
        setIsOpen(props.name);
    }

    const onClickFunc = (e) => {
        e.stopPropagation()
        if (!props.onClick && props.href) return
        props.onClick?.()
        if (isOpen) {
            if (isOpen === props.name) {
                if(!needsConfirmations[isOpen]) setIsOpen(null);
            } else {
                if(!needsConfirmations[isOpen]) setIsOpen(props.name);
            }
        } else setIsOpen(props.name);
    }
    return <div {...filterProps(props, InvalidHtmlSidebarProps)}
                className={`icon ${props.name} ${props.circle ? 'circle-background' : ''} ${props.bottomStart ? 'bottom-row-start' : ''}`}>
        <LinkWrapper className="link" href={props.href} onClick={onClickFunc} externallink={props.externallink}>
            <FontAwesomeIcon icon={props.icon}/>
            <span
                className={`explanation ${!props.explanationPos ? 'right' : props.explanationPos}`}
            >
                {props.explanation}
            </span>
            {props.notification && <span className="notification">{props.notification}</span>}

        </LinkWrapper>

        {
            !props.hideOptions && props.children && (isOpen === props.name) && 
            <div className={`options ${props.optionsPos ? props.optionsPos : 'right'}`}
                 onClick={e => e.stopPropagation()}
                 style={props.style}
            >
                {props.children}
            </div>
        }

        {
            props.hideOptions && props.children
        }

    </div>
}

const SidebarSpacer = styled('div')`
    margin-top: auto;
`

const needsConfirmations = {
    cookies: true,
    language: false,
    help: false,
    changelog: false,
    inbox: false
}