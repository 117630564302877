import {GridRenderCellParams, GridRowParams} from "@mui/x-data-grid-pro";
import {GridColumnConfig} from "./types/GridTypes";
import {GridButton} from "PlattixUI/core/components/Buttons";
import {Link} from "react-router-dom";
import moment from "moment";
import {toFixedDecimal} from "PlattixUI/util/numberUtil";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    GridRowOption,
    isGridRowOptionAction,
    isGridRowOptionLink
} from "PlattixUI/core/grid/gridStyling/GridOptions";
import {GridActionsCellItem} from "@mui/x-data-grid-pro";
import {history} from "PlattixUI/PlattixReactCore/store";
import {GridProps} from "PlattixUI/core/grid/PlattixDataGrid";
import {faCheck, faEdit, faTrash, faXmark} from "@fortawesome/free-solid-svg-icons";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {DeleteWithConfirm, QueryApiCallWithConfirmProps} from "PlattixUI/PlattixReactCore/api/ApiWithSwal";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {faCopy} from "@fortawesome/free-regular-svg-icons";
import {styled} from "goober";
import { toast } from "react-toastify";


type CellRenderer = ((cell: GridRenderCellParams) => React.ReactNode)

const CopyCell = styled('span')`
    width: 100%;
    position: relative;
    
    svg {
        position: absolute;
        right: 0;
        color: transparent;
        cursor: pointer;
    }
    
    &:hover svg {
        position: absolute;
        right: 0;
        color: gray;
    }
`

const EllipsisCell = styled('span')`
    width: 100%;
    display: inline-flex;
    text-overflow: ellipsis;
    overflow: hidden;
`
export const RightAlign = styled('span')`
    margin-left: auto;
`

export function GetCellRenderer(column: GridColumnConfig): CellRenderer {

    const renderer = GetCellRenderer2(column);

    if (column.copyable) {
        return (cell: GridRenderCellParams) => {
            if (!cell.value) return <EllipsisCell>{renderer(cell)}</EllipsisCell>
            return <CopyCell>
                <EllipsisCell>{renderer(cell)}</EllipsisCell>
                <CopyToClipboard onCopy={() => toast.success(t('cell.copied'))}  text={cell.value}><FontAwesomeIcon icon={faCopy}/></CopyToClipboard>
            </CopyCell>
        }
    }

    return (cell: GridRenderCellParams) => <EllipsisCell>{renderer(cell)}</EllipsisCell>;
}

function GetCellRenderer2(column: GridColumnConfig): CellRenderer {
    switch (column.displayType) {
        case "hyperlink":
            return formatHyperlink(column);
        case "hyperlinkarray":
            return formatHyperlinkArray(column);
        case "image":
            return formatImage(column);
        case "checkbox":
            return formatCheckbox(column);
        case "datetime":
        case "timespan":
        case "date":
        case "time":
            return formatDateTime(column)
        case "decimal":
            return formatDecimal(column)
        case "currency":
            return formatCurrency(column)
        case "timespan":
            return (cell) => <RightAlign>{formatNull(cell)}</RightAlign>
        // case "editabletexteditor":
        //     return renderInputText(column)
        // case "ellipsis":
        //     return formatEllipsis(column);
        case "text":
        default:
            return formatNull;
    }
}

const formatNull: CellRenderer = (cell: GridRenderCellParams) => (cell.value === null ?
    <span className="nullCell"/> : cell.value);

function formatHyperlinkArray(column: GridColumnConfig) {
    return (cell: GridRenderCellParams) => {
        if (cell.value) {
            return cell.value.split(",").map(s => <div key={s}>{formatHyperlink(column, s)?.(cell)}</div>)
        }
        return formatNull(cell);
    }
}

function formatHyperlink(column: GridColumnConfig, valueOverwrite?: any): CellRenderer {
    let url = column.extensionUrl;
    let prefix = column.gridColumnPrefix;
    const keyFieldName = column.extensionKeyFieldName;
    const prefixKeyFieldName = column.gridColumnPrefixColumnName;
    return (cell: GridRenderCellParams) => {
        const value = valueOverwrite ?? cell.value;

        if (value === null || value === undefined) return <></>

        if (url && keyFieldName && prefix && prefixKeyFieldName && value) {
            return <GridLink url={prefix + cell.row[prefixKeyFieldName] + url + cell.row[keyFieldName]} label={value}/>
        }
        if (url && prefix && prefixKeyFieldName){
            return <GridLink url={prefix + cell.row[prefixKeyFieldName] + url} label={value}/>
        }
        if (url && keyFieldName && value)
            return <GridLink url={url + cell.row[keyFieldName]} label={value}/>
        if (!keyFieldName && value.includes('|'))
            return <GridLink url={url + value.split('|')[0]} label={value.split('|')[1]}/>
        return <GridButton>{cell.value}</GridButton>
    }
}

function GridLink(props: { url: any, label: any }) {
    const isLocalUrl = props.url.startsWith('http')
    if (isLocalUrl) {
        return <a href={props.url}><GridButton>{props.label}</GridButton></a>
    }
    return <Link to={props.url}><GridButton>{props.label}</GridButton></Link>
}

function formatImage(column: GridColumnConfig): CellRenderer {
    return (cell: GridRenderCellParams) => <span>Images not yet supported</span>
}

function formatCheckbox(column: GridColumnConfig): CellRenderer {
    return (cell: GridRenderCellParams) => {
        if (!!cell.value) return <FontAwesomeIcon color={'green'} icon={faCheck}></FontAwesomeIcon>
        return <FontAwesomeIcon color={'red'} icon={faXmark}></FontAwesomeIcon>
    }
}

function formatDateTime(column: GridColumnConfig): CellRenderer {
    let format;

    switch (column.displayType) {
        case "date": 
            return renderDateCell
        case "time": 
            return renderTimeCell
        case "datetime":
        default:
            return renderDateTimeCell
    }
}

export function renderDateTimeCell(cell: GridRenderCellParams) {
    if (cell.value)
        return moment(cell.value).format("L LT")
    return formatNull(cell)
}

export function renderDateCell(cell: GridRenderCellParams) {
    if (cell.value)
        return moment(cell.value).format("L")
    return formatNull(cell)
}

export function renderTimeCell(cell: GridRenderCellParams) {
    if (cell.value)
        return moment(cell.value).format("LT")
    return formatNull(cell)
}

function formatDecimal(column: GridColumnConfig): CellRenderer {
    let numDecimals = 2;
    switch (column.format) {
        case "n":
            numDecimals = 2;
            break;
        case "n4":
            numDecimals = 4;
            break
    }
    return (cell: GridRenderCellParams) => <RightAlign>{toFixedDecimal(cell.value, numDecimals)}</RightAlign>
}

function formatCurrency(column: GridColumnConfig): CellRenderer {
    let numDecimals = 2;
    switch (column.format) {
        case "c":
            numDecimals = 2;
            break;
        case "c4":
            numDecimals = 4;
            break
    }
    return (cell: GridRenderCellParams) => <RightAlign>€{toFixedDecimal(cell.value, numDecimals)}</RightAlign>
}


const formatEncrypted: CellRenderer = (cell: GridRenderCellParams) => <span className="encryptedCell">********</span>

export function GetRowOptions(grid: GridProps, row: GridRowParams, gridReloader: () => void) {
    const options = grid.rowOptions
        ?.filter(option => option.showIf === undefined || option.showIf(row))
        .map(option => RowOptionComponent(option, row)) ?? [];

    if (grid.showDeleteButton) {
        const deleteProps: QueryApiCallWithConfirmProps<GridRowParams> =
            {
                ...grid.deleteWithConfirmProps,
                onSuccess: value => {
                    gridReloader();
                    grid.deleteWithConfirmProps?.onSuccess?.(value)
                }
            }

        const deleteRow = () => {
            DeleteWithConfirm(
                grid.deleteUrlGenerator(row),
                grid.deleteTitle ?? t('delete'),
                deleteProps
            )
        }

        options.unshift(
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faTrash}/>}
                onClick={deleteRow}
                label={t('delete')}
                showInMenu={!!grid.showDeleteInDropdown}/>
        )
    }

    if (grid.showEditButton) {
        const editRow = () => {
            const response = grid.onEdit(row);
            if (response) history.push(response)
        }

        options.unshift(
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faEdit}/>}
                onClick={editRow}
                label={t('edit')}
                showInMenu={!!grid.showEditInDropdown}/>
        )
    }

    return options
}

export function RowOptionComponent(option: GridRowOption, row: GridRowParams) {
    function onClick() {
        if (isGridRowOptionLink(option)) {
            let href;
            if (typeof option.href === "function") href = option.href(row);
            else href = option.href;

            history.push(href)
        }
        if (isGridRowOptionAction(option)) {
            option.onClick(row)
        }
    }

    function getIcon() {
        if (!option.icon) return
        return <FontAwesomeIcon icon={option.icon}/>
    }

    if (option.showInMenu ?? true)
        return <GridActionsCellItem icon={getIcon()} onClick={onClick} label={option.label} showInMenu={true}/>

    return <GridActionsCellItem icon={getIcon() ?? <></>} onClick={onClick} label={option.label} showInMenu={false}/>


}
