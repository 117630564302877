import React, {PropsWithChildren} from "react";
import {useAppSelector} from "../PlattixReactCore/hooks";
import {hasPlatformPermissionSelector, isAdminSelector, isInRoleSelector, Role} from "../PlattixReactCore/UserSlice";
import {NoPermissionErrorComponent} from "PlattixUI/core/components/errors/NoPermissionErrorComponent";
import {
    EntityPermissionsType,
    hasEntityPermission,
    PermissionsType,
    PlatformPermissionsType
} from "PlattixUI/PlattixReactCore/Permissions";

type ShowPermissionError =  {
    showError?: boolean
}

type PermissionsProps = PermissionsType & ShowPermissionError
type PlatformPermissionsProps = PlatformPermissionsType & ShowPermissionError
type EntityPermissionsProps = EntityPermissionsType & ShowPermissionError

export function RequirePermission(props: PropsWithChildren<PermissionsProps>) {
    const isAdmin = useAppSelector(isAdminSelector);

    if (isAdmin)
        return <div data-permission={props.permission}>
            {props.children}
        </div>
    
    if (props.entity === "Platform"){
        return <RequirePlatformPermission {...props} />
    } else {
        return <RequireEntityPermission {...props} entity={props.entity}/>
    }

}

export function RequirePlatformPermission(props: PropsWithChildren<Omit<PlatformPermissionsProps, "entity">>) {
    const hasPlatformPermission = useAppSelector(hasPlatformPermissionSelector);
    const isAdmin = useAppSelector(isAdminSelector);

    if (isAdmin)
        return <div className={'permission-wrapper'} data-permission={props.permission}>
            {props.children}
        </div>

    if (props.permission && !hasPlatformPermission(props.permission)) {
        if (props.showError) return <NoPermissionErrorComponent/>
        return <></>;
    }


    return <>{props.children}</>
}


export function RequireEntityPermission(props: PropsWithChildren<EntityPermissionsProps>) {
    const isAdmin = useAppSelector(isAdminSelector);

    if (isAdmin)
        return <div data-permission={props.permission}>
            {props.children}
        </div>

    if (!hasEntityPermission(props.entity, props.permission, props.action)) {
        if (props.showError) return <NoPermissionErrorComponent/>
        return <></>;
    }

    return <>{props.children}</>
}

export function HasNoPlatformPermission(props: PropsWithChildren<Omit<PlatformPermissionsProps, "entity">>) {
    const hasPlatformPermission = useAppSelector(hasPlatformPermissionSelector);
    const isAdmin = useAppSelector(isAdminSelector);

   /* if (isAdmin)
        return <div data-permission={props.permission}>
            {props.children}
        </div>*/

    if (props.permission && hasPlatformPermission(props.permission)) return <></>;


    return <>{props.children}</>
}

interface RoleProps extends ShowPermissionError {
    role: Role;
}

export function RequireRole(props: PropsWithChildren<RoleProps>) {
    const isAdmin = useAppSelector(isAdminSelector);
    const isInRole = useAppSelector(isInRoleSelector);

    if (isAdmin)
        return <div data-role={props.role}>
            {props.children}
        </div>

    if (props.role && !isInRole(props.role)) {
        if (props.showError) return <NoPermissionErrorComponent/>
        return <></>;
    }

    return <>{props.children}</>
}

export const RequireAdmin = (props: PropsWithChildren<ShowPermissionError>) => RequireRole({
    role: "Administrator",
    ...props
})

export const RequireDeveloper = (props: PropsWithChildren<ShowPermissionError>) => RequireRole({
    role: "Developer",
    ...props
})
