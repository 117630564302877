import {round} from "./numberUtil";
import {store} from "../PlattixReactCore/store";

export function DateDiffDay(date1: Date, date2: Date, decimals?: number) {
    const diff = (date1.getTime() - date2.getTime()) / (1000 * 3600 * 24);

    return round(diff, decimals ?? 0);
}

export function formatDateTime(date: Date | null | undefined, options?: Intl.DateTimeFormatOptions): string {
    if (!date) return '';

    const locale = store.getState().user.user?.locale;

    return date.toLocaleString(locale, options);
}

export function formatDate(date: Date | null | undefined, options?: Intl.DateTimeFormatOptions): string {
    if (!date) return '';

    const locale = store.getState().user.user?.locale;

    return date.toLocaleDateString(locale, options);
}

export function formatTime(date: Date | null | undefined, options?: Intl.DateTimeFormatOptions): string {
    if (!date) return '';

    const locale = store.getState().user.user?.locale;

    return date.toLocaleTimeString(locale, options);
}

/** Check if a date is older than <seconds> seconds*/
export function isOlder(date: Date | undefined, seconds: number) {
    if (!date) return false;

    return (new Date()).getTime() - date.getTime() > seconds * 1000;
}

export function toISODateString(date: string | Date | undefined | null){
    if (!date) return date;
    if (typeof date === 'string') return Date.parse(date) ? new Date(date).toISODateString() : null;
    return date.toISODateString();
}

export function isValidDate(date: any){
    return date instanceof Date && !isNaN(date as any);
}

/**
 * Convert a date representation to a ISO string in the current browser timezone
 * @param date
 */
export function toLocalISOString(date: string | Date | undefined | number | null){
    if (!date) return "";
    const d = new Date(date);
    d.setUTCHours(d.getHours(),d.getMinutes());
    return d.toISOString().slice(0, -1) // slice of Z
}

/**
 * Convert a date representation to an ISO string (but without seconds) in the current browser timezone
 * @param date
 */
export function toLocalISOStringWithoutSeconds(date: string | Date | undefined | number | null){
    if (!date) return "";
    const d = new Date(date);
    d.setUTCHours(d.getHours(),d.getMinutes());
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d.toISOString().slice(0, -1) // slice of Z
}
/**
 * Convert a date representation to a ISO DATE string in the current browser timezone
 * @param date
 */
export function toLocalISODateString(date: string | Date | undefined | number | null){
    if (!date) return "";
    const d = new Date(date);
    d.setUTCHours(d.getHours(),d.getMinutes());
    return d.toISODateString()
}

const DateUtil = {DateDiffDay, formatDate, formatTime, formatDateTime, isOlder}
export default DateUtil