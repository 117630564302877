import React, {useMemo, useState} from 'react';
import {CancelButton, ConfirmButton} from "../../PlattixUI/core/components/Buttons";
import {Endpoints} from "../../configuration/ApiEnpointMap";
import {PlattixCard, PlattixCardContainer} from "../../PlattixUI/core/components/ContentCard";
import {PageHeader} from "../../PlattixUI/core/Header";
import {t} from "../../PlattixUI/PlattixReactCore/i18n";
import {Actions, ActionsLeft, ActionsRight} from "../../PlattixUI/core/components/ActionBar";
import {history} from "../../PlattixUI/PlattixReactCore/store";
import {DocumentScanUrl} from "../DocScan/DocumentScanRoutes";
import {PlattixSubmitField, PlattixSubmitForm} from "../../PlattixUI/core/components/form/Form";
import {Max, Min, Required} from "../../PlattixUI/core/forms/FormValidators";
import {toast} from "react-toastify";
import {PlattixTextTitle} from "../../PlattixUI/core/components/Text";

interface HangfireConfig {
    hour: number;
}

export function HangfireConfig() {
    const hangfireConfigFields = useMemo<PlattixSubmitField<HangfireConfig>[]>(() => [
        {
            name: "hour",
            label: 'hour (0-23)',
            validation: [Required(),Min(0), Max(23)]
        }
    ],[]);
    
    return (
        <div>
            <PageHeader title='Hangfire Configuration' description='Perform configuration of hangfire jobs'/>
            <Actions>
                <ActionsLeft>
                    <CancelButton onClick={() => history.push(DocumentScanUrl)}>{t('BackToOverview')}</CancelButton>
                </ActionsLeft>
                <ActionsRight>
                    <ConfirmButton onClick={() => window.location.assign('/hangfire')}>Go to Hangfire Dashboard</ConfirmButton>
                </ActionsRight>
            </Actions>
            <PlattixCardContainer>
                <PlattixCard header='Rossum Export' collapsible className={'content-column-span2'}>
                    <PlattixTextTitle alignment={'center'} description='Configure daily scheduling time to export documents from Rossum (in 24h format)'>
                            Daily Rossum Export
                    </PlattixTextTitle>
                    <PlattixSubmitForm
                        formName={'HangfireDailyRossumExportConfigForm'}
                        fields={hangfireConfigFields}    
                        postUrl={Endpoints.Job.ScheduleDailyRossumExport}
                        onSuccess={() => toast.success(t('ChangesSaved'))}
                    />
                </PlattixCard>
            </PlattixCardContainer>
        </div>
    )


}