import {FeedbackComponent} from "../Partials/Feedback";
import {PlattixCard, PlattixCardContainer} from "PlattixUI/core/components/ContentCard";
import Matter, {Bodies, Engine, Render, Svg, World} from "matter-js";
import {styled} from "goober";
import {forwardRef, useEffect, useRef} from "react";
import icon from 'PlattixUI/images/branding/energytix/icon/iconTransparent.svg';
import {t} from "PlattixUI/PlattixReactCore/i18n";

// TODO: Matter JS afwerken en SVG logo geanimeerd krijgen

export function ErrorFallbackScreen1(props: {error, resetErrorBoundary}) {
    
    // // region MatterJs
    //
    // // create a scene
    // const scene = useRef(null);
    //
    // // create an engine
    // const engine = useRef(Engine.create());
    //
    // const isPressed = useRef(false)
    //
    // useEffect(() => {
    //     // mount
    //     const cw = document.body.clientWidth
    //     const ch = document.body.clientHeight
    //
    //     const render = Render.create({
    //         element: scene.current,
    //         engine: engine.current,
    //         options: {
    //             width: cw,
    //             height: ch,
    //             wireframes: false,
    //             background: 'transparent'
    //         }
    //     })
    //
    //     // boundaries
    //     World.add(engine.current.world, [
    //         Bodies.rectangle(cw / 2, -10, cw, 20, { isStatic: true }),
    //         Bodies.rectangle(-10, ch / 2, 20, ch, { isStatic: true }),
    //         Bodies.rectangle(cw / 2, ch + 10, cw, 20, { isStatic: true }),
    //         Bodies.rectangle(cw + 10, ch / 2, 20, ch, { isStatic: true })
    //     ])
    //
    //     // run the engine
    //     Engine.run(engine.current)
    //     Render.run(render)
    //
    //     // // create runner
    //     // const runner = Runner.create();
    //     //
    //     // // run the engine
    //     // Runner.run(runner, engine);
    //
    //     // unmount
    //     return () => {
    //         // destroy Matter
    //         Render.stop(render)
    //         World.clear(engine.current.world)
    //         Engine.clear(engine.current)
    //         render.canvas.remove()
    //         render.canvas = null
    //         render.context = null
    //         render.textures = {}
    //     }
    // }, []);
    //
    // const handleDown = () => {
    //     isPressed.current = true
    // }
    //
    // const handleUp = () => {
    //     isPressed.current = false
    // }
    //
    // const handleAddCircle = (e) => {
    //     if (isPressed.current) {
    //         const ball = Bodies.circle(
    //             e.clientX,
    //             e.clientY,
    //             10 + Math.random() * 30,
    //             {
    //                 mass: 10,
    //                 restitution: 0.9,
    //                 friction: 0.005,
    //                 render: {
    //                     fillStyle: '#0000ff'
    //                 }
    //             })
    //         World.add(engine.current.world, [ball])
    //     }
    // }
    //
    //
    // // endregion
    
    // // region MatterJs
    //
    // // create a scene
    // const scene = useRef(null);
    //
    // // create an engine
    // const engine = useRef(Engine.create());
    //
    // const isPressed = useRef(false)
    //
    // useEffect(() => {
    //     // mount
    //     const cw = document.body.clientWidth
    //     const ch = document.body.clientHeight
    //
    //     const render = Render.create({
    //         element: scene.current,
    //         engine: engine.current,
    //         options: {
    //             width: cw,
    //             height: ch,
    //             wireframes: false,
    //             background: 'transparent'
    //         }
    //     })
    //
    //     // voorbeeldballen
    //     const ballA = Bodies.circle(
    //         210, 
    //         100, 
    //         30, 
    //         { 
    //             restitution: 0.5 
    //         }
    //     );
    //    
    //     const ballB = Bodies.circle(
    //         110, 
    //         50, 
    //         30, 
    //         { 
    //             restitution: 0.5 
    //         }
    //     );
    //    
    //     const ballC = Bodies.circle(
    //         410,
    //         75,
    //         10 + Math.random() * 30,
    //         {
    //             mass: 10,
    //             restitution: 0.9,
    //             friction: 0.005,
    //             render: {
    //                 fillStyle: '#0000ff'
    //             }
    //         });
    //    
    //     const bodies = [
    //         Bodies.rectangle(400, 210, 810, 60, {isStatic: true}),
    //         ...[...document.querySelectorAll("svg > path")].map(path => {
    //             const body = Bodies.fromVertices(
    //                 100, 80, Svg.pathToVertices(path), {}, true
    //             );
    //             Body.scale(body, 0.2, 0.2);
    //             return body;
    //         })
    //     ];
    //
    //     // boundaries
    //     World.add(engine.current.world, [
    //         Bodies.rectangle(cw / 2, -10, cw, 20, { isStatic: true }),
    //         Bodies.rectangle(-10, ch / 2, 20, ch, { isStatic: true }),
    //         Bodies.rectangle(cw / 2, ch + 10, cw, 20, { isStatic: true }),
    //         Bodies.rectangle(cw + 10, ch / 2, 20, ch, { isStatic: true })
    //     ]);
    //
    //     World.add(engine.current.world, [ballA, ballB, ballC]);
    //     // World.add(engine.current.world, bodies);
    //
    //     // run the engine
    //     Engine.run(engine.current)
    //     Render.run(render)
    //
    //     // unmount
    //     return () => {
    //         // destroy Matter
    //         Render.stop(render)
    //         World.clear(engine.current.world)
    //         Engine.clear(engine.current)
    //         render.canvas.remove()
    //         render.canvas = null
    //         render.context = null
    //         render.textures = {}
    //     }
    // }, []);
    //
    // const handleDown = () => {
    //     isPressed.current = true
    // }
    //
    // const handleUp = () => {
    //     isPressed.current = false
    // }
    //
    // const handleAddCircle = (e) => {
    //     if (isPressed.current) {
    //         const ball = Bodies.circle(
    //             e.clientX,
    //             e.clientY,
    //             10 + Math.random() * 30,
    //             {
    //                 mass: 10,
    //                 restitution: 0.9,
    //                 friction: 0.005,
    //                 render: {
    //                     fillStyle: '#0000ff'
    //                 }
    //             })
    //         World.add(engine.current.world, [ball])
    //     }
    // }
    //
    //
    // // endregion
    
    // region MatterJs

    // create a scene
    const scene = useRef(null);
    
    const iconRef = useRef(null);

    // create an engine
    const engine = useRef(Engine.create());

    const isPressed = useRef(false)

    useEffect(() => {
        // mount
        const cw = document.body.clientWidth
        const ch = document.body.clientHeight

        const render = Render.create({
            element: scene.current,
            engine: engine.current,
            options: {
                width: cw,
                height: ch,
                wireframes: false,
                background: 'transparent'
            }
        })

        // boundaries
        World.add(engine.current.world, [
            Bodies.rectangle(cw / 2, -10, cw, 20, { isStatic: true }),
            Bodies.rectangle(-10, ch / 2, 20, ch, { isStatic: true }),
            Bodies.rectangle(cw / 2, ch + 10, cw, 20, { isStatic: true }),
            Bodies.rectangle(cw + 10, ch / 2, 20, ch, { isStatic: true })
        ]);

        // const bodies = [
        //     // Bodies.rectangle(400, 210, 810, 60, {isStatic: true}),
        //     ...[...document.querySelectorAll("svg > path")].map(path => {
        //         console.log(path)
        //         const body = Bodies.fromVertices(
        //             20, 20, Svg.pathToVertices(iconRef), {}, true
        //         );
        //         body.scale(body, 0.2, 0.2);
        //         return body;
        //     })
        // ];

        // World.add(engine.current.world, bodies);

        // run the engine
        Engine.run(engine.current)
        Render.run(render)

        // unmount
        return () => {
            // destroy Matter
            Render.stop(render)
            World.clear(engine.current.world)
            Engine.clear(engine.current)
            render.canvas.remove()
            render.canvas = null
            render.context = null
            render.textures = {}
        }
    }, []);

    // const handleDown = () => {
    //     isPressed.current = true
    // }
    //
    // const handleUp = () => {
    //     isPressed.current = false
    // }
    //
    // const handleAddCircle = (e) => {
    //     if (isPressed.current) {
    //         const ball = Bodies.circle(
    //             e.clientX,
    //             e.clientY,
    //             10 + Math.random() * 30,
    //             {
    //                 mass: 10,
    //                 restitution: 0.9,
    //                 friction: 0.005,
    //                 render: {
    //                     fillStyle: '#0000ff'
    //                 }
    //             })
    //         World.add(engine.current.world, [ball])
    //     }
    // }


    // endregion
    
    return <>
        {/*<h1>{t('React.Error.Title')}</h1>*/}
        {/*<img src={"https://c.tenor.com/7nBep3kaaJsAAAAC/explosion-windturbine.gif"} alt={'broken mill'} style={{width: '33vw'}}/>*/}
        {/*<EditButton onClick={window.location.reload}>{t('React.Error.ReloadPage')}</EditButton>*/}
        
        <img src={icon} alt={'Icon'} ref={iconRef} />
        
        {/*<h4>{t('React.Error.ProblemPersists')}</h4>*/}
        <PlattixCardContainer>
            {/*<PlattixCard header={'React.Error.WhatHappened.Title'}>*/}
            {/*    <FeedbackComponent standAlone />*/}
            {/*</PlattixCard>*/}
            
            <MatterContainer
                // onMouseDown={handleDown}
                // onMouseUp={handleUp}
                // onMouseMove={handleAddCircle}
            >
                <MatterContent
                    ref={scene}
                />
            </MatterContainer>
            
        </PlattixCardContainer>
    </>
}

export function ErrorFallbackScreen2(props: {error, resetErrorBoundary}) {

    const paths = [
        "M289.05,84.63L98.225,308.136c-6.35,7.44-9.126,17.291-7.594,26.954l10.514,66.144c1.32,8.312,8.484,14.441,16.901,14.44h66.971c9.785,0,19.079-4.283,25.434-11.725l206.015-241.29c5.93-6.943,5.104-17.379-1.841-23.305l-42.83-36.57c-6.946-5.928-17.38-5.104-23.31,1.837l-152.4,178.477c-3.312,3.872-1.941,7.557-0.754,15.562c0.647,4.358,4.409,7.572,8.817,7.528c8.201-0.082,11.959,0.677,15.254-3.186l104.745-122.653l30.031,25.641l-108.396,126.93c-5.823,6.821-14.307,10.795-23.272,10.904h-40.639c-10.349,0.124-19.191-6.943-20.691-17.18l-5.896-40.215c-1.304-8.875,1.292-17.878,7.118-24.697L321.757,75.111c18.261-21.39,50.409-23.929,71.803-5.664l50.576,43.18c21.395,18.265,23.932,50.414,5.666,71.806L235.457,435.479c-10.447,12.243-25.735,19.284-41.83,19.271h-89.321c-19.688-0.012-36.449-14.382-39.549-33.821l-14.072-88.213c-2.53-15.89,2.027-32.097,12.479-44.339L259.017,58.988L289.05,84.63z"
    ];
    
    // region MatterJs

    // create a scene
    const scene = useRef(null);
    
    // create an engine
    const engine = useRef(Engine.create());

    const isPressed = useRef(false)

    useEffect(() => {
        // mount
        const cw = document.body.clientWidth
        const ch = document.body.clientHeight

        const render = Render.create({
            element: scene.current,
            engine: engine.current,
            options: {
                width: cw,
                height: ch,
                wireframes: false,
                background: 'transparent'
            }
        })

        // boundaries
        World.add(engine.current.world, [
            Bodies.rectangle(cw / 2, -10, cw, 20, { isStatic: true }),
            Bodies.rectangle(-10, ch / 2, 20, ch, { isStatic: true }),
            Bodies.rectangle(cw / 2, ch + 10, cw, 20, { isStatic: true }),
            Bodies.rectangle(cw + 10, ch / 2, 20, ch, { isStatic: true })
        ]);

        // const bodies = [
        //     // Bodies.rectangle(400, 210, 810, 60, {isStatic: true}),
        //     ...[...document.querySelectorAll("svg > path")].map(path => {
        //         console.log(path)
        //         const body = Bodies.fromVertices(
        //             20, 20, Svg.pathToVertices(iconRef), {}, true
        //         );
        //         body.scale(body, 0.2, 0.2);
        //         return body;
        //     })
        // ];

        // World.add(engine.current.world, bodies);
        
        let vertexSets: any[] = [],
            color = Matter.Common.choose([
                "#556270",
                "#4ECDC4",
                "#C7F464",
                "#FF6B6B",
                "#C44D58"
            ]);

        paths.forEach((path, i) => {
            const newElement = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "path"
            );
            newElement.setAttribute("d", path);

            const points = Svg.pathToVertices(newElement, 30);
            vertexSets.push(Matter.Vertices.scale(points, 0.4, 0.4));

            World.add(
                engine.current.world,
                Bodies.fromVertices(
                    // 100 + i * 150,
                    // 200 + i * 50,
                    800,
                    400,
                    vertexSets,
                    {
                        render: {
                            fillStyle: color,
                            strokeStyle: color,
                            lineWidth: 1
                        }
                    },
                    true
                )
            );
        });

        
        

        // run the engine
        Engine.run(engine.current)
        Render.run(render)

        // unmount
        return () => {
            // destroy Matter
            Render.stop(render)
            World.clear(engine.current.world)
            Engine.clear(engine.current)
            render.canvas.remove()
            render.canvas = null
            render.context = null
            render.textures = {}
        }
    }, []);

    // const handleDown = () => {
    //     isPressed.current = true
    // }
    //
    // const handleUp = () => {
    //     isPressed.current = false
    // }
    //
    // const handleAddCircle = (e) => {
    //     if (isPressed.current) {
    //         const ball = Bodies.circle(
    //             e.clientX,
    //             e.clientY,
    //             10 + Math.random() * 30,
    //             {
    //                 mass: 10,
    //                 restitution: 0.9,
    //                 friction: 0.005,
    //                 render: {
    //                     fillStyle: '#0000ff'
    //                 }
    //             })
    //         World.add(engine.current.world, [ball])
    //     }
    // }


    // endregion
    
    return <>
        {/*<h1>{t('React.Error.Title')}</h1>*/}
        {/*<img src={"https://c.tenor.com/7nBep3kaaJsAAAAC/explosion-windturbine.gif"} alt={'broken mill'} style={{width: '33vw'}}/>*/}
        {/*<EditButton onClick={window.location.reload}>{t('React.Error.ReloadPage')}</EditButton>*/}
        
        {/*<h4>{t('React.Error.ProblemPersists')}</h4>*/}
        <PlattixCardContainer>
            {/*<PlattixCard header={'React.Error.WhatHappened.Title'}>*/}
            {/*    <FeedbackComponent standAlone />*/}
            {/*</PlattixCard>*/}
            
            <MatterContainer
                // onMouseDown={handleDown}
                // onMouseUp={handleUp}
                // onMouseMove={handleAddCircle}
            >
                <MatterContent
                    ref={scene}
                />
            </MatterContainer>
            
        </PlattixCardContainer>
    </>
}

export function ErrorFallbackScreen3(props: {error, resetErrorBoundary}) {

    
    // region MatterJs

    // create a scene
    const scene = useRef(null);
    
    // create an engine
    const engine = useRef(Engine.create());
    
    const paths = [
        "M289.05,84.63L98.225,308.136c-6.35,7.44-9.126,17.291-7.594,26.954l10.514,66.144c1.32,8.312,8.484,14.441,16.901,14.44h66.971c9.785,0,19.079-4.283,25.434-11.725l206.015-241.29c5.93-6.943,5.104-17.379-1.841-23.305l-42.83-36.57c-6.946-5.928-17.38-5.104-23.31,1.837l-152.4,178.477c-3.312,3.872-1.941,7.557-0.754,15.562c0.647,4.358,4.409,7.572,8.817,7.528c8.201-0.082,11.959,0.677,15.254-3.186l104.745-122.653l30.031,25.641l-108.396,126.93c-5.823,6.821-14.307,10.795-23.272,10.904h-40.639c-10.349,0.124-19.191-6.943-20.691-17.18l-5.896-40.215c-1.304-8.875,1.292-17.878,7.118-24.697L321.757,75.111c18.261-21.39,50.409-23.929,71.803-5.664l50.576,43.18c21.395,18.265,23.932,50.414,5.666,71.806L235.457,435.479c-10.447,12.243-25.735,19.284-41.83,19.271h-89.321c-19.688-0.012-36.449-14.382-39.549-33.821l-14.072-88.213c-2.53-15.89,2.027-32.097,12.479-44.339L259.017,58.988L289.05,84.63z"
    ];

    useEffect(() => {
        // mount
        const cw = document.body.clientWidth
        const ch = document.body.clientHeight

        const render = Render.create({
            element: scene.current,
            engine: engine.current,
            options: {
                width: cw,
                height: ch,
                wireframes: false,
                background: 'transparent'
            }
        })

        // boundaries
        World.add(engine.current.world, [
            Bodies.rectangle(cw / 2, -10, cw, 20, { isStatic: true }),
            Bodies.rectangle(-10, ch / 2, 20, ch, { isStatic: true }),
            Bodies.rectangle(cw / 2, ch + 10, cw, 20, { isStatic: true }),
            Bodies.rectangle(cw + 10, ch / 2, 20, ch, { isStatic: true })
        ]);

            const ballB = Bodies.circle(
                110, 
                50, 
                30, 
                { 
                    restitution: 0.5 
                }
            );

        const bodies = [
            // Bodies.rectangle(400, 210, 810, 60, {isStatic: true}),
            // ...[...document.querySelectorAll("svg > path")].map(path => {
            //     console.log(path)
            //     const body = Bodies.fromVertices(
            //         20, 20, Svg.pathToVertices(iconRef), {}, true
            //     );
            //     body.scale(body, 0.2, 0.2);
            //     return body;
            // })
            ballB
        ];

        World.add(engine.current.world, bodies);
        
        let vertexSets: any[] = [],
            color = Matter.Common.choose([
                "#556270",
                "#4ECDC4",
                "#C7F464",
                "#FF6B6B",
                "#C44D58"
            ]);

        paths.forEach((path, i) => {
            const newElement = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "path"
            );
            newElement.setAttribute("d", path);

            const points = Svg.pathToVertices(newElement, 30);
            vertexSets.push(Matter.Vertices.scale(points, 0.4, 0.4));

            World.add(
                engine.current.world,
                Bodies.fromVertices(
                    // 100 + i * 150,
                    // 200 + i * 50,
                    800,
                    400,
                    vertexSets,
                    {
                        render: {
                            fillStyle: color,
                            strokeStyle: color,
                            lineWidth: 1
                        }
                    },
                    true
                )
            );
        });

        
        

        // run the engine
        Engine.run(engine.current)
        Render.run(render)

        // unmount
        return () => {
            // destroy Matter
            Render.stop(render)
            World.clear(engine.current.world)
            Engine.clear(engine.current)
            render.canvas.remove()
            render.canvas = null
            render.context = null
            render.textures = {}
        }
    }, []);

    // const handleDown = () => {
    //     isPressed.current = true
    // }
    //
    // const handleUp = () => {
    //     isPressed.current = false
    // }
    //
    // const handleAddCircle = (e) => {
    //     if (isPressed.current) {
    //         const ball = Bodies.circle(
    //             e.clientX,
    //             e.clientY,
    //             10 + Math.random() * 30,
    //             {
    //                 mass: 10,
    //                 restitution: 0.9,
    //                 friction: 0.005,
    //                 render: {
    //                     fillStyle: '#0000ff'
    //                 }
    //             })
    //         World.add(engine.current.world, [ball])
    //     }
    // }


    // endregion
    
    return <>
        {/*<h1>{t('React.Error.Title')}</h1>*/}
        {/*<img src={"https://c.tenor.com/7nBep3kaaJsAAAAC/explosion-windturbine.gif"} alt={'broken mill'} style={{width: '33vw'}}/>*/}
        {/*<EditButton onClick={window.location.reload}>{t('React.Error.ReloadPage')}</EditButton>*/}
        
        {/*<h4>{t('React.Error.ProblemPersists')}</h4>*/}
        <PlattixCardContainer>
            {/*<PlattixCard header={'React.Error.WhatHappened.Title'}>*/}
            {/*    <FeedbackComponent standAlone />*/}
            {/*</PlattixCard>*/}
            
            <MatterContainer
                // onMouseDown={handleDown}
                // onMouseUp={handleUp}
                // onMouseMove={handleAddCircle}
            >
                <MatterContent
                    ref={scene}
                />
            </MatterContainer>
            
        </PlattixCardContainer>
    </>
}

export function ErrorFallbackScreen(props: {error, resetErrorBoundary}) {
    
    return <>
        <FullPageCenteredContent>
            <h1>{t('React.Error.Title')}</h1>
            <PlattixCardContainer>
                <PlattixCard header={'React.Error.WhatHappened.Title'}>
                    <FeedbackComponent standAlone />
                </PlattixCard>
            </PlattixCardContainer>
        </FullPageCenteredContent>
    </>
}

export const MatterContainer = styled('div', forwardRef)(() => {
    return `
        border: 1px dotted red;
        position: fixed;
        left: 0;
        top: 0;
    `;
});

export const MatterContent = styled('div', forwardRef)(() => {
    return `
        border: 1px dotted red;
    `;
});

export const FullPageCenteredContent = styled('div')(() => {
    return `
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
        flex-flow: column nowrap;
        background: radial-gradient(circle at center, transparent 80%, #CC3B3333 100%);
        animation: colorPulse 1s infinite alternate cubic-bezier(0.58, -0.17, 0.56, 1.4);
        
        @keyframes colorPulse {
            0% {
                background-size: 100% 100%;
                background-position: center center;
            }
            
            100% {
                background-size: 125% 125%;
                background-position: center center;
            }
        }
        
        & > .content-masonry {
            display: flex;
            width: fit-content;
            min-width: 400px;
            
        }
    `;
});