import {Slugs} from "../../configuration/Configuration";

export const DeveloperRouteMap = {
    Grid: {
        base: `${Slugs.Developer}/Grid`,
        Overview: () => `${DeveloperRouteMap.Grid.base}`,
        Detail: () => `${DeveloperRouteMap.Grid.base}/:gridId`,
    },
}

export const DefaultAdminRouteMap = {
    base: `${Slugs.Admin}`,
    UsersOverview: () => `${DefaultAdminRouteMap.base}/Users`,
    UserDetails: () => `${DefaultAdminRouteMap.base}/Users/:UserId`,
};