import {DocumentScanProduct} from "./types/Document";
import {FormGridColDef} from "./types/FormColDef";
import {GridEvents, useGridApiContext} from "@mui/x-data-grid-pro";
import React, {useCallback, useEffect, useState} from "react";
import {PlattixSubmitField, PlattixSubmitForm} from "../../PlattixUI/core/components/form/Form";
import {Required} from "../../PlattixUI/core/forms/FormValidators";
import {toast} from "react-toastify";
import {t} from "../../PlattixUI/PlattixReactCore/i18n";
import {styled} from "goober";

const DetailPanel = styled('div')((props: DetailPanelProps) => `
    width: calc(${props.width}px + 10px);
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
    border: 10px solid var(--styleColor1);
    position: sticky;
    left: 0;
`);

interface DetailPanelProps extends React.HTMLAttributes<HTMLDivElement> {
    width: number
}

export interface DocumentScanProductPanelContentProps {
    row: DocumentScanProduct,
    columns: FormGridColDef<DocumentScanProduct>[],
}

interface inputFieldConfigProps {
    readonly: boolean,
    data: DocumentScanProduct,
    fields: PlattixSubmitField<DocumentScanProduct>[],
    postUrl: string,
}

function DocumentScanProductPanelContent(props: DocumentScanProductPanelContentProps) {
    const apiRef = useGridApiContext();
    const [width, setWidth] = useState(() => {
        const dimensions = apiRef.current.getRootDimensions();
        return dimensions!.viewportInnerSize.width;
    });

    const handleViewportInnerSizeChange = useCallback(() => {
        const dimensions = apiRef.current.getRootDimensions();
        setWidth(dimensions!.viewportInnerSize.width);
    }, [apiRef]);

    useEffect(() => {
        return apiRef.current.subscribeEvent(
            GridEvents.viewportInnerSizeChange,
            handleViewportInnerSizeChange,
        );
    }, [apiRef, handleViewportInnerSizeChange]);

    const getInputFields = () => {
        let arr: PlattixSubmitField<DocumentScanProduct>[] = [
            {
                name: "id",
                label: '',
                type: 'hidden',
                validation: [Required(),]
            }
        ];

        props.columns.forEach((d, i) => {
            if (d.field == "options") return;
            arr.push({
                name: d.field,
                label: d.headerName || '',
                readOnly: !d.editable || false,
            });
        });

        return arr;
    };

    const getInputFieldsData = () => {
        let obj = {
            id: props.row.id,
        };
        props.columns.forEach((d, i) => obj[d.field] = props.row[d.field]);
        return obj;

    }

    const inputFieldConfig: inputFieldConfigProps = {
        readonly: false,
        data: getInputFieldsData(),
        fields: getInputFields(),
        postUrl: '',
    };

    return (
        <DetailPanel width={width}>
            <PlattixSubmitForm<DocumentScanProduct, DocumentScanProduct>
                formName={inputFieldConfig.readonly ? `documentScanDetailedRowForm_${props.row.id}` : ''}
                fields={inputFieldConfig.fields}
                readOnly={inputFieldConfig.readonly}
                defaultValue={inputFieldConfig.data}
                postUrl={inputFieldConfig.postUrl}
                onSuccess={() => toast.success(t('ChangesSaved'))}
            />
        </DetailPanel>
    );
}

export default DocumentScanProductPanelContent;