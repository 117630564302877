import {RouteProps} from "react-router";
import {isAdminSelector, isDeveloperSelector, isInRoleSelector, Role} from "../../PlattixReactCore/UserSlice";
import {hasPermission, PermissionsType} from "../../PlattixReactCore/Permissions";
import {useAppSelector} from "../../PlattixReactCore/hooks";
import {Redirect, Route} from "react-router-dom";
import {filterProps} from "../../util/ElementProperties";
import React from "react";


type PlattixRouteProps = RouteProps
    & {
    role?: Role
}
    & PermissionsType

export function RouteWithPermissions(props: PlattixRouteProps){
    const inRole = useAppSelector(isInRoleSelector)
    
    if (props.role && !inRole(props.role))
        return <Route {...props} component={undefined}><Redirect to={'/'}/></Route>;
    if (props.entity && !hasPermission(props.entity, props.permission, props.action))
        return <Route {...props} component={undefined}><Redirect to={'/'}/></Route>;

    return <Route {...filterProps(props, ['role', 'entity', 'permission', 'action'])} />
}


export function AdminRoute(props: RouteProps){
    const isAdmin = useAppSelector(isAdminSelector)

    if (!isAdmin)
        return <Route {...props} component={undefined}><Redirect to={'/'}/></Route>;

    return <Route {...props} />
}

export function DevRoute(props: RouteProps){
    const isDev = useAppSelector(isDeveloperSelector)

    if (!isDev)
        return <Route {...props} component={undefined}><Redirect to={'/'}/></Route>;

    return <Route {...props} />
}