import {SweetAlertIcon} from "sweetalert2"
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";
import {doDelete, doPost, GetParams, isHttpError, requestOptions} from "PlattixUI/PlattixReactCore/api/Api";
import {toast} from "react-toastify";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {PlattixSwal} from "PlattixUI/core/components/Swal";


interface ApiCallWithConfirmProps<T> {
    /**
     * Optional confirm Description
     */
    confirmDescription?: string,
    /**
     * Swal icon to use. Default: 'warning'
     */
    icon?:  SweetAlertIcon,

    /**
     * Show a toast on success.
     * Default: true
     */
    toastOnSuccess?: boolean,
    /**
     * On success handler
     * @param value: return value of delete action
     */
    onSuccess?: (value: T) => void,
    /**
     * Success message
     * Default: t('delete.sucess')
     */
    successMessage?: string,

    /**
     * On Error handler
     * @param HttpError: Error that occurred
     */
    onError?: (HttpError) => void,
    /**
     * Error message
     * Default: t('delete.failed')
     */    
    errorMessage?: string,
    /**
     * Show a swal on error.
     * Default: true
     */
    showSwalOnError?: boolean,

    /**
     * Optional {@link requestOptions: request options}
     */
    options?: requestOptions,
    
    confirmButtonColor?:  "red" | "green" | "blue" | "grey"
    
}

export interface QueryApiCallWithConfirmProps<T> extends ApiCallWithConfirmProps<T> {

    /**
     * Optional query parameters
     */
    params?: GetParams
}

export interface BodyApiCallWithConfirmProps<T> extends ApiCallWithConfirmProps<T> {

    /**
     * Optional query parameters
     */
    data?: any
}

/**
 * Do a delete with a Swal to confirm deletion
 * @param url: route to post DELETE to
 * @param confirmTitle: Confirm swal title
 * @param props: {@link DeleteWitchConfirmProps}
 */
export function DeleteWithConfirm<T>(url: string, confirmTitle: string, props?: QueryApiCallWithConfirmProps<T>){
    ApiCallWithConfirm(() => doDelete<T>(url, props?.params, props?.options), confirmTitle, {
        confirmButtonColor: 'red',
        ...props
    })
}

/**
 * Do a Post with a Swal to confirm
 * @param url: route to POST to
 * @param data: data to POST
 * @param confirmTitle: Confirm swal title
 * @param props: {@link BodyApiCallWithConfirmProps}
 */
export function PostWithConfirm<T>(url: string, data: any, confirmTitle: string, props?: BodyApiCallWithConfirmProps<T>){
    ApiCallWithConfirm(() => doPost<T>(url, data, props?.options), confirmTitle, 
        {...props, successMessage: props?.successMessage ?? t('success')}
    )
}

/**
 * Do a delete with a Swal to confirm deletion
 * @param apiCall: 
 * @param confirmTitle: Confirm swal title
 * @param props: {@link DeleteWitchConfirmProps}
 */
function ApiCallWithConfirm<T>(apiCall: () => Promise<T | HttpError>, confirmTitle: string, props?: ApiCallWithConfirmProps<T>){
    PlattixSwal({
        title: confirmTitle,
        html: props?.confirmDescription,
        icon: props?.icon ?? "warning",
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonColor: props?.confirmButtonColor,
        preConfirm: apiCall
    }).then(
        result => {
            if (result.isConfirmed) {
                if (isHttpError(result.value)) {
                    if (props?.showSwalOnError ?? true) PlattixSwal({icon: "error", title: props?.errorMessage ?? result.value.detail ?? t('response.servererror')})
                    props?.onError?.(result.value)
                }
                else {
                    if (props?.toastOnSuccess ?? true) toast(props?.successMessage ?? t('delete.success'), {type: "success"})
                    props?.onSuccess?.(result.value as T)
                }
            }
        }
    )
}