import * as React from "react";
import {textAlign} from "html2canvas/dist/types/css/property-descriptors/text-align";
import {styled} from "goober";

const PlattixTextContainer = styled('div')`
    width: 100%;
    grid-column: 1/-1;
`;

export type alignmentTypes = 'left' | 'center' | 'right';

export interface PlattixTextTitleProps extends React.HTMLAttributes<HTMLHeadingElement> { 
    alignment?: alignmentTypes,
    description?: string,
}

export function PlattixTextTitle(props: React.PropsWithChildren<PlattixTextTitleProps>) {
    return <PlattixTextContainer>
        <h5 style={{textAlign: props.alignment}}>{props.children}</h5>
        {
            props.description && 
            <p style={{textAlign: props.alignment}}>{props.description}</p>
        }
    </PlattixTextContainer>;
}

export function PlattixText(props: React.PropsWithChildren<PlattixTextTitleProps>) {
    return <PlattixTextContainer>
            <p style={{textAlign: props.alignment}}>{props.children}</p>
    </PlattixTextContainer>;
}