import i18n, {TOptions} from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
//To load the translation files

i18n.on('languageChanged', function (lng: string) {
    localStorage.setItem("lng", lng);
})

// Default translations

const versionNumber = new Date().getTime() + '';
const shortenVersionNumber = versionNumber.slice(versionNumber.length - 6, versionNumber.length);
const version = `v${shortenVersionNumber}`;

i18n
    .use(LanguageDetector)
    .use(ChainedBackend)
    .use(initReactI18next)
    .init({
        react: {
            bindI18n: 'languageChanged',
            bindI18nStore: 'added',
            useSuspense: true,
        },
        // debug: true,
        preload: ['nl'],
        fallbackLng: 'nl',
        interpolation: {
            escapeValue: false,
        },
        ns: ['translation'],
        fallbackNS: 'translation',
        // partialBundledLanguages: true,
        backend: {
            backends: [
                LocalStorageBackend,
                HttpBackend,
            ],
            backendOptions: [
                {
                    expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
                    versions: {
                        nl: version,
                        fr: version,
                        de: version,
                        en: version,
                    },
                }, 
                {
                    loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
                    addPath:  `${process.env.PUBLIC_URL}/locales/add/{{lng}}/{{ns}}.json`,
                }
            ]
        },
        detection: {
            order: ['localStorage', 'cookie'],
            lookupLocalStorage: 'lng',
            lookupCookie: 'UserCulture', // Use same cookie as backend
            caches: ['localStorage', 'cookie'],
        },
        saveMissing: false,
        keySeparator: false,
        // initImmediate: false,
    });

export function t(key: string | string[], options?: TOptions | string) : string
{
    if (Array.isArray(key)){
        return i18n.t(key.map(k => k.toLowerCase()), options)
    }
    if (!key) return key;
    
    return i18n.t(key.toLocaleLowerCase(), options);
    // return i18n.t(key, options);
}

export function useTranslation(ns?: string[])
{
    return {t:t}
}

export default i18n;