import React, {useEffect, useState} from "react";
import {PageHeader} from "../../Header";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {ContentCardButtons, PlattixCard, PlattixCardContainer} from "../../components/ContentCard";
import {PlattixDataGrid} from "../../grid/PlattixDataGrid";
import {GridSelectionModel} from "@mui/x-data-grid-pro";
import {generatePath, Link, useHistory, useParams} from "react-router-dom";
import {PlattixForm} from "../../components/form/Form";
import {useForm} from "react-hook-form";
import {doGet, doPost, isHttpError, throwOnHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {PlattixInput, PlattixSubmitButton} from "../../components/form/Input";
import {fieldset} from "PlattixUI/core/components/form/Fieldset";
import {Actions, ActionsLeft, ActionsRight} from "../../components/ActionBar";
import {CancelButton, ConfirmButton} from "../../components/Buttons";
import {
    TranslationDescription,
    TranslationDescriptionContainer,
    TranslationDescriptionText,
    TranslationDescriptionTitle
} from "./TranslationsStyling";
import {SupportedLanguageCode, SupportedLanguages} from "PlattixUI/PlattixReactCore/types/Languages";
import {faArrowLeft, faLanguage} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {styled} from "goober";
import {BaseApiEndpoints} from "PlattixUI/PlattixReactCore/api/BaseApiEndpointMap";
import {toast} from "react-toastify";
import useDebounce from "PlattixUI/util/useDebounce";
import {useQuery} from '@tanstack/react-query';
import {PlattixSwal} from "PlattixUI/core/components/Swal";
import {Slugs} from "PlattixUI/configuration/Configuration";

export const TranslationsRouteMap = {
    Overview: `${Slugs.Developer}/Translations`,
    Edit: `${Slugs.Developer}/Translations/Edit/:MsgCode`,
    New: `${Slugs.Developer}/Translations/New`,
}

export interface TranslationsEditParams {
    MsgCode: string,
}

export type MessageTranslations = {
    [key in SupportedLanguageCode]?: string
};

export interface TranslationMessageModel {
    messageCode: string,
    originalCode?: string,
    description?: string,
    messageTranslations: MessageTranslations,
}

export function Translations() {
    const [gridSelection, setGridSelection] = useState<GridSelectionModel | undefined>(undefined);
    const history = useHistory();

    const handleNew = () => {
        history.push(TranslationsRouteMap.New);
    }

    return (
        <>
            <PageHeader title={t('Translations')}/>
            <Actions>
                <ActionsRight>
                    <ConfirmButton onClick={handleNew}>{t('Translations.New')}</ConfirmButton>
                </ActionsRight>
            </Actions>
            <PlattixCardContainer>
                <PlattixCard
                    header={t('Translations')}
                    width={'full'}
                    collapsible={true}
                >
                    <PlattixDataGrid
                        gridCode={'Messages'}
                        showCheckbox={false}
                        allowMultiSelect={true}
                        onSelectionChanged={setGridSelection}
                        selectionModel={gridSelection}

                        showDeleteButton
                        deleteUrlGenerator={row => `/Message/${row.id}`}

                        showEditButton
                        onEdit={(row) => generatePath(TranslationsRouteMap.Edit, {MsgCode: row.id})}
                    />
                </PlattixCard>
            </PlattixCardContainer>

        </>
    );
}


export function TranslationsEdit(props: { createNew?: boolean }) {
    const {MsgCode} = useParams<TranslationsEditParams>();

    return (
        <>
            <PageHeader title={MsgCode}/>
            <Actions>
                <ActionsLeft>
                    <Link to={TranslationsRouteMap.Overview}>
                        <CancelButton icon={faArrowLeft}>{t('GridButtonBackToList')}</CancelButton>
                    </Link>
                </ActionsLeft>
            </Actions>
            <PlattixCardContainer>
                <PlattixCard header={t('Translations.Edit')} width={'full'}>
                    <TranslationForm
                        msgCode={props.createNew ? null : MsgCode}
                        redirect={true}
                        getRequest={!props.createNew}
                    />
                </PlattixCard>
            </PlattixCardContainer>

        </>
    );
}

export interface TranslationFormProps {
    getRequest?: boolean,
    redirect?: boolean,
    msgCode?: string | null,
}

type IsMessageCodeUniqueResult = {
    unique: true
} |
    {
        unique: false,
        message: string
    }

export function TranslationForm(props: TranslationFormProps) {
    const [translation, setTranslation] = useState<TranslationMessageModel | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const form = useForm<TranslationMessageModel>();
    const {register, handleSubmit, reset, watch} = form;
    const history = useHistory();

    const postUrl = props.msgCode ? `/Message/${props.msgCode}` : '/Message';

    const debouncedMessageCode = useDebounce(watch('messageCode'), 250);
    const messageUniqueQuery = useQuery<IsMessageCodeUniqueResult>(
        ['MessageCodeUnique', debouncedMessageCode],
        () => {
            if (!debouncedMessageCode || debouncedMessageCode === translation?.originalCode)
                return new Promise<IsMessageCodeUniqueResult>((resolve => resolve({unique: true})));
            return throwOnHttpError(doGet<IsMessageCodeUniqueResult>(`/Message/Unique`, 
                {messageCode: debouncedMessageCode, originalCode: translation?.originalCode ?? ''}))
        }
        )

    useEffect(() => {
        if (props.getRequest) {
            doGet<TranslationMessageModel>(`/Message/${props.msgCode}`).then((result) => {
                if (!isHttpError(result)) {
                    setTranslation(result);
                } else {
                    PlattixSwal({
                        icon: "error",
                        text: result.detail,
                    });
                }
            });
        }
    }, [props.msgCode])

    useEffect(() => {
        reset(translation);
    }, [reset, translation]);
    
    const handleSubmission = async (data: TranslationMessageModel) => {
        if (messageUniqueQuery.data && !messageUniqueQuery.data.unique) return;
        
        setLoading(true)
        const response = await doPost(postUrl, data);
        setLoading(false)

        if (isHttpError(response)) {
            await PlattixSwal({
                icon: "error",
                text: response.detail,
            });
        } else {
            toast.success(t("Translation.Saved"))

            if (props.redirect) history.push(TranslationsRouteMap.Overview);
        }

    }

    function translateFrom(sourceLang: SupportedLanguageCode) {
        const text = form.getValues(`messageTranslations.${sourceLang}`)
        SupportedLanguages
            .filter(lang => lang.code !== sourceLang)
            .forEach(lang => {
                doGet<{ text: string }>(BaseApiEndpoints.Translate(sourceLang, lang.code), {text: text})
                    .then(trans => {
                        if (isHttpError(trans)) {
                            toast.error(`Vertaling ophalen voor ${lang.name} mislukt`)
                        } else {
                            form.setValue(`messageTranslations.${lang.code}`, trans.text)
                        }
                    })
            })
    }

    return (<>
        <PlattixForm onSubmit={handleSubmit(handleSubmission)}>
            <fieldset className={fieldset}>
                <legend>{t('Translations.General')}</legend>

                <PlattixInput register={register("messageCode")} label={t("MessageCode")} error={messageUniqueQuery.data?.unique ? null : messageUniqueQuery.data?.message}/>
                <PlattixInput register={register("description")} label={t("Description")} type={'textareaAutoSize'}/>
            </fieldset>

            <fieldset className={fieldset}>
                <legend>{t('Translations.Languages')}</legend>

                <TranslationDescriptionContainer>
                    <TranslationDescription>
                        <TranslationDescriptionTitle>
                            String interpolatie
                        </TranslationDescriptionTitle>
                        <TranslationDescriptionText>
                            String replace parameters doorgeven aan vertaling: <a
                            href={"https://www.i18next.com/translation-function/interpolation"} target={'_blank'}
                            rel="noreferrer">Zie documentatie</a>
                        </TranslationDescriptionText>
                    </TranslationDescription>
                </TranslationDescriptionContainer>

                {
                    SupportedLanguages.map(l => <div style={{display: "flex"}} key={l.code}>
                            <PlattixInput
                                style={{flexGrow: 1, alignItems: 'stretch'}}
                                register={register(`messageTranslations.${l.code}`)}
                                label={t(l.translationCode)}
                                type={'textareaAutoSize'}
                                lang={l.locale}
                            />
                            <TranslateButton onClick={() => translateFrom(l.code)}><FontAwesomeIcon
                                icon={faLanguage}/></TranslateButton>
                        </div>
                    )
                }
            </fieldset>
            
            <ContentCardButtons padding={['none']}>
                <PlattixSubmitButton loading={loading} disabled={messageUniqueQuery.isLoading} name={t("Save")}/>
            </ContentCardButtons>

        </PlattixForm>
    </>);
}

export const TranslateButton = styled('div')`
    margin-top: 22px;
    margin-bottom: 15px;
    height: auto;
    padding: 5px;
    border-radius: 5px;
    
    background-color: var(--buttonColorBlue);
    cursor: pointer;
    
    color: white;
    display: flex;
    align-items: center;
`