import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {PlattixSubmitButton, PlattixValidatedInput} from "../components/form/Input";
import {PlattixFormSelect, SelectOption} from "../components/form/Select";
import {PlattixAutoForm, PlattixForm, PlattixSubmitField} from "../components/form/Form";
import {EditButton} from "../components/Buttons";
import {InfoModal} from "../components/Modal";
import {useScreenshot} from "../components/ScreenshotHook";
import {HttpError} from "../../PlattixReactCore/CoreTypes";
import {doPostFormData, isHttpError} from "../../PlattixReactCore/api/Api";
import {Required} from "../forms/FormValidators";
import {ContentCardButtons} from "../components/ContentCard";
import {BaseApiEndpoints} from "PlattixUI/PlattixReactCore/api/BaseApiEndpointMap";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {styled} from "goober";


interface FeedbackFormType {
    subject: string,
    message: string
    feedbackKind: string,
    allowScreenshot?: boolean,
    page: string,
    screenshot: any
}

export interface FeedbackComponentProps {
    standAlone?: boolean;
}

export function FeedbackComponent(props: FeedbackComponentProps) {
    const [screenshotAllowed, setScreenshotAllowed] = useState(false);
    const [image, takeScreenshot, clearScreenshot] = useScreenshot();
    const [showEnlarged, setShowEnlarged] = useState(false);

    const [loading, setLoading] = useState(false)
    const [screenshotLoading, setScreenshotLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState<HttpError | null>(null)

    const form = useForm<FeedbackFormType>()
    const {handleSubmit} = form;

    const feedbackKindOptions: SelectOption[] = [
        {
            value: '',
            label: t('Feedback.Form.TypeOfSubject.ChooseOption'),
            disabled: true
        },
        {
            value: "layout",
            label: t('Feedback.Form.TypeOfSubject.ChooseOption.Layout')
        },
        {
            value: "functional",
            label: t('Feedback.Form.TypeOfSubject.ChooseOption.Functional')
        }
    ]

    async function doTakeScreenshot() {
        await clearScreenshot()
        setScreenshotLoading(true)
        await takeScreenshot()
        setScreenshotLoading(false)
    }

    async function onSubmit(model: FeedbackFormType) {
        if (model.feedbackKind === '0') {
            form.setError('feedbackKind',{message: t('Validation.Error.Required'),type:'required'})
            return;
        }
        
        model.page = window.location.href;

        setLoading(true);

        const data = new FormData();
        Object.entries(model)
            .forEach(([key, value]) => {
                data.append(key, value);
            })


        if (screenshotAllowed && image) {
            const file = await urlToFile(image, 'screenshot.png', 'img/png');
            await data.append('Screenshots', file);
        }

        const response = await doPostFormData(BaseApiEndpoints.Feedback, data);
        if (isHttpError(response)) {
            setError(response);
        } else {
            setSuccess(true);
        }

        setLoading(false);

        clearScreenshot();
        setScreenshotAllowed(false);
        form.reset()
    }

    async function urlToFile(url, filename, mimeType) {
        return (fetch(url)
                .then(function (res) {
                    return res.arrayBuffer();
                })
                .then(function (buf) {
                    return new File([buf], filename, {type: mimeType});
                })
        );
    }
    
    const fields: PlattixSubmitField<FeedbackFormType>[] = [
        {
            name: 'subject',
            label: t('Feedback.Form.Onderwerp'),
            validation: Required(),
        },
        {
            name: 'message',
            label: t('Feedback.Form.Opmerking'),
            validation: Required(),
            type: 'textareaAutoSize',
        },
        {
            name: 'feedbackKind',
            label: t('Feedback.Form.TypeOfSubject'),
            validation: Required(),
            type: 'select',
            options: feedbackKindOptions,
        },
        {
            name: 'allowScreenshot',
            label: t('Feedback.Form.SendScreenshot'),
            type: 'checkbox',
            onClick: () => setScreenshotAllowed(!screenshotAllowed),
        },
        {
            name: 'page',
            type: 'hidden',
        },
    ];
    
    if (success) return (
        <>
            <h5>{t("Feedback.Form.Confirmation.Headline")}</h5>
            <div className="hr-container">
                <hr/>
            </div>
            <p>{t("Feedback.Form.Bodytext")}</p>
        </>
    );
    
    if (error) return (
        <>
            <h5>{t("Feedback.Form.Confirmation.Error.Headline")}</h5>
            <div className="hr-container">
                <hr/>
            </div>
            <p>{t("Feedback.Form.Error.Bodytext")}</p>
        </>
    );

    return (
        <>
            {!props.standAlone &&
                <>
                    <FeedbackTitle>{t('Feedback.Form.Headline')}</FeedbackTitle>
                    <hr/>
                </>
            }
            <PlattixAutoForm<FeedbackFormType>
                fields={fields}
                onSubmit={onSubmit}
                showSubmitButton={false}
                showCancelButton={false}
                formName={'FeedbackForm'}
                form={form}
            />
            {(screenshotAllowed && !!image) &&
                <img src={image ?? ''} alt={'screenshot'} style={{height: "10vh"}}
                     onClick={() => setShowEnlarged(true)}
                />
            }
            
            <ContentCardButtons padding={['left', 'right']}>
                {screenshotAllowed &&
                    <EditButton
                        disabled={loading}
                        loading={screenshotLoading}
                        onClick={doTakeScreenshot}
                    >
                        {t('Feedback.Form.MakeScreenshot')}
                    </EditButton>
                }
                <PlattixSubmitButton 
                    loading={loading} 
                    disabled={screenshotLoading}
                    name={t('Submit')}
                    form={'FeedbackForm'}
                />
            </ContentCardButtons>

            <InfoModal onClose={() => setShowEnlarged(false)} show={!!image && showEnlarged}>
                <img src={image ?? ''} alt={'screenshot'} style={{height: "80vh"}}
                     onClick={() => setShowEnlarged(false)}
                />
            </InfoModal>
        </>
    );
}

export const FeedbackTitle = styled('h5', React.forwardRef)(() => {
    return `
        width: 100%;
        text-align: center;
    `;
});
