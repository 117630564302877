import {Route, Switch} from "react-router-dom";
import {clientSlug}  from 'configuration/ApiEnpointMap';
import DocumentScan from "./DocumentScan";
import DocumentScanDetail from "./DocumentScanDetail";
import DocumentScanHistory from "./DocumentScanHistory";

export const DocumentScanUrl = clientSlug + '/DocScan';
export const DocumentScanDetailUrl = DocumentScanUrl + '/:documentId';
export const DocumentHistoryUrl = DocumentScanUrl + '/History';

export function DocumentScanRoutes() {    
    return <Switch>
        <Route path={DocumentHistoryUrl}  component={DocumentScanHistory}/>
        <Route path={DocumentScanDetailUrl}  component={DocumentScanDetail}/>
        <Route path={DocumentScanUrl}  component={DocumentScan}/>

    </Switch>
}