export const rootUrl = '/Tactix'
export const AdminSlug = rootUrl + '/Admin';
export const DeveloperSlug = rootUrl + '/Developer';
export const clientSlug = rootUrl + '/MU';

export type PermissionedType="Account" | "User";

export const Endpoints = {
    Account: {
        Login: "/Account/Login",
        ReLogin: "/Account/ReLogin",
        Logout: "/Account/Logout",
        Register: "/Account/Register",
        Forgot2FA: "/Account/Forgot2FA",
        ResetPassword: "/Account/ResetPassword",
        ResendVerification: "/Account/ResendEmailVerification",
        ForgotPassword: "/Account/ForgotPassword",
        UpdateLanguage: "/Account/UpdateLanguage",
        CreateAccountOwners: "/Accounts/:ownerAccountId/CreateAccountOwners"
    },
    Feedback: "/Feedback/SendFeedbackMail",
    
    OCR: {
        UpdateOCR :(id)=>`/DocumentScanning/Document/${id}/UpdateOCR`,
        GetDocumentInfo :(id)=>`/DocumentScanning/Document/${id}/Info`,
        DeleteDocument: (id) => `/DocumentScanning/Document/${id}`
    },    
    Job: {
        ScheduleDailyRossumExport: `/job/rossum/export/daily`
    },
    Admin: {
        TestOcr: "/DocumentScanning/TestOcr"
    }
}