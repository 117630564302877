import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import '../../PlattixUI/scss/Index.scss';
import {OcrTest} from "../admin/OcrTest";
import {DocumentScanRoutes, DocumentScanUrl} from "../DocScan/DocumentScanRoutes";
import { rootUrl,clientSlug,AdminSlug } from 'configuration/ApiEnpointMap';
import {HangfireConfig} from "../admin/HangfireConfig";
import { AdminRoute } from 'PlattixUI/core/components/RouteComponents';

export function RoutersComponent() {
    // Tactix/Tactix/MU/DocScan
    return (
        <>
            <section className="body">
                <Switch>
                    <Redirect exact strict from={rootUrl} to={DocumentScanUrl}/>
                    {/*<Redirect exact strict from={rootUrl} to={clientSlug}/>*/}

                    
                    <Route path={clientSlug}>
                        <Switch>
                            <Route path={DocumentScanUrl}  component={DocumentScanRoutes}/>
                        </Switch>
                    </Route>
                    {/*<Route path={rootUrl + DocumentHistoryUrl}>*/}
                    {/*    <DocumentScanRoutes />*/}
                    {/*</Route>*/}
                    
                    <AdminRoute path={AdminSlug} >
                        <Switch>
                            <AdminRoute path={AdminSlug + '/testOcr'}  component={OcrTest}/>
                            <AdminRoute path={AdminSlug + '/hangfireConfig'} component={HangfireConfig}/>
                        </Switch>
                    </AdminRoute>
                    <Redirect to={DocumentScanUrl}/>
                </Switch>
                
            </section>
        </>
    );
}
