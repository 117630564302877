import React, {useEffect, useMemo, useState} from "react";
import {Controller, FieldError, FieldPath, FieldValues, UseFormReturn} from "react-hook-form";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";
import Select, {
    ControlProps,
    CSSObjectWithLabel,
    GroupBase,
    MultiValue,
    OptionProps,
    SingleValue,
    StylesConfig
} from "react-select";
import {t, useTranslation} from "PlattixUI/PlattixReactCore/i18n";
import {doGet, throwOnHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {filterProps as filterPropsDefault} from "PlattixUI/util/ElementProperties";
import {getErrorMessage} from "./formUtil";
import {ErrorMessage} from "../ActionBar";
import {useQuery} from '@tanstack/react-query';
import {css} from "goober";
import {useCodesQuery} from "PlattixUI/util/CodesUtil";


export interface PlattixCodeSelectProps<TFieldValues extends FieldValues> extends PlattixSelectProps<TFieldValues> {
    namespace?: string,
    tableName: string,
}

export interface PlattixSelecStylingOptionType {
    label: string,
    value: number | string | boolean,
    disabled?: boolean,
    selected?: boolean
}

export type PlattixSelecStylingType = StylesConfig<PlattixSelecStylingOptionType, boolean, GroupBase<PlattixSelecStylingOptionType>>;

// export const MainMenuSelectStyling: StylesConfig<SelectOption, boolean, GroupBase<SelectOption>> = {
// export const MainMenuSelectStyling: PlattixSelecStylingType = {
export const MainMenuSelectStyling: StylesConfig<SelectOption, boolean, GroupBase<SelectOption>> = {
    option: (provided: CSSObjectWithLabel, state: OptionProps<SelectOption>): CSSObjectWithLabel => ({
        padding: '12px 15px',
        height: 'unset',
        background: state.isFocused ? '#eee' : state.isSelected ? 'var(--styleColor3)' : '',
        '&:hover, &:active': {
            background: '#eee',
        },
    }),
    menu: (provided: CSSObjectWithLabel, state) => ({
        margin: '2px 0 0 0',
        background: '#ffffff',
        borderRadius: 10,
        width: '100%',
        position: 'absolute',
        zIndex: 10,
        boxShadow: 'var(--shadow2)',
        border: '3px solid rgba(0, 0, 0, 0.1)',
    }),
    menuList: (provided: CSSObjectWithLabel, state) => ({
        margin: 0,
        padding: '10px 0',
        background: '#ffffff',
        borderRadius: 10,
        cursor: 'pointer',
        height: 'fit-content',
        maxHeight: '70vh',
        overflowY: 'auto',
    }),
    valueContainer: (provided: CSSObjectWithLabel, state) => ({
        padding: '5px 35px 5px 15px',
        borderRadius: 50,
        border: 'none',
        fontSize: '13px',
        height: 25,
        background: '#ffffff',
        outline: '2px solid #CCC',
    }),
    input: (provided: CSSObjectWithLabel, state) => ({
        borderRadius: 50,
        border: 'none',
        fontSize: '13px',
        height: 25,
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
    }),
    indicatorsContainer: (provided: CSSObjectWithLabel, state) => ({
        width: '100%',
        height: 25,
        padding: 0,
        position: 'absolute',
        top: 0,
        left: 0,
    }),
    dropdownIndicator: (provided: CSSObjectWithLabel, state) => ({
        padding: '0 7px 0 0',
        height: 25,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    }),
    control: (provided: CSSObjectWithLabel, state: ControlProps<SelectOption>): CSSObjectWithLabel => ({
        height: 25,
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {...provided, opacity, transition};
    }
};

export const selectStyling: StylesConfig<SelectOption, boolean, GroupBase<SelectOption>> = {

    menu: (provided: CSSObjectWithLabel, state) => ({
        margin: '6px 0 0 0',
        background: '#ffffff',
        borderRadius: 10,
        border: '3px solid rgba(0, 0, 0, 0.1)',
        width: '100%',
        position: 'absolute',
        zIndex: 10,
        boxShadow: 'var(--shadow2)',
        left: 0,
    }),
    menuList: (provided: CSSObjectWithLabel, state) => ({
        margin: 0,
        padding: '10px 0',
        background: '#ffffff',
        borderRadius: 10,
        cursor: 'pointer',
        maxHeight: '500px',
        overflowY: 'auto',
    }),
    option: (provided: CSSObjectWithLabel, state: OptionProps<SelectOption>): CSSObjectWithLabel => ({
        padding: '5px 15px',
        height: 'unset',
        background: state.isFocused ? '#eee' : state.isSelected ? 'var(--styleColor3)' : '',
        '&:hover, &:active': {
            background: '#eee',
        },
    }),
    valueContainer: (provided: CSSObjectWithLabel, state) => ({
        padding: '0 20px 0 0',
        borderRadius: 50,
        border: 'none',
        fontSize: '13px',
    }),
    input: (provided: CSSObjectWithLabel, state) => ({
        borderRadius: 50,
        border: 'none',
        fontSize: '13px',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        'input': {
            height: 25,
            borderRadius: 50,
        }
    }),
    indicatorsContainer: (provided: CSSObjectWithLabel, state) => ({
        width: '100%',
        height: 25,
        padding: 0,
        position: 'absolute',
        top: 0,
        left: 0,
    }),
    dropdownIndicator: (provided: CSSObjectWithLabel, state) => ({
        padding: '0 7px 0 0',
        height: 25,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    }),
    control: (provided: CSSObjectWithLabel, state: ControlProps<SelectOption>): CSSObjectWithLabel => ({
        // height: 25,
        // borderRadius: 10,
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        const margin = 0;

        return {...provided, opacity, transition, margin};
    },
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
        padding: 0,
        margin: 0,
    }),
}

export const selectMultiStyling: StylesConfig<SelectOption, boolean, GroupBase<SelectOption>> = {

    menu: (provided: CSSObjectWithLabel, state) => ({
        margin: '6px 0 0 0',
        background: '#ffffff',
        borderRadius: 10,
        border: '3px solid rgba(0, 0, 0, 0.1)',
        width: '100%',
        position: 'absolute',
        zIndex: 10,
        boxShadow: 'var(--shadow2)',
        left: 0,
    }),

    control: (provided: CSSObjectWithLabel, state) => ({
        border: 'none',
        background: 'none',
        height: 'fit-content',
        display: 'flex',
        flexFlow: 'row nowrap',
        gap: '10px',
    }),

    valueContainer: (provided: CSSObjectWithLabel, state) => ({
        border: 'none',
        background: 'none',
        height: 'fit-content',
        display: 'flex',
        flexFlow: 'row wrap',
        gap: '10px',
        padding: 0,
        width: '100%',
    }),

    indicatorsContainer: (provided: CSSObjectWithLabel, state) => ({
        border: 'none',
        background: 'none',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        gap: '5px',
        padding: 0,
        div: {
            padding: 0,
        }
    }),

    multiValue: (provided: CSSObjectWithLabel, state) => ({
        border: 'none',
        background: 'var(--styleColor1)',
        height: 'fit-content',
        display: 'flex',
        flexFlow: 'row nowrap',
        gap: '5px',
        padding: '3px 10px',
        borderRadius: '10px',
        color: 'white',
    }),

    multiValueLabel: (provided: CSSObjectWithLabel, state) => ({
        padding: 0,
        color: 'white',
    }),

    multiValueRemove: (provided: CSSObjectWithLabel, state) => ({
        padding: 0,
        borderRadius: '10px',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    }),

    input: (provided: CSSObjectWithLabel, state) => ({
        padding: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        'input:focus-visible': {
            boxShadow: 'none !important',
        },
        input: {
            height: '100%',
        },
        borderRadius: 0,
        flex: '1 1 auto'
    }),

    placeholder: (provided: CSSObjectWithLabel, state) => ({
        margin: 0,
        lineHeight: 'normal',
    }),
}


export interface SelectOption {
    label: string,
    value: number | string | boolean,
    disabled?: boolean,
    selected?: boolean
}

export interface NumberSelectOption extends SelectOption {
    value: number
}

export interface StringSelectOption extends SelectOption {
    value: string
}

export interface BooleanSelectOption extends SelectOption {
    value: boolean
}

export function PlattixCodeSelect<TFieldValues extends FieldValues>(props: Omit<PlattixCodeSelectProps<TFieldValues>, 'options'>) {

    const namespace = props.namespace ?? "DataAccessLayer.Data"

    const [initialized, setInitialized] = useState(false);
    const codes = useQuery<SelectOption[]>(['codeSelect', namespace, props.tableName],
        () => throwOnHttpError(doGet<SelectOption[]>(
            '/Code/PlattixSelectCodeList',
            {
                Namespace: props.namespace,
                TableName: props.tableName
            }
        )), {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            // refetchOnReconnect: false,
            cacheTime: 60 * 60 * 1000, // 1 hour
            staleTime: 60_000 // 1 minute
        }
    )

    useEffect(() => {
        if (codes.isError) {
            props.form.setError(props.name, {
                type: t('CodeSelect.FailedToLoadCodes'),
                message: t('CodeSelect.FailedToLoadCodes')
            })
        } else (
            props.form.clearErrors(props.name)
        )
    }, [codes.isError, props.form, props.name])

    return (
        <PlattixSelect {...props} options={codes.data ?? []} isLoading={codes.isLoading}/>
    )
}

export interface PlattixFormSelectProps<TFieldValues extends FieldValues> extends PlattixSelectProps<TFieldValues> {
    label: string,
    error?: FieldError | string | string[] | undefined | HttpError | null,
}


/**
 * Basic Plattix Select component with label end error
 *
 * If you dont need the label, use {@link PlattixSelect}
 * @param props
 */
export function PlattixFormSelect<T extends FieldValues>(props: PlattixFormSelectProps<T>) {
    const {t} = useTranslation()

    const id = props.id ?? props.name;

    return (
        <div className="form-group row">
            <div className="module-tab-content-body-group">
                <label className="module-tab-content-title" htmlFor={id}>
                    {t(props.label)}
                </label>

                <PlattixSelect {...props} />

                {
                    (getErrorMessage(props.name, props.error) || props.form.getFieldState(props.name).error)
                    && <span
                        className="text-danger">{getErrorMessage(props.name, props.error) ?? getErrorMessage(props.form.register.name, props.form.getFieldState(props.name).error)}</span>
                }
            </div>
        </div>
    );
}

export interface PlattixSelectProps<TFieldValues extends FieldValues> {
    name: FieldPath<TFieldValues>;
    options: SelectOption[] | undefined;
    form: UseFormReturn<TFieldValues, object>;
    placeholder?: string;

    /**
     * Add an unselectable option that forces the user to pick an option
     */
    chooseOption?: boolean;
    /**
     * Should the 'choose Option' option be unselectable
     */
    chooseOptionDisabled?: boolean;

    id?: string;
    required?: boolean;
    allowZero?: boolean;
    onChange?: (e: any) => void;
    onBlur?: (e: any) => void;
    isLoading?: boolean;
    isDisabled?: boolean;
    isMulti?: boolean;
    // styling?: PlattixSelecStylingType;
    isMenu?: boolean;
}

/**
 * Basic Plattix Select component without label end error.
 *
 * If you need the label and error use {@link PlattixFormSelect}
 * @param props
 */
export function PlattixSelect<T extends FieldValues>(props: PlattixSelectProps<T>) {
    const {t} = useTranslation()
    const [initialized, setInitialized] = useState(false)

    const id = props.id ?? props.name;
    const selectedValue = props.form.watch(props.name)

    let options = useMemo(() => {
        const options = props.options ?? [];
        if (props.isMulti) return options;

        if (props.chooseOption && options.length > 0) {
            const chooseOption: SelectOption = {
                label: t('ChooseOption'),
                value: '',
                disabled: props.chooseOptionDisabled,
            }
            return [chooseOption, ...options]
        }

        return options;
    }, [props.chooseOption, props.chooseOptionDisabled, props.isMulti, props.options, t]);

    const initialValue = useMemo(() => {
        if (selectedValue === null || selectedValue === undefined)
            return options.find((option) => option.selected) ?? options[0]

        // eslint-disable-next-line eqeqeq
        const val = options.find((option) => selectedValue == option.value)
        // if (!val) console.error(`optie met id ${value} niet gevonden`)
        return val ?? options[0];

    }, [options, selectedValue]);

    const selectedOption = useMemo(() => {
        if (props.isMulti) {
            if (!options || !selectedValue) return []
            return options.filter((option) => (selectedValue as string[]).includes(option.value as string))
        }

        return options.find((option) => selectedValue == option.value) ?? initialValue

    }, [initialValue, options, props.isMulti, selectedValue])


    function getValue(option: MultiValue<SelectOption> | SingleValue<SelectOption>) {
        if (isMultiValue(option)) {
            return option?.map(o => o.value) ?? []
        } else {
            return option?.value
        }
    }

    useEffect(() => {
        if (!initialized) {
            if (options.length > 0) {
                props.form.setValue(props.name, initialValue.value as any, {shouldValidate: true})
                setInitialized(true)
            }
        }
    }, [initialValue, initialized, options.length, props.form, props.name]);
    
    useEffect(() => {
        let required

        if (isMultiValue(selectedOption)) {
            required = !!selectedOption.length
        } else {
            required = !selectedOption || selectedOption.value === 0 || selectedOption.value === '0'
        }

        if (props.required) {

            if (required) {
                props.form.setError(props.name, {message: t('Validation.Error.Required'), type: 'required'})
            } else {
                props.form.clearErrors(props.name)
            }
        }
    }, [props.form, props.name, props.required, selectedOption, t])

    props.form.register(props.name, {required: props.required});
    
    const styling = () => {
        if (!!props.isMenu) return MainMenuSelectStyling;
        if (!props.isMulti) return selectStyling;
        return selectMultiStyling;
    }
    
    return (
        <Controller
            name={props.name}
            control={props.form.control}
            render={({field: {value, onChange, onBlur}}) => {
                return (
                    <Select
                        id={id}
                        options={options}
                        placeholder={props.placeholder}
                        isMulti={props.isMulti}
                        onChange={(option) => {
                            onChange(getValue(option));
                            props.onChange?.(option);
                        }}
                        onBlur={props.onBlur}
                        value={selectedOption}
                        defaultValue={initialValue}
                        // styles={!props.isMulti ? selectStyling : selectMultiStyling}
                        styles={styling()}
                        isOptionDisabled={(option) => option.disabled ?? false}
                        isOptionSelected={(option) => option.selected ?? false}
                        className={`form-control ${SelectListStyling}`}
                        isLoading={props.isLoading}
                        isDisabled={props.isDisabled}
                    />
                );
            }}
        />
    )
}

interface PlattixCodeFormSelectProps<TFieldValues extends FieldValues> extends Omit<PlattixFormSelectProps<TFieldValues>, "options" | "onBlur" | "allowZero"> {
    namespace?: string,
    tableName: string,
}

export function PlattixCodeFormSelect<TFieldValues extends FieldValues>(props: PlattixCodeFormSelectProps<TFieldValues>) {
    const namespace = props.namespace ?? "DataAccessLayer.Data"

    const [initialized, setInitialized] = useState(false);
    const codes = useCodesQuery(props.tableName, namespace)

    useEffect(() => {
        if (!initialized && codes.data?.length) {
            const getInitialValue = (value) => {
                const options = codes.data ?? [];
                if (value === null || value === undefined)
                    return options.find((option) => option.selected) ?? options[0];

                // eslint-disable-next-line eqeqeq
                const val = options.find((option) => value == option.value)
                // if (!val) console.error(`optie met id ${value} niet gevonden`)
                return val ?? options[0];
            }
            props.form.setValue(props.name, getInitialValue(props.form.getValues(props.name)).value as any)
            setInitialized(true)
        }
    }, [codes, initialized, props.form, props.name])

    if (codes.isError) return <ErrorMessage>Kon codes niet ophalen</ErrorMessage>

    return <PlattixFormSelect
        {...filterPropsDefault(props, ['namespace', 'tablename'])}
        chooseOption={props.chooseOption}
        form={props.form}
        name={props.name}
        label={props.label}
        options={codes.data}/>
}


// const formatOptionLabel = ({ label, value }) => {
//     console.log(label)
//     return (
//     <div style={{ display: "flex" }}>
//         <div>dslkfj</div>
//         <div style={{ marginLeft: "10px", color: "#ccc" }}>
//             {label}
//         </div>
//     </div>
// )};

export const SelectListStyling = css`
    #react-select-7-listbox {
    }
    
    .css-17nov2c-MenuList {
        max-height: 250px;
    }
`;

function isMultiValue(option: MultiValue<SelectOption> | SingleValue<SelectOption>): option is MultiValue<SelectOption> {
    return Array.isArray(option);
}