import React from "react";
import {toast, ToastContainer} from "react-toastify";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {css} from "goober";
// import {ToastContent, ToastOptions} from "react-toastify/dist/types";

interface ToastContentProps {
    title: string,
    body?: string
}
export function ToastContent(props: ToastContentProps) {
    
    return <div>
        <h4>{props.title}</h4>
        <p>{props.body}</p>
    </div>
}

export function PlattixToastContainer() {
    return (
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className={ToastContainerStyling}
        />
    )
}

export const ToastContainerStyling = css`
    --toastify-color-light: #fff;
    --toastify-color-dark: #333;
    --toastify-color-info: var(--buttonColorBlue);
    --toastify-color-success: var(--buttonColorGreen);
    --toastify-color-warning: var(--styleColor3);
    --toastify-color-error: var(--buttonColorRed);
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);
    
    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);
    
    --toastify-toast-width: 320px;
    --toastify-toast-background: var(--backgroundColor1);
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;
    
    --toastify-text-color-light: #333;
    --toastify-text-color-dark: #fff;
    
    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;
    
    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;
    --toastify-color-progress-light: linear-gradient(
        to right,
        #4cd964,
        #5ac8fa,
        #007aff,
        #34aadc,
        #5856d6,
        #ff2d55
    );
    
    --toastify-color-progress-dark: #333;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
    
    .Toastify__toast {
        border-radius: 10px;
        box-shadow: var(--shadow2);
    }
    
    .Toastify__close-button {
        margin-inline-start: 10px;
    }
`;