import {getActiveLanguage, SupportedLanguageCode, SupportedLanguages} from "PlattixUI/PlattixReactCore/types/Languages";
import {PlattixValidatedInput, TranslationInputProps} from "PlattixUI/core/components/form/Input";
import i18n, {t} from "PlattixUI/PlattixReactCore/i18n";
import {TranslateButton} from "PlattixUI/core/pages/Translations/Translations";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLanguage} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {doGet, isHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {BaseApiEndpoints} from "PlattixUI/PlattixReactCore/api/BaseApiEndpointMap";
import {toast} from "react-toastify";
import {Required} from "PlattixUI/core/forms/FormValidators";
import {css} from "goober";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons/faAngleDown";

export type TranslationModel = {
    translationUsageId: number,
    translations: {[key in SupportedLanguageCode]: string}
}

export function TranslationPlattixInput(props: TranslationInputProps){

    const description = props.formHook.watch(`${props.name}.translations.${i18n.language}`)
    
    if (props.collapsable){
        return <Accordion>
            <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <p>{t(getActiveLanguage()?.translationCode ?? '')}: {description}</p>
            </AccordionSummary>
            <AccordionDetails>
                <TranslationPlattixInputFields {...props}/>
            </AccordionDetails>
        </Accordion>
    }
    
    return <>
        <PlattixValidatedInput 
            type={"hidden"} 
            formHook={props.formHook} 
            label={""} 
            name={`${props.name}.translationUsageId`}
        />
        <TranslationPlattixInputFields {...props}/>
    </>
}

function TranslationPlattixInputFields(props: TranslationInputProps){
    function translateFrom(sourceLang: SupportedLanguageCode) {
        const input = props.formHook.getValues(`${props.name}.translations.${sourceLang}`)
        SupportedLanguages
            .filter(lang => lang.code !== sourceLang)
            .forEach(lang => {
                doGet<{ text: string }>(BaseApiEndpoints.Translate(sourceLang, lang.code), {text: input})
                    .then(trans => {
                        if (isHttpError(trans)) {
                            toast.error(`Vertaling ophalen voor ${lang.name} mislukt`)
                        } else {
                            props.formHook.setValue(`${props.name}.translations.${lang.code}`, trans.text)
                        }
                    })
            })
    }
    
    return <>{
        SupportedLanguages.map(l => (
            <div
                style={{display: "flex"}}
                key={`${props.name}.translations.${l.code}`}
                className={PlattixTranslationContainer}
            >
                <PlattixValidatedInput<any>
                    style={{flexGrow: 1, alignItems: 'stretch'}}
                    name={`${props.name}.translations.${l.code}`}
                    formHook={props.formHook}
                    label={l.translationCode}
                    type={'textareaAutoSize'}
                    lang={l.locale}
                    validation={props.required ? [Required()] : undefined}
                />
                <TranslateButton onClick={() => translateFrom(l.code)}>
                    <FontAwesomeIcon icon={faLanguage}/>
                </TranslateButton>
            </div>
        ))
    }</>
}

export const PlattixTranslationContainer = css`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    
    .MuiCollapse-root {
        width: 100%;
    }
`;