function navigationCollapse() {
    const menus = document.querySelectorAll('.menu');

    menus.forEach(menu => {
        const items = menu.querySelectorAll('.item');

        items.forEach(item => {
            if (item.querySelector('.item')) {
                const itemInItem = item.querySelectorAll('.item');
                const textInItem = item.querySelector('p');

                if (/*!item.parentNode.classList.contains('group') && */!item.classList.contains('group')) item.classList.add('has-children');

                textInItem?.addEventListener('click', (e: Event) => {
                    itemInItem.forEach(itm => itm.classList.toggle('hide'));
                    let childrenAreHidden = item.querySelector('.item')?.classList.contains('hide');

                    if (item.classList.contains('has-children')) {
                        childrenAreHidden ? item.classList.add('closed') : item.classList.remove('closed');
                    }

                    if (item.classList.contains('group')) {
                        let icon = textInItem.querySelector('i');

                        if (icon?.classList.contains('fa-angle-left')) {
                            icon?.classList.remove('fa-angle-left');
                            icon?.classList.add('fa-angle-down');
                        } else {
                            icon?.classList.remove('fa-angle-down');
                            icon?.classList.add('fa-angle-left');
                        }
                    }
                });
            }
        });
    });
}

//navigationCollapse();

export function optionsColapse() {
    const optionsMenu = document.querySelectorAll('.options');

    optionsMenu.forEach(options => {
        const link = options.parentNode?.querySelector('.link');
        const autoClose = !options.hasAttribute('auto-close');

        window.addEventListener('click', (e: Event) => {
            const opened = !options.classList.contains('hide');
            const open = () => {
                //[...optionsMenu].filter(om => om !== options).forEach(om => om.classList.add('hide'));
                options.classList.remove('hide');
            }
            //const clickedCloseBtn = Array.from(options.querySelectorAll('[close-button]'))?.filter(cb => cb.contains(e.target as HTMLElement)).length !== 0;

            if (autoClose) options.classList.add('hide');
            //if (options.getAttribute('auto-close') === 'true' && clickedCloseBtn) options.classList.add('hide');
            if (autoClose && options.contains(e.target as HTMLElement)) open();
            if (link?.contains(e.target as HTMLElement) && !opened) open();
        });
    });
}

//optionsColapse();

export function applicationSelector() {
    const applicationSelection = document.querySelector('.application');
    const applicationCurrent = applicationSelection?.querySelector('.application-current');
    const applicationList = applicationSelection?.querySelector('.application-list');
    const applicationListOptions = applicationList?.querySelectorAll('.application-option');

    if (!applicationSelection) return;

    window.addEventListener('click', (e: Event) => {
        if (!applicationSelection.contains(e.target as HTMLElement)) applicationList?.classList.add('hide');
        let openApplicationList = () => applicationList?.classList.toggle('hide');
        let chooseApplicationListOption = (alo: any) => {
            const attributes = [...alo.querySelector('img').attributes];

            attributes.forEach(attr => applicationCurrent?.querySelector('img')?.setAttribute(attr.nodeName, attr.nodeValue));

            openApplicationList();
        };

        if (applicationCurrent?.contains(e.target as HTMLElement)) openApplicationList();

        applicationListOptions?.forEach(alo => {
            if (alo.contains(e.target as HTMLElement)) chooseApplicationListOption(alo);
        });
    });
}

export function applicationSelectorClick(target: EventTarget & HTMLDivElement) {
    const applicationSelection = document.querySelector('.application');
    const applicationCurrent = applicationSelection?.querySelector('.application-current');
    const applicationList = applicationSelection?.querySelector('.application-list');
    const applicationListOptions = applicationList?.querySelectorAll('.application-option');


    if (!applicationSelection) return;
    
    if (!applicationSelection.contains(target as HTMLElement)) applicationList?.classList.add('hide');
    let openApplicationList = () => applicationList?.classList.toggle('hide');

    let chooseApplicationListOption = (alo: any) => {
        const attributes = [...alo.querySelector('img').attributes];

        attributes.forEach(attr => applicationCurrent?.querySelector('img')?.setAttribute(attr.nodeName, attr.nodeValue));

        openApplicationList();
    };

    if (applicationCurrent?.contains(target as HTMLElement)) openApplicationList();

    applicationListOptions?.forEach(alo => {
        if (alo.contains(target as HTMLElement)) chooseApplicationListOption(alo);
    });
    

    
    

    // window.addEventListener('click', (e: Event) => {
    //     if (!applicationSelection.contains(e.target as HTMLElement)) applicationList?.classList.add('hide');
    //     let openApplicationList = () => applicationList?.classList.toggle('hide');
    //     let chooseApplicationListOption = (alo: any) => {
    //         const attributes = [...alo.querySelector('img').attributes];
    //
    //         attributes.forEach(attr => applicationCurrent?.querySelector('img')?.setAttribute(attr.nodeName, attr.nodeValue));
    //
    //         openApplicationList();
    //     };
    //
    //     if (applicationCurrent?.contains(e.target as HTMLElement)) openApplicationList();
    //
    //     applicationListOptions?.forEach(alo => {
    //         if (alo.contains(e.target as HTMLElement)) chooseApplicationListOption(alo);
    //     });
    // });
}


//applicationSelector();