/**
 * Regex to validate email addresses
 * 
 * The regex is taken form {@link https://emailregex.com/}
 */
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/**
 * RegExp to validate email addresses
 * The regex is taken form {@link https://emailregex.com/}
 */
const emailRegExp = new RegExp(emailRegex)

/**
 * Test if a string is a valid regex.
 *
 * The regex is taken form {@link https://emailregex.com/}
 * @param email: email to test
 */
export function isValidEmail(email: string){
    return emailRegExp.test(email)
}