import {useState} from 'react'
import html2canvas from 'html2canvas'

/**
 * @module Main_Hook
 * Hook return
 * @typedef {Array} HookReturn
 * @property {string} HookReturn[0] - image string
 * @property {string} HookReturn[1] - take screen shot string
 * @property {string} HookReturn[2] - clear the screenshot
 * @property {object} HookReturn[3] - errors
 */


interface ScreenshotHookParams {type?: string, quality?: string}
/**
 * hook for creating screenshot from html node
 * @returns {HookReturn}
 */
const useScreenshot = (props?: ScreenshotHookParams) : [string | null, (node?: HTMLElement | undefined) => Promise<string | void>, () => void, { error: null; }] => {
    const [image, setImage] = useState<string|null>(null)
    const [error, setError] = useState(null)
    
    const clearScreenshot = () => {
        setImage(null)
    }
    
    /**
     * convert html node to image
     * @param {HTMLElement} node
     */
    const takeScreenShot = (node?:HTMLElement) => {
        if (!node) {
            node = document.body
        }
        return html2canvas(node)
            .then((canvas) => {
                const croppedCanvas = document.createElement('canvas')
                const croppedCanvasContext = croppedCanvas.getContext('2d')
                // init data
                const cropPositionTop = 0
                const cropPositionLeft = 0
                const cropWidth = canvas.width
                const cropHeight = canvas.height

                croppedCanvas.width = cropWidth
                croppedCanvas.height = cropHeight

                croppedCanvasContext?.drawImage(
                    canvas,
                    cropPositionLeft,
                    cropPositionTop,
                )

                const base64Image = croppedCanvas.toDataURL(props?.type, props?.quality)

                setImage(base64Image)
                return base64Image
            })
            .catch(setError)
    }
    
    return [
        image,
        takeScreenShot,
        clearScreenshot,
        {
            error,
        }
    ]
}


export { useScreenshot }