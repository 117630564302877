import {GridRenderCellParams} from "@mui/x-data-grid";
import React from "react";
import {styled} from "goober";
import {statusDictionary} from "./types/Document";

export const dataGridStyling = {
    border: 'none',
    '.MuiDataGrid-columnHeaders': {
        borderBottom: 'none',
    },
    '.dataGrid-header': {
        background: '#f5f5f5',
        height: '40px',

        '&:first-of-type': {
            borderRadius: '10px 0 0 10px',
        },

        '&:last-child': {
            borderRadius: '0 10px 10px 0',

            '.MuiDataGrid-columnSeparator': {
                display: 'none',
            }
        },
    },
    '.dataGrid-cell': {
        padding: '0 16px',
        height: '40px',

        '&.dataGrid-editableCell': {
            cursor: 'pointer',
        },

        '&[data-field="options"]': {
            padding: 0,
        },
    },
    '.MuiDataGrid-row.Mui-selected': {
        background: '#f5f5f5',

        '&:hover': {
            background: '#cccccc',
        },
    },
    '.MuiToolbar-gutters': {
        '& > *': {
            marginBottom: 0,
        }
    },
    '.MuiDataGrid-editInputCell': {
        height: '100%',
    },
};

export const editColumnConfig = {
    editable: true,
    cellClassName: 'dataGrid-editableCell',
};

export const columnConfig = {
    minWidth: 150,
    headerClassName: 'dataGrid-header',
    cellClassName: 'dataGrid-cell',
};

export const columnEqualWidth = {
    flex: 1,
};

interface StatusIconProps {
    status: string
}

const StatusIcon = styled<StatusIconProps>('span')(
    (props) => `
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
        align-items: center;
        
        & > div {
            width: 10px;
            height: 10px;
            borderRadius: 10px;
         background: ${statusDictionary[props.status]?.color};   
        }
    `);

export const statusCellDocumentState = {
    renderCell: (params: GridRenderCellParams) => {
        return (
            <StatusIcon status={params.value}>
                <div className={'icon'}/>
                {params.row['documentStateTranslation']}
            </StatusIcon>
        );
    },
};

export const statusCellOCRState = {
    renderCell: (params: GridRenderCellParams) => {
        return (
            <StatusIcon status={params.value}>
                <div className={'icon'}/>
                {params.row['ocrStateTranslation']}
            </StatusIcon>
        );
    },
};