import logo from '../images/branding/metalunion/logo/logo.png';
import loginBackground from '../images/branding/metalunion/background/login.png';
import currentAppIcon from '../PlattixUI/images/branding/tactix/icon/icon.svg';

import {IPlattixConfiguration} from "../PlattixUI/PlattixReactCore/types/PlattixConfiguration";
import {isAdminSelector} from "../PlattixUI/PlattixReactCore/UserSlice";

export const ApiRootUrl = (process.env.NODE_ENV === 'production' ? '' : 'http://localhost:51000') + '/tactix/api';

export const PlattixConfiguration: IPlattixConfiguration = {
    platformName: 'Tactix',
    clientName: 'Metalunion',
    hideInMenu: {
        // 'Tactix': (state) => state.selectedServicepoint.servicepoint !== null,
        
    },
    
    // applicationAccess: ['Tactix', 'Energytix', 'Plattix'],
    //applicationAccess: [],

    appIcon: '562adfdf1e15431b80b0d1bc03e6a26a/Tekengebied 10 kopie 6.png',
    mainLogo: 'c02693f448d34052afff9b9d7aa4705d/logo.png',
    
    loginScreen: {
        background: loginBackground
    }
}