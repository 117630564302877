import React from 'react';
import {useTranslation} from 'PlattixUI/PlattixReactCore/i18n';
import {PlattixNavigation, PlattixNavigationItem} from "./PlattixUI/core/components/MenuComponents";
import {DocumentHistoryUrl, DocumentScanUrl} from 'pages/DocScan/DocumentScanRoutes';

export function MainMenu() {
    const { t } = useTranslation(['translation']);

    return (
        <>
            <PlattixNavigation>

                <PlattixNavigationItem
                    Name={t('AIDocscan')}
                    Group={true}>
                    <PlattixNavigationItem
                        Name={t('ScanAndProcess')}
                        Link={DocumentScanUrl} />
                    <PlattixNavigationItem
                        Name={t('History')}
                        Link={DocumentHistoryUrl} />
                </PlattixNavigationItem>


            </PlattixNavigation>
        </>
    );

}