import {BodyComponent} from './Body';
import {MobileMenu} from "PlattixUI/core/Navigation";
import {useAppSelector} from "PlattixUI/PlattixReactCore/hooks";
import {userSelector} from "PlattixUI/PlattixReactCore/UserSlice";

export function ContentComponent() {
    const {user, userStatus} = useAppSelector(userSelector)
    
    return (
        <>
            <MobileMenu
                firstName={user?.firstName}
                lastName={user?.lastName}
                email={user?.email}
            >
                <section className="content">
                    <BodyComponent />
                </section>
            </MobileMenu>
            
        </>
	);
}