import React, {useState} from 'react';
import i18n from 'PlattixUI/PlattixReactCore/i18n';
import {useAppDispatch} from "PlattixUI/PlattixReactCore/hooks";
import {changeLanguage} from "PlattixUI/PlattixReactCore/UserSlice";
import {Language, SupportedLanguages} from "PlattixUI/PlattixReactCore/types/Languages";
import {Spinner} from "PlattixUI/core/components/Loader";


export function LanguageMenuComponent() {
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);

    const onLanguageClick = (language: Language) => {
        setLoading(true)
        i18n.changeLanguage(language.code).then((() => {
            dispatch(changeLanguage(language))
            setLoading(false)
        }))
    };

    return (
        <>
            {
                SupportedLanguages.map(lang => 
                    <p key={lang.code} 
                       className={`option ${i18n.language === lang.code ? 'active' : ''}`} 
                       onClick={() => onLanguageClick(lang)}>
                        {lang.name}
                        {
                            loading && <Spinner size={"small"}/>
                        }
                    </p>)
            }
        </>
    );
}
