import * as React from "react";
import {filterProps} from "PlattixUI/util/ElementProperties";
import {logoutUser, userSelector} from "PlattixUI/PlattixReactCore/UserSlice";
import {useAppDispatch, useAppSelector} from "PlattixUI/PlattixReactCore/hooks";
import {Spinner} from "./Loader";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {history} from "PlattixUI/PlattixReactCore/store"
import {styled} from "goober";
import {faPen} from "@fortawesome/free-solid-svg-icons/faPen";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {LocationDescriptor} from "history";
import {Link} from "react-router-dom";
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark";
import {DownloadFileProps, useDownloadFile} from "PlattixUI/core/hooks/useDownloadFile";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {
    faArrowPointer,
    faArrowUpRightFromSquare,
    faWindowRestore,
    faDownload,
    faLocationDot, faArrowsTurnToDots, faLink
} from "@fortawesome/free-solid-svg-icons";
import { PlattixTooltip } from "./ContentCard";

export type ButtonType = "button" | "submit" | "reset"

export enum ButtonColors {
    Confirm = 'content-btn-1',
    Edit = 'content-btn-4',
    Cancel = 'content-btn-5',
    Error = 'content-btn-2',
}

export type ButtonActionType = 'action' | 'modal' | 'link' | 'linkNewTab' | 'download';

export interface ButtonActionTypeValues {
    icon: IconDefinition;
    label: string;
}

export type ButtonActionMapType = Record<ButtonActionType, ButtonActionTypeValues>;

export const ButtonActionMap: ButtonActionMapType = {
    action: {
        icon: faArrowsTurnToDots,
        label: 'ButtonActionType.Action.Label',
    },
    modal: {
        icon: faWindowRestore,
        label: 'ButtonActionType.Modal.Label',
    },
    link: {
        icon: faLink,
        label: 'ButtonActionType.Link.Label',
    },
    linkNewTab: {
        icon: faArrowUpRightFromSquare,
        label: 'ButtonActionType.LinkNewTab.Label',
    },
    download: {
        icon: faDownload,
        label: 'ButtonActionType.Download.Label',
    },
};

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    buttonColor?: ButtonColors,
    loading?: boolean,
    disabled?: boolean,
    disableOnLoad?: boolean,
    type?: ButtonType,
    form?: string,
    /**
     * Add a font awesome icon to the button
     */
    icon?: IconProp,

    link?: LocationDescriptor<unknown>,
    notification?: string | number,
}

function filterButtonProps(props: any) {
    return filterProps(props, ['className', 'buttonColor', 'loading', 'disableOnLoad', 'icon']);
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (props: React.PropsWithChildren<ButtonProps>, ref) => {
        if (typeof props.link !== 'undefined')
            return <Link to={props.link}><Button ref={ref} {...props} link={undefined}/></Link>

        return (
            <button
                {...filterButtonProps(props)}
                type={props.type ?? "button"}
                disabled={props.disabled || (props.disableOnLoad && props.loading)}
                form={props.form}
                className={`content-btn ${props.buttonColor ?? ButtonColors.Edit} ${props.className ?? ''}`}
                ref={ref}
            >
                {props.loading && <Spinner size={"small"} colorMode={'light'} style={{marginRight: "0.5em"}}/>}
                {props.icon && <FontAwesomeIcon icon={props.icon}/>}
                {props.children}
                {!!props.notification && <span className="notification">{props.notification}</span>}
            </button>
        );
    }
);

Button.defaultProps = {
    disableOnLoad: true
}

/**
 * Button to render in MUI Data Grid
 * @param props
 * @constructor
 */
export function GridButton(props: ButtonProps) {
    props = Object.assign({
        buttonColor: ButtonColors.Confirm,
        className: 'btn-irc-grid'
    }, props)
    return <Button {...props}/>;
}

export const ConfirmButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (props: ButtonProps, ref) => {
        props = Object.assign({
            buttonColor: ButtonColors.Confirm,
            children: t('Confirm')
        }, props)
        return <Button ref={ref} {...props}/>;
    }
)

export const AddButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (props: ButtonProps, ref) => {
        props = Object.assign({
            buttonColor: ButtonColors.Confirm,
            children: t('Action.Create')
        }, props)
        return <Button ref={ref} {...props}/>;
    }
)

export const AddIconButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (props: ButtonProps, ref) => {
        props = Object.assign({
            buttonColor: ButtonColors.Confirm,
            icon: faPlus
        }, props)
        return <Button ref={ref} {...props}/>;
    }
);

export const CancelButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (props: ButtonProps, ref) => {
        props = Object.assign({
            buttonColor: ButtonColors.Cancel,
            children: t('Cancel')
        }, props)
        return <Button ref={ref} {...props}/>;
    })

export const EditButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (props: ButtonProps, ref) => {
        props = Object.assign({
            buttonColor: ButtonColors.Edit,
            children: t('Edit')
        }, props)
        return <Button ref={ref} {...props}/>;
    }
);

/**
 * Edit button with only an icon
 */
export const EditIconButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (props: ButtonProps, ref) => {
        props = Object.assign({
            buttonColor: ButtonColors.Edit,
            icon: faPen
        }, props)
        return <Button ref={ref} {...props}/>;
    }
);

/**
 * Cancel button with only an icon
 */
export const CancelIconButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (props: ButtonProps, ref) => {
        props = Object.assign({
            buttonColor: ButtonColors.Cancel,
            icon: faXmark
        }, props)
        return <Button ref={ref} {...props}/>;
    }
);

interface DownloadButtonProps extends Exclude<ButtonProps, "children"> {
    url: string,
    label?: string
}

export function DownloadButton(props: DownloadButtonProps) {
    props = Object.assign({
        buttonColor: ButtonColors.Edit,
        children: t('Download'),
        icon: faDownload
    }, props)
    return <a download href={props.url}>{Button(props)}</a>;
}

interface DownloadFileButtonProps extends Exclude<ButtonProps, "children">, DownloadFileProps {
    label?: string;
}

export const DownloadFileButton = React.forwardRef<HTMLButtonElement, DownloadFileButtonProps>(
    (props: DownloadFileButtonProps, ref) => {

        const {download, isDownloading} = useDownloadFile({
            url: props.url,
            fileName: props.fileName,
            onDownloadStart: props.onDownloadStart,
            onDownloadComplete: props.onDownloadComplete,
            onDownloadError: props.onDownloadError,
            requestConfig: props.requestConfig
        });

        let buttonProps = Object.assign({
            buttonColor: ButtonColors.Edit,
            children: t('Download'),
            icon: faDownload,
            loading: isDownloading,
            onClick: download,
        }, filterProps(props, ['url', 'fileName', 'onDownloadError', 'onDownloadStart', 'onDownloadComplete', 'requestConfig']));

        return <Button ref={ref} {...buttonProps} />;
    }
)

export function DeleteButton(props: ButtonProps) {
    props = Object.assign({
        buttonColor: ButtonColors.Error,
        children: t('Delete')
    }, props)
    return <Button {...props}/>;
}

export const DeleteIconButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (props: ButtonProps, ref) => {
        props = Object.assign({
            buttonColor: ButtonColors.Error,
            icon: faTrash,
        }, props)
        return <Button ref={ref} {...props}/>;
    }
);

export function LogoutButton(props: ButtonProps) {
    const dispatch = useAppDispatch();
    const {userStatus} = useAppSelector(userSelector);

    function logout() {
        if (userStatus.isLoading || !userStatus.isLoggedIn) return;
        dispatch<any>(logoutUser())
        history.push("/")
    }

    return <Button onClick={logout}>{t('Logout')}</Button>
}

export function LogoutLink(props: React.HTMLAttributes<HTMLParagraphElement>) {
    const dispatch = useAppDispatch();
    const {userStatus} = useAppSelector(userSelector);

    return <p style={{cursor: 'pointer'}} {...props} onClick={logout}>{t('Logout')}</p>

    function logout() {
        if (userStatus.isLoading || !userStatus.isLoggedIn) return;
        dispatch<any>(logoutUser())
        history.push('/')
    }
}

export interface ToggleButtonProps {
    onClick?: React.ChangeEventHandler<HTMLInputElement>,
    checked: boolean,
    readOnly?: boolean,
    loading?: boolean
    explanation?: string,
}

export function ToggleButton(props: ToggleButtonProps) {
    return <label className="content-btn-switch" title={props.explanation}>
        {props.loading && <Spinner size={"small"}/>}
        <input type="checkbox" onChange={props.onClick} readOnly={!props.onClick || props.readOnly || props.loading}
               className="asset-active-checkbox" checked={props.checked}/>
        <span className="content-btn-slider"/>
    </label>
}

export interface ButtonActionIconProps {
    type?: ButtonActionType;
}

export function ButtonActionIcon(props: ButtonActionIconProps) {
    const buttonElements = !props.type ? ButtonActionMap['action'] : ButtonActionMap[props.type];
    
    return (
        <PlattixTooltip title={t(buttonElements.label)}>
            <ButtonActionIconContainer>
                <FontAwesomeIcon icon={buttonElements.icon} />
            </ButtonActionIconContainer>
        </PlattixTooltip>
    );
}

export const InlineButtonDescription = styled('h5')(() => {
    return `
        font-weight: 700;
    `;
});

export const ButtonActionIconContainer = styled('div', React.forwardRef)(() => {
    return `
        color: inherit;
        opacity: 0.3;
    `;
});