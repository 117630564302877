import * as React from 'react';
import {useState} from 'react';
import {PlattixInput, PlattixSubmitButton} from "../../components/form/Input";
import {useForm} from "react-hook-form";
import {UserState} from "PlattixUI/PlattixReactCore/UserSlice";
import {useTranslation} from 'PlattixUI/PlattixReactCore/i18n';
import {Link, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {QueryParamSelector} from "PlattixUI/PlattixReactCore/selectors";
import * as accountApi from "PlattixUI/PlattixReactCore/api/AccountApi";
import {CancelButton, ConfirmButton} from "../../components/Buttons";
import {isHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";
import {PasswordStrengthIndicator} from "../../components/form/Validation";
import {PlattixForm} from "../../components/form/Form";
import {ContentCardButtons} from "../../components/ContentCard";

export function ResetPasswordForm(params: { userState: UserState }) {
    const {t} = useTranslation(['translation']);
    const queryParams = useSelector(QueryParamSelector);
    const [success, setSuccess] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<HttpError | null>(null);
    const hist = useHistory()

    const code = queryParams.get("code");
    const email = queryParams.get("email")

    const form = useForm<accountApi.ResetPasswordModel>({
        defaultValues: {
            code: code || "",
            email: email || "",
        }
    });
    const {register, handleSubmit} = form;

    async function onSubmit(value: accountApi.ResetPasswordModel) {
        console.log(hist)

        setSuccess(false);
        setLoading(true);

        const response = await accountApi.doChangePassword(value);

        if (isHttpError(response)) {
            setError(error)
        } else {
            setSuccess(true);
        }

        setLoading(false);
    }

    if (!code || !email) {
        return (
            <>
                <div className="login-form">
                    <h5 className="login-title">{t('ForgotH4')}</h5>
                    <p>{t('Password.Reset.IvalidCode')}</p>
                    <ContentCardButtons>
                        <Link to={'/'}><CancelButton>{t('Link.To.Login')}</CancelButton></Link>
                    </ContentCardButtons>
                </div>
            </>
        )
    }

    if (success) {
        return (
            <>
                <div className="login-form">
                    <p>{t('ResetConfirmationP')}</p>
                    <ContentCardButtons>
                        <Link to={'/'}><ConfirmButton>{t('Link.To.Login')}</ConfirmButton></Link>
                    </ContentCardButtons>
                    
                </div>
            </>
        )
    }

    return (

        <PlattixForm onSubmit={handleSubmit(onSubmit)}>
            <PlattixInput
                type={"hidden"}
                name="Email"
                register={register("email")}
                placeholder={t('EmailPH')}
                label={t('EmailPH')}
            />
            <PlattixInput
                type={"hidden"}
                name="Code"
                register={register("email")}
                placeholder={t('Code')}
                label={t('Code')}
            />
            <PlattixInput
                type={"password"}
                name="password"
                register={register("password", {required: true})}
                label={t("PasswordPH")}
                placeholder={t('PasswordPH')}
            />
            <PasswordStrengthIndicator password={form.watch("password")} minLength={8}/>
            <PlattixInput
                type={"password"}
                name="confirmPassword"
                register={register("confirmPassword", {required: true})}
                placeholder={t('ConfirmPasswordPH')}
                label={t("ConfirmPasswordPH")}
            />
            <ContentCardButtons>
                <PlattixSubmitButton loading={isLoading} name={"Submit"}/>
            </ContentCardButtons>

        </PlattixForm>
    );

}
