import {useTranslation} from "../../PlattixUI/PlattixReactCore/i18n";
import {useImmer} from "use-immer";
import {DocumentList, DocumentScanDocument} from "./types/Document";
import React, {useEffect, useState} from "react";
import {HttpError} from "../../PlattixUI/PlattixReactCore/CoreTypes";
import {usePlattixQuery} from "../../PlattixUI/PlattixReactCore/api/Api";
import {DocScanEndpoints} from "./DocScanEndpoints";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {PageHeader} from "../../PlattixUI/core/Header";
import {PlattixCardContainer} from "../../PlattixUI/core/components/ContentCard";
import DocumentScanGrid from "./DocumentScanGrid";
import {history} from "../../PlattixUI/PlattixReactCore/store";
import {generatePath} from "react-router";
import {DocumentScanDetailUrl} from "./DocumentScanRoutes";
import {statusCellOCRState, statusCellDocumentState, columnConfig, columnEqualWidth} from "./DocumentScanGridStyling";

//TODO: open in read-only mode? Document is finished so should no longer be allowed to edit?
const goToDocument = (documentId) => {
    return history.push(generatePath(DocumentScanDetailUrl, {documentId: documentId}));
}

function DocumentScanHistory() {
    const {t} = useTranslation();
    const [processedDocuments, setProcessedDocuments] = useImmer<DocumentList | null>(null);
    const [error, setError] = useState<HttpError | null>(null);
    const [loading, setLoading] = useState(false);

    const documentsProcessedQuery = 
        usePlattixQuery<DocumentList>(
            ["state", "ProcessedAll"], 
            DocScanEndpoints.Document(),
            { documentState: 'Processed'},
            { refetchOnWindowFocus: false });

    useEffect(() => {
        if (documentsProcessedQuery.data) {
            setProcessedDocuments(documentsProcessedQuery.data);
        }

        if (documentsProcessedQuery.error) {
            setError(documentsProcessedQuery.error);
        }

        setLoading(documentsProcessedQuery.isLoading);
    }, [documentsProcessedQuery])

    const scannedDocumentsColumns: GridColDef<DocumentScanDocument>[] = [
        {
            field: 'invoiceNumber',
            headerName: t('DocumentScan.InvoiceNumber'),
            ...columnConfig,
            ...columnEqualWidth
        },
        {
            field: 'documentName',
            headerName: t('DocumentScan.DocumentName'),
            ...columnConfig,
            ...columnEqualWidth
        },
        {
            field: 'supplierName',
            headerName: t('DocumentScan.SupplierName'),
            ...columnConfig,
            ...columnEqualWidth
        },
        {
            field: 'invoiceDate',
            headerName: t('DocumentScan.InvoiceDate'),
            ...columnConfig,
            ...columnEqualWidth,
            renderCell: (params: GridRenderCellParams) => (
                <span>
                    {new Date(params.value).toLocaleDateString()}
                </span>
            ),
        },
        {
            field: 'documentStateCode',
            headerName: t('DocumentScan.DocumentStateCode'),
            ...columnConfig,
            ...columnEqualWidth,
            ...statusCellDocumentState
        },
        {
            field: 'ocrStateCode',
            headerName: t('DocumentScan.OCRStateCode'),
            ...columnConfig,
            ...columnEqualWidth,
            ...statusCellOCRState
        }
    ];

    return (
        <div>
            <PageHeader title={t('DocumentScan.HistoryTitle')} description={t('DocumentScan.Description')}/>

            <PlattixCardContainer>
                <DocumentScanGrid
                    className={'content-column-1_-1'}
                    header={t('DocumentScan.OverviewHistoryProcessed')}
                    description={t('DocumentScan.OverviewHistoryProcessed.Description')}
                    isLoading={loading}
                    rows={processedDocuments}
                    columns={scannedDocumentsColumns}
                    onDoubleClickHandler={(params, event) => {
                        if (event.ctrlKey) return;
                        if (params.field === 'options') return;
                        event.defaultMuiPrevented = true;
                        goToDocument(params.id);
                    }}
                />
            </PlattixCardContainer>
        </div>
    );
}

export default DocumentScanHistory;