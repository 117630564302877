import {useTranslation} from "../../PlattixReactCore/i18n";
import {Box, TextField} from "@mui/material";
import * as React from "react";

export function DataGridFilterInput(props) {
    const {t} = useTranslation()
    const {item, applyValue, focusElementRef = null} = props;


    const handleChange = (event) => {
        applyValue({...item, value: event.target.value});
    };

    if (props.operator.numOperands === 0) {
        return <></>
    }

    return (
        <Box
            sx={{
                display: 'inline-flex',
                flexDirection: 'row',
                alignItems: 'end',
                height: 48,
                pl: '20px',
            }}
        >
            <TextField
                name={props.operator.name}
                placeholder={t('value')}
                label={t('value')}
                variant="standard"
                value={item.value ?? ''}
                onChange={handleChange}
                type={props.type}
                inputRef={focusElementRef}
                sx={{mr: 2}}
            />
            {
                props.operator.numOperands >= 2 && (
                    <TextField
                        name={props.operator.name + "_2"}
                        placeholder={t('value')}
                        label={t('value')}
                        variant="standard"
                        value={item.value ?? ''}
                        onChange={handleChange}
                        type={props.type}
                        inputRef={focusElementRef}
                        sx={{mr: 2}}
                    />
                )
            }
        </Box>
    );
}

