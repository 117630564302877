

export type DocumentList = DocumentListItem[];

export type DocumentListItem = {
    documentId: number,
    invoiceNumber: string,
    documentName: string,
    supplierName: string,
    invoiceDate: Date,
    documentStateCode: string,
    documentStateTranslation: string,
    ocrStateCode: string,
    ocrStateTranslation: string,
}


export interface DocumentScanProduct {
    id: number,
    purchaseInvoiceDetailId?: number,
    purchaseInvoiceId?: number,
    orderId?: string,
    productCode?: string,
    productDescription?: string,
    quantity?: number,
    totalBaseVatAmount?: number,
    totalVatAmount?: number,
    vatcode?: number,
    unitPrice?: number,
    totalAmount?: number,
    createDate?: Date,
    createOu?: string,
    createUser?: string,
    createSource?: string,
    updateDate?: Date,
    updateOu?: string,
    updateUser?: string,
    updateSource?: string,
}
export interface DocumentScanned {
    documentImported: DocumentScanDocument,
    documentExpected: DocumentExpectedScanDocument,
}
    export interface DocumentScanDocument {    
    documentId: number,
    documentName: string,
    invoiceNumber: string,
    supplierName: string,
    supplierVat: string,
    invoiceDate?: Date,
    dueDate?: Date,
    orderId: string,
    vat?: string,
    totalBaseVatAmount?: number,
    totalVatAmount?: number,
    totalAmount?: number,
    transportCost?: number,
    currencyCode?: string,
    createDate: Date,
    // createOu?: string,
    // createUser?: string,
    // createSource?: string,
    // updateDate: Date,
    // updateOu?: string,
    // updateUser?: string,
    // updateSource?: string,
    purchaseInvoiceId: number,
    documentDetails: DocumentScanProduct[],
    documentStateCode: string,
    documentStateTranslation: string,
    ocrStateCode: string,
    ocrStateTranslation: string,
    iFrameUrl?: string,
}
export interface DocumentExpectedScanDocument extends DocumentScanDocument {

    orderStartDate?: Date,
    deliveryDate?: Date,
    
}

export const statusDictionary = {
    'DocumentState.Finished': {
        color: 'green',
    },
    'DocumentState.ReadyToImport': {
        color: 'green',
    },
    'DocumentState.AutomaticModified': {
        color: 'orange',
    },
    'DocumentState.Manual': {
        color: 'purple',
    },
    'DocumentState.Automatic': {
        color: 'blue',
    },
    'DocumentState.Created': {
        color: '#9FE2BF',
    },
    'CreateOCR': {
        color: '#9FE2BF',
    },
    'AutomaticOCR': {
        color: 'green',
    },
    'ManualOCR': {
        color: 'blue',
    },
    'ExportedOCR': {
        color: 'green'
    }
}
