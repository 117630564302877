import {RoutersComponent} from "pages/Routers/Routers";
import React from "react";
import {Switch} from "react-router-dom";
import {ScrollToTopOnNavigation} from "PlattixUI/core/Navigation";


export function Router() {
    return (
        <>
            <RoutersComponent/>
            <NotificationsRouter/>
        </>
    );
}

export function NotificationsRouter() {
    return (
        <>
            <Switch>
                {/*<Redirect exact strict from={NotificationsRouteMap.overview} to={NotificationsRouteMap.nextSteps}/>*/}
                
                {/*<Route path={NotificationsRouteMap.nextSteps} component={LandingPageNewUser}/>*/}
            </Switch>
        </>
    );
}