import React, {useEffect} from 'react';
import {SidebarComponent} from './PlattixUI/core/Sidebar';
import {NavigationComponent} from './PlattixUI/core/Navigation';
import {ContentComponent} from './PlattixUI/core/Content';

import './PlattixUI/scss/Index.scss';
import {setup} from "goober";
import {CheckLoginComponent} from "./PlattixUI/core/pages/Account/CheckLoginComponent";
import {userSelector} from "./PlattixUI/PlattixReactCore/UserSlice";
import {useAppDispatch, useAppSelector} from "./PlattixUI/PlattixReactCore/hooks";

import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'

import * as moment from 'moment'
import 'moment/locale/nl-be'
import 'moment/locale/fr'
import 'moment/locale/en-in'
import 'moment/locale/de'
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { setLicenseKeys } from './PlattixUI/core/License';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

// import {advisorySelector} from "./pages/SEA/store/SeaSlice";
// @ts-ignore
library.add(fas)


setup(React.createElement);

const queryClient = new QueryClient();

// Compatibility with react 18 for mui 
declare global {
    namespace React {
        interface DOMAttributes<T> {
            onResize?: ReactEventHandler<T> | undefined;
            onResizeCapture?: ReactEventHandler<T> | undefined;
            nonce?: string | undefined;
        }
    }
}

function App() {    
    const {user, userStatus} = useAppSelector(userSelector)
    // const {advisoryStatus} = useAppSelector(advisorySelector)
    const dispatch = useAppDispatch();
    // license keys componenten ophalen
    setLicenseKeys();
    
    
    useEffect(() => {
        if (user) {
            moment.locale(user.locale)

            
            if (userStatus.isInitialised && !userStatus.isLoading && !userStatus.error){
                // if (!advisoryStatus.isInitialised && !advisoryStatus.isLoading && !advisoryStatus.error){
                //     dispatch<any>(initSelectedServicepoint());
                // }
            }
        }
    }, [user])


    // if (!status.isInitialised && !status.isLoading && !status.error)
    //     dispatch<any>(initSelectedServicepoint());
    
    return (
        <div id="root" className={userStatus.isLoggedIn ? "" : "login-body"}>
            <QueryClientProvider client={queryClient}>
                <CheckLoginComponent>
                    <ContentComponent/>
                    <NavigationComponent/>
                    <SidebarComponent/>
                </CheckLoginComponent>
                <ToastContainer />
            </QueryClientProvider>
        </div>
    );
}


export default App;
