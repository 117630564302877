import React, {useEffect, useState} from 'react';
import {styled} from "goober";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {EditButton} from "./Buttons";
import {Alert, AlertTitle, useMediaQuery} from "@mui/material";
import {AlertColor} from "@mui/material/Alert/Alert";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight, faCaretDown, faCaretRight} from "@fortawesome/free-solid-svg-icons";
import {deviceDimensions} from "PlattixUI/core/components/Responsive";
import {mobileMenustylingSnippets} from '../Navigation';

export function Header(props: React.PropsWithChildren<any>) {
    return (
        <section className="header">
            {props.children}
        </section>
    );
}

/**
 * Action bar at the top of a Page
 * 
 * Actions must be wrapped in {@link ActionsLeft} or {@link ActionsRight}
 * @param props
 */
export function Actions(props: React.PropsWithChildren<any>) {
    
    return (
        <>
            <div className="actions">
                {props.children}
            </div>
        </>
    );
}

export interface MobileActionsProps {
    
}

/**
 * Action bar at the top of a Page
 * 
 * Actions must be wrapped in {@link ActionsLeft} or {@link ActionsRight}
 * @param props
 */
export function MobileActions(props: React.PropsWithChildren<MobileActionsProps>) {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [touchStart, setTouchStart] = useState<number | null>(null)
    const [touchEnd, setTouchEnd] = useState<number | null>(null)

    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);

    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
    const minSwipeDistance = 150;

    const onTouchStart = (e) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX)
    };

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance

        if (!iOS) return;

        if (isLeftSwipe) setMenuOpen(true);
        if (isRightSwipe) setMenuOpen(false);
    };
    
    const openMenuHandler = () => {
        setMenuOpen(!menuOpen);
    };
    
    useEffect(() => {
        console.log(menuOpen)
    }, [menuOpen]);
    
    if (desktop) return (
        <></>
    );
    
    return (
        <>
            <MobileActionsSidebar
                openmenu={menuOpen ? 'true' : undefined}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
            >
                <MobileActionsSidebarButton
                    onClick={openMenuHandler}
                    openmenu={menuOpen ? 'true' : undefined}
                >
                    <FontAwesomeIcon icon={menuOpen ? faAngleRight : faAngleLeft} />
                </MobileActionsSidebarButton>
            </MobileActionsSidebar>
            
            <MobileActionsSidebarContainer
                openmenu={menuOpen ? 'true' : undefined}
            >
                {props.children}
            </MobileActionsSidebarContainer>
        </>
    );
}

/**
 * Left align the content
 * 
 * Content might include Buttons, inputs or text
 * @param props
 * @constructor
 */
export function ActionsLeft(props: React.PropsWithChildren<any>) {
    return (
        <div className="left">
            {props.children}
        </div>
    );
}

/**
 * Right align actions
 * @param props
 * @constructor
 */
export function ActionsRight(props: React.PropsWithChildren<any>) {
    return (
        <div className="right">
            {props.children}
        </div>
    );
}

export interface ActionsItemContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    description?: string;
    direction?: 'row' | 'column';
}

export function ActionsItemContainer(props: React.PropsWithChildren<ActionsItemContainerProps>) {
    return (
        <div className={`item-container ${props.direction ? props.direction : ''}`}>
            {props.description && <h5>{props.description}</h5>}
            <div>
                {props.children}
            </div>
        </div>
    );
}

export const ActionsSeparator = styled('div')(() => {
    return `
        height: 100%;
        border-left: var(--borderBottom1);
    `;
});

export function MessageBar(props: React.PropsWithChildren<any>) {
    return (
        <section className="messages">
            <div className="actions">
                {props.children}
            </div>
        </section>
    );
}

interface PlattixAlertProps {
    title: React.ReactNode,
    description?: React.ReactNode,
    actionTitle?: string,
    onAction?: () => void
    type?: AlertColor,
    onClose?: (event: React.SyntheticEvent) => void
}
export function PlattixAlert(props: PlattixAlertProps){
    return <Alert 
        action={props.onAction ? <EditButton onClick={props.onAction}>{props.actionTitle}</EditButton> : undefined} 
        severity={props.type} 
        onClose={props.onClose}
    >
        <AlertTitle>{props.title}</AlertTitle>
        {props.description}
    </Alert>
}
export function ErrorAlert(props: PlattixAlertProps){
    return <PlattixAlert {...props} type={props.type ?? "error"} />
}
export function WarningAlert(props: PlattixAlertProps){
    return <PlattixAlert {...props} type={props.type ?? "warning"} />
}
export function SuccessAlert(props: PlattixAlertProps){
    return <PlattixAlert {...props} type={props.type ?? "success"} />
}

/// Deprecated: use MUI Alert instead
const Message = styled('div')`
    display: flex;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    
    & > * {
        margin: 0;
    }
    
    h5 {
        width: 100%;
    }
`

/// Deprecated: use MUI Alert instead
export const WarningMessage = styled(Message)`
    background-color: var(--styleColor3);
    display: flex;
    justify-content: space-between;
    align-items: center;
`

/// Deprecated: use MUI Alert instead
export const ErrorMessage = styled(Message)`
    background-color: var(--buttonColorRed);
    display: flex;
    justify-content: space-between;    
    align-items: center;
    margin-bottom: var(--marginBottom3);
    
    &, * {
        color: var(--textColorLight);
    }
`;

type HttpErrorMessageProps = {
    error: HttpError | null | undefined,
    severity?:  AlertColor | undefined,
    includeErrorDetail?: boolean
}
export function HttpErrorMessage(props: HttpErrorMessageProps){
    if (!props.error) return <></>;
    return <Alert severity={ props.severity ?? "error"}>
        <AlertTitle>{props.error?.title}</AlertTitle>
        {!!props.error?.detail && props.error?.detail}
        {
            props.includeErrorDetail && props.error?.errors && !!Object.keys(props.error.errors).length 
            && <>
                <ul>
                    {Object.keys(props.error.errors).map(key => 
                        <li key={key}>
                            <strong>{key}:</strong>
                            <ul>
                                {props.error?.errors[key]?.map(e => <li key={e}>{e}</li>)}
                            </ul>
                        </li>)}
                </ul>
            </>
        }
    </Alert>
}

export function PlattixTabs(props: React.PropsWithChildren<any>) {
    return (
        <Tabs className="tabs">
            {props.children}
        </Tabs>
    );
}

export function PlattixTabList(props: React.PropsWithChildren<any>) {
    return (
        <TabList>
            {props.children}
        </TabList>
    );
}

export function PlattixTab(props: React.PropsWithChildren<any>) {
    return (
        <Tab {...props} className="tab">
            {props.children}
        </Tab>
    );
}

export function PlattixTabPanel(props: React.PropsWithChildren<any>) {
    return (
        <TabPanel {...props}>
            {props.children}
        </TabPanel>
    );
}

export const MobileActionsstylingSnippets = {
    menuWidth: `60vw`,
    menuButtonWidth: `30px`,
};

export interface MobileActionsSidebarProps {
    openmenu?: string;
}

export const MobileActionsSidebarContainer = styled('div')((props: MobileActionsSidebarProps) => {
    return `
        width: ${MobileActionsstylingSnippets.menuWidth};
        padding: 50px 10px;
        height: 100vh;
        position: fixed;
        top: 0;
        right: ${props.openmenu ? '0vw' : '-' + MobileActionsstylingSnippets.menuWidth};
        z-index: 99;
        background: var(--backgroundColor1);
        box-shadow: var(--shadow2);
        
        display: flex;
        flex-flow: column-reverse nowrap;
        gap: 10px;
        
        ${mobileMenustylingSnippets.transition}
    `;
});

export const MobileActionsSidebar = styled('div')((props: MobileActionsSidebarProps) => {
    return `
        position: fixed;
        right: 0;
        top: 0;
        z-index: 100;
        width: ${props.openmenu ? MobileActionsstylingSnippets.menuWidth : '50px'};
        height: 100vh;
        cursor: pointer;
        
        ${mobileMenustylingSnippets.transition}
    `;
});

export const MobileActionsSidebarButton = styled('div')((props: MobileActionsSidebarProps) => {
    return `
        position: absolute;
        right: ${props.openmenu ? MobileActionsstylingSnippets.menuWidth : '0'};
        top: 60%;
        width: 30px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px 0 0 100px;
        font-size: 20px;
        color: #f5f5f5;
        background: var(--styleColor1);
        box-shadow: var(--shadow2);
        cursor: pointer;
        
        ${mobileMenustylingSnippets.transition}
    `;
});

export interface FormErrorMessageProps {
    title: string;
    detail?: string;
}

export function FormErrorMessage(props: FormErrorMessageProps) {
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const clickable = !!props.detail;
    
    return (
        <FormErrorMessageContainer>
            <div className={'title'} onClick={() => setShowDetails(!showDetails)}>
                {clickable &&
                    <FontAwesomeIcon icon={showDetails ? faCaretDown : faCaretRight} />
                }
                <b className={`${clickable ? 'clickable' : ''}`}>{props.title}</b>
            </div>
            
            {(clickable && showDetails) &&
                <>
                    <hr />
                    <p>{props.detail}</p>
                </>
            }
        </FormErrorMessageContainer>
    );
}

export const FormErrorMessageContainer = styled('div', React.forwardRef)(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;
        padding: 10px;
        border-radius: 10px;
        background-color: var(--buttonColorRed);
        margin-bottom: var(--marginBottom3);
        
        h5 {
            width: 100%;
        }
        
        hr {
            border-color: var(--textColorLight);
        }
        
        .title {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 10px;
            user-select: none;
            cursor: pointer;
        }
        
        & > * {
            margin: 0;
        }
        
        &, * {
            color: var(--textColorLight);
        }
    `;
});