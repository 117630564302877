import styled from "@emotion/styled";
import {css} from "goober";

export const CardOverlay = styled('div')`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    backdrop-filter: blur(1px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--marginSides1);
    border-radius: 15px;
    
    * {
        color: white;
    }
`;

export interface ShowcaseCardProps {
    fillHeight?: boolean,
}

export const ShowcaseCard = styled('div')((props: ShowcaseCardProps) => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        overflow: hidden;
        ${props.fillHeight ? 'height: auto;' : ''}
`});

export interface ShowcaseCardHeaderProps {
    imgFit?: 'cover' | 'fill' | 'contain' | 'scale-down' | 'none',
    imgHeight?: string;
}

export const ShowcaseCardHeader = styled('div')((props: ShowcaseCardHeaderProps) => {
    return `
        width: 100%;
        height: ${props.imgHeight ? props.imgHeight : '100px'};;
        display: flex;
        background: #f5f5f5;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        
        &, img {
            user-select: none;
        }
        
        img {
            width: 100%;
            height: 100%;
            object-fit: ${props.imgFit ? props.imgFit : 'cover'};
            object-position: center;
        }
        
        .logo {
            position: absolute;
            max-height: 50%;
            object-fit: contain;
            width: fit-content;
            bottom: 0;
            right: 0;
            padding: 10px;
            background: rgba(255, 255, 255, 0.7);
            border-top-left-radius: 10px;
        }
`});

export const ShowcaseCardBody = styled('div')((props) => {
    return `
        padding: var(--padding2);
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;
        
        h3 {
            font-size: 1.3em;
        }
`});

export const PlattixShowcaseCardDataListContainer = styled('div')((props) => {
    return `
        padding: var(--padding2);
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;
        
        h3 {
            font-size: 1.3em;
        }
`});

export const ShowcaseCardButtons = css`
    margin-top: auto;
`;

export interface ShowcaseCardSpacerProps {
    spacing?: 'normal' | 'big' | 'small' | 'none',
}

export const ShowcaseCardSpacer = styled('div')((props: ShowcaseCardSpacerProps) => {
    const spacingMap = {
        'normal': '15px',
        'big': '25px',
        'small': '5px',
        'none': '0',
    };
    
    const spacing = props.spacing ? spacingMap[props.spacing] : spacingMap['normal'];
    
    return `
        width: 100%;
        border-bottom: var(--borderBottom1);
        margin: ${spacing} 0;
`});

export const PlattixSectionContainer = styled('div')(() => {
    return `
        width: 100%;
        grid-column: 1/-1;
        display: flex;
        flex-flow: column nowrap;
        gap: 15px;
    `;
});

export interface PlattixSectionStylingProps {
    open?: string;
    focused?: string;
    focusmode?: string;
    hiddenbutrendered?: string;
}

export const PlattixSectionHeader = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
    `;
});

export const PlattixSectionHeaderHeadline = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;
    `;
});

export const PlattixSectionHeaderText = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        position: relative;
        top: -2px;
        
        h5 {
            user-select: none;
            cursor: pointer;
        }
    `;
});

export const PlattixSectionHeaderCollapsible = styled('div')(() => {
    return `
        min-width: 12px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        cursor: pointer;
    `;
});

export const PlattixSectionHeaderButtonsAlignment = css`
    align-items: flex-start !important;
    
    &, * {
        justify-content: flex-start !important;
    }
`;

interface PlattixSectionMenuProps {
    anonymous?: string;
}

export const PlattixSectionMenu = styled('div')((props: PlattixSectionMenuProps) => {
    return `
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 10px;
        position: sticky;
        top: 10px;
        right: 10px;
        z-index: 100;
        background: rgba(255, 255, 255, 0.8);
        padding: 10px;
        border-radius: 10px;
        box-shadow: var(--shadow2);
        backdrop-filter: blur(10px);
        
        .alignRight {
            margin-left: auto;
        }
    `;
    // return `
    //     width: calc(100% ${!props.anonymous ? '- 250px' : ''} - 50px - 10px - 10px);
    //     display: flex;
    //     flex-flow: row nowrap;
    //     align-items: center;
    //     gap: 10px;
    //     position: fixed;
    //     bottom: 10px;
    //     right: 10px;
    //     z-index: 100;
    //     background: #FFFFFF;
    //     padding: 10px;
    //     border-radius: 10px;
    //     box-shadow: var(--shadow2);
    //    
    //     .alignRight {
    //         margin-left: auto;
    //     }
    // `;
});

export const PlattixSectionMenuItem = styled('div')((props: PlattixSectionStylingProps) => {
    return `
        width: fit-content;
        padding: var(--padding1);
        cursor: pointer;
        border-radius: 10px;
        background: ${!!props.focused ? 'var(--styleColor1)' : '#F5F5F5'};
        
        p {
            ${!!props.focused ? 'color: #FFF;' : ''}
        }
        
        
        &:hover {
            background: #EEE;
            
            p {
                ${!!props.focused ? 'color: unset;' : ''}
            }
        }
        
        &:active {
            background: #DDD;
        }
    `;
});

export const PlattixSectionMenuItemText = styled('p')(() => {
    return `
        width: 100%;
        user-select: none;
    `;
});