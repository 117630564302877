import React, {ChangeEvent, InputHTMLAttributes} from "react";
import {UseFormReturn} from "react-hook-form";
import {filterProps} from "PlattixUI/util/ElementProperties";
import {toPrecision} from "PlattixUI/util/numberUtil";

export interface PercentPlattixInputProps extends InputHTMLAttributes<HTMLInputElement> {
    formHook: UseFormReturn,
    name: string
}

export function PercentPlattixInput(props: PercentPlattixInputProps){
        
    const value = props.formHook.watch(props.name) * 100
    
    const step = props.step ? (
        ( typeof props.step === 'string' ? parseFloat(props.step) : props.step) * 100
        ) : 1
    
    function handleChange(event: ChangeEvent<HTMLInputElement>){
        const newValue = parseFloat(event.target.value) / 100
        event.target.value = newValue.toString()
        props.onChange?.(event)
        
        props.formHook.setValue(props.name, newValue, {shouldDirty: true, shouldTouch: true, shouldValidate:true});
    }
    
    return <input
        {...filterProps(props, ['formHook', 'name'])}
        type={"number"}
        step={step}
        className="form-control"
        onChange={handleChange}
        value={toPrecision(value, step)}
    />
}