import React, {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {doGet, throwOnHttpError} from "../../PlattixReactCore/api/Api";
import {ThumborImageProps} from "./types";
import {ThumborImage} from "./image";
import {filterProps as filterPropsDefault} from "../ElementProperties";

export interface PlattixImageProps extends Omit<ThumborImageProps, "src"> {
    fileKey?: string;
    /**
     * @description - Optional - The ID and the name of the image.
     *
     * @example - '86277122303c406ca621b8fcee8d02e4/Product-Placeholder-Picture2_Tekengebied 1 kopie.png'
     */
    fileSource?: string;
    server?: string;
    onClick?: (e?: any) => any;
}

export const baseMediaServerUrl = `https://thumbor.energytix.cloud`;

export function PlattixImage(props: PlattixImageProps) {
    const server = props.server ?? baseMediaServerUrl;
    const [initialized, setInitialized] = useState(false);
    const img = useQuery<string>(['getImage', props.fileKey],
        () => throwOnHttpError(
            doGet<string>(
            '/Media/GetMediaSource',
            {
                fileKey: props.fileKey,
            }
        )), {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            cacheTime: 60 * 60 * 1000, // 1 hour
            staleTime: 60_000, // 1 minute
            enabled: !!props.fileKey
        }
    )
    
    useEffect(() => {
        if (!initialized && img.data) {
            setInitialized(true)
        }
    }, [img.data, initialized])

    return (
        <>
            {!props.fileSource && <ThumborImage
                server={server}
                src={img.data ?? ""}
                {...filterPropsDefault(props, ['server','fileKey'])}
            /> }
            {props.fileSource &&
                <ThumborImage
                    server={server}
                    src={encodeURI(props.fileSource)}
                    {...filterPropsDefault(props, ['server','fileKey'])}
                    // alt={'sdfsdf'}
                />
            }
        </>
    )
}

export const getThumborUrl = (path: string, size?: {x?: number, y?: number}, filters?: string, encode?: boolean) => {
    if (!path) return;
    
    const imageFilters = filters ? `filter:${filters}/` : '';
    const mediaSize = size ? `/unsafe/${size.x ?? '0'}x${size.y ?? '0'}/${filters}` : `/image/${filters}`;
    const rawUrl = `${baseMediaServerUrl}${mediaSize}${path}`;
    const url = encode ? encodeURI(rawUrl) : rawUrl;
    
    return url;
};