import React, {useEffect, useMemo, useState} from "react";
import {
    DocumentExpectedScanDocument,
    DocumentScanDocument,
    DocumentScanned,
    DocumentScanProduct
} from "./types/Document";
import {HttpError} from "../../PlattixUI/PlattixReactCore/CoreTypes";
import {useParams} from "react-router";
import {usePlattixQuery} from "../../PlattixUI/PlattixReactCore/api/Api";
import {DocScanEndpoints} from "./DocScanEndpoints";
import {PageHeader} from "../../PlattixUI/core/Header";
import {t} from "../../PlattixUI/PlattixReactCore/i18n";
import {Actions, ActionsLeft, ActionsRight, ErrorMessage, MessageBar} from "../../PlattixUI/core/components/ActionBar";
import {CancelButton, ConfirmButton} from "../../PlattixUI/core/components/Buttons";
import {history} from "../../PlattixUI/PlattixReactCore/store";
import {PlattixCard, PlattixCardContainer} from "../../PlattixUI/core/components/ContentCard";
import IframeResizer from "iframe-resizer-react";
import {Spinner} from "../../PlattixUI/core/components/Loader";
import {PlattixSubmitField} from "../../PlattixUI/core/components/form/Form";
import {Required} from "../../PlattixUI/core/forms/FormValidators";
import {FormGridColDef} from "./types/FormColDef";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {toFixedDecimal} from "../../PlattixUI/util/numberUtil";
import {toast} from "react-toastify";
import DocumentScanDetailCard from "./DocumentScanDetailCard";
import {DocumentScanUrl} from "./DocumentScanRoutes";
import {columnConfig, editColumnConfig} from "./DocumentScanGridStyling";
import {signalRConnection} from "../../configuration/SignalRHubs";
import {HubConnectionState} from "@microsoft/signalr";
import {ToastContent} from "../../PlattixUI/core/components/ToastContent";

function DocumentScanDetail() {
    const [error, setError] = useState<HttpError | null>(null);        

    let {documentId} = useParams<{ documentId: string }>();

    const loadDocumentQuery = 
        usePlattixQuery<DocumentScanned>(
            ['documentId', documentId], 
            DocScanEndpoints.Document(documentId), 
            undefined, 
            { refetchOnWindowFocus: false });
    
    const processedDocumentFields = useMemo<PlattixSubmitField<DocumentScanDocument>[]>(() => [
        {
            name: "documentId",
            label: '',
            type: 'hidden',
            validation: [Required(),]
        }, {
            name: "documentName",
            label: 'documentName',
            validation: [Required(),]
        }, {
            name: "invoiceNumber",
            label: 'invoicenumber',
            validation: [Required(),]
        }, {
            name: "supplierName",
            label: 'SupplierName',
            validation: [Required(),]
        }, {
            name: "supplierVat",
            label: 'SupplierVAT',
            validation: [Required(),]
        }, {
            name: "invoiceDate",
            label: 'invoicedate',
            type: "datetime-local",
            validation: [Required(),]
        }, {
            name: "dueDate",
            label: 'duedate',
            type: "datetime-local",
        },
        {
            name: "orderId",
            label: 'orderId',
            validation: [Required(),]
        }, {
            name: "vat",
            label: 'VATCode',
        }, {
            name: "totalBaseVatAmount",
            label: 'TotalBaseVATAmount',
            type: "number-format",
            numberFormatOptions: {
                decimalScale: 2,
                prefix: '€'
            }
        }, {
            name: "totalVatAmount",
            label: 'TotalVATAmount',
            type: "number-format",
            numberFormatOptions: {
                decimalScale: 2,
                prefix: '€'
            }
        }, {
            name: "totalAmount",
            label: 'totalAmount',
            type: "number-format",
            numberFormatOptions: {
                decimalScale: 2,
                prefix: '€'
            }
        }, {
            name: "currencyCode",
            label: 'currencyCode',
        }, {
            name: "createDate",
            label: 'createDate',
            type: 'hidden',
            // readonly: true,
        }, {
            name: "purchaseInvoiceId",
            label: 'purchaseInvoiceId',
            type: "hidden",
            validation: [Required(),]
        }, {
            name: "transportCost",
            label: 'transportCost',
            type: "number-format",
            numberFormatOptions: {
                decimalScale: 2,
                prefix: '€'
            }
        },  {
            name: "documentStateTranslation",
            label: 'documentStateTranslation',
        }
    ],[]);

    const processedDocumentGridColumns: FormGridColDef<DocumentScanProduct>[] = [
        {
            field: 'orderId',
            headerName: 'orderId',
            ...editColumnConfig,
            ...columnConfig
        },
        {
            field: 'productCode',
            headerName: t('Product.ProductCode'),
            ...editColumnConfig,
            ...columnConfig
        },
        {
            field: 'productDescription',
            headerName: t('Product.ProductDescription'),
            ...editColumnConfig,
            // ...columnEqualWidth,
            ...columnConfig
        },
        {
            field: 'quantity',
            headerName: t('Product.Quantity'),
            type: 'number',
            ...editColumnConfig,
            ...columnConfig
        },
        {
            field: 'totalBaseVatAmount',
            headerName: t('Product.TotalBaseVatamount'),
            type: 'number',
            ...editColumnConfig,
            ...columnConfig
        },
        {
            field: 'totalVatAmount',
            headerName: t('Product.TotalVatamount'),
            type: 'number',
            ...editColumnConfig,
            ...columnConfig
        },
        {
            field: 'vatcode',
            headerName: t('Product.Vatcode'),
            type: 'number',
            ...editColumnConfig,
            ...columnConfig
        },
        {
            field: 'unitPrice',
            headerName: t('Product.UnitPrice'),
            type: 'number',
            renderCell: (params: GridRenderCellParams<number>) => (
                toFixedDecimal(params.value, 4)

            ),
            ...editColumnConfig,
            ...columnConfig
        },
        {
            field: 'totalAmount',
            headerName: t('Product.TotalAmount'),
            renderCell: (params: GridRenderCellParams<number>) => (
                toFixedDecimal(params.value, 2)
            ),
            type: 'number',
            ...editColumnConfig,
            ...columnConfig,

        },
    ];

    const expectedDocumentFields: PlattixSubmitField<DocumentExpectedScanDocument>[] = [
        {
            name: "documentId",
            label: 'documentId',
            type: 'hidden',
            validation: [Required(),]
        },
        {
            name: "documentName",
            label: 'documentName',
            validation: [Required(),]
        }, {
            name: "invoiceNumber",
            label: 'invoicenumber',
            validation: [Required(),]
        },
        {
            name: "supplierName",
            label: 'SupplierName',
            validation: [Required(),]
        }, {
            name: "supplierVat",
            label: 'SupplierVAT',
            validation: [Required(),]
        }, {
            name: "orderStartDate",
            label: 'OrderStartDate',
            type: "datetime-local",
            validation: [Required(),]
        }, {
            name: "deliveryDate",
            label: 'DeliveryDate',
            type: "datetime-local",
        },
        {
            name: "orderId",
            label: 'orderId',
            validation: [Required(),]
        }
        , {
            name: "vat",
            label: 'VATCode',
        }, {
            name: "totalBaseVatAmount",
            label: 'TotalBaseVATAmount',
            type: "number-format",
            numberFormatOptions: {
                decimalScale: 2,
                prefix: '€'
            }
        }, {
            name: "totalVatAmount",
            label: 'TotalVATAmount',
            type: "number-format",
            numberFormatOptions: {
                decimalScale: 2,
                prefix: '€'
            }
        }, {
            name: "totalAmount",
            label: 'totalAmount',
            type: "number-format",
            numberFormatOptions: {
                decimalScale: 2,
                prefix: '€'
            }
        },{
            name: "currencyCode",
            label: 'currencyCode',
        }, {
            name: "createDate",
            label: 'createDate',
            type: 'hidden',
            readOnly: true,
        }, {
            name: "purchaseInvoiceId",
            label: 'purchaseInvoiceId',
            type: "hidden",
            validation: [Required(),]
        } ,{
            name: "transportCost",
            label: 'transportCost',
            type: "number-format",
            numberFormatOptions: {
                decimalScale: 2,
                prefix: '€'
            }
        }, {
            name: "documentStateTranslation",
            label: ' - ',
        }
    ]

    const expectedDocumentGridColumns: FormGridColDef<DocumentScanProduct>[] = [
        {
            field: 'orderId',
            headerName: 'orderId',
            ...editColumnConfig,
            ...columnConfig
        },
        {
            field: 'productCode',
            headerName: t('Product.ProductCode'),
            ...editColumnConfig,
            ...columnConfig
        },
        {
            field: 'productDescription',
            headerName: t('Product.ProductDescription'),
            ...editColumnConfig,
            // ...columnEqualWidth,
            ...columnConfig
        },
        {
            field: 'quantity',
            headerName: t('Product.Quantity'),
            type: 'number',
            ...editColumnConfig,
            ...columnConfig
        },
        {
            field: 'totalBaseVatAmount',
            headerName: t('Product.TotalBaseVatamount'),
            renderCell: (params: GridRenderCellParams<number>) => (
                toFixedDecimal(params.value, 2)

            ),
            ...editColumnConfig,
            ...columnConfig
        },
        {
            field: 'totalVatAmount',
            headerName: t('Product.TotalVatamount'),
            renderCell: (params: GridRenderCellParams<number>) => (
                toFixedDecimal(params.value, 2)

            ),
            ...editColumnConfig,
            ...columnConfig
        },
        {
            field: 'vatcode',
            headerName: t('Product.Vatcode'),
            ...editColumnConfig,
            ...columnConfig
        },
        {
            field: 'unitPrice',
            headerName: t('Product.UnitPrice'),
            renderCell: (params: GridRenderCellParams<number>) => (
                toFixedDecimal(params.value, 4)

            ),
            ...editColumnConfig,
            ...columnConfig
        },
        {
            field: 'totalAmount',
            headerName: t('Product.TotalAmount'),
            renderCell: (params: GridRenderCellParams<number>) => (
                toFixedDecimal(params.value, 2)

            ),
            type: 'number',
            ...editColumnConfig,
            ...columnConfig,

        },
    ];
    
    const scannedDocument = loadDocumentQuery.data?.documentImported;
    const expectedDocument = loadDocumentQuery.data?.documentExpected;

    const ShowChangesConfirmed = (documentId, newState) => {
        if (documentId === scannedDocument?.documentId && newState === 'confirmed') {            
            toast.success(<ToastContent title={t('DocScan.Message.RossumConfirmed')} />)
        }
    };
        
    if (signalRConnection.state === HubConnectionState.Disconnected) {
        signalRConnection.start()
            .then(() => signalRConnection.invoke("joinGroup", ("documentScan"))
                .catch((err) => console.error(err)));
    }
    
    useEffect(() => {
        signalRConnection.on("stateChange", ShowChangesConfirmed);
                
        return function cleanup() {
            signalRConnection.off("stateChange", ShowChangesConfirmed);           
        }
    });

    return (
        <div>
            <PageHeader title={t('DocumentScanDetail.Title')} description={t('DocumentScanDetail.Description')}/>
            <Actions>
                <ActionsLeft>
                    <CancelButton
                        onClick={() => history.push(DocumentScanUrl)}>{t('BackToOverview')}</CancelButton>
                </ActionsLeft>
                <ActionsRight />
            </Actions>
            {
                loadDocumentQuery.isError && <MessageBar>
                    <ErrorMessage>
                        <h4>{t('DocScan.Document.Load.Fail')}</h4>
                        <ConfirmButton onClick={() => setError(null)}>{t('Retry')}</ConfirmButton>
                    </ErrorMessage>
                </MessageBar>
            }

            {!error &&
                <PlattixCardContainer>
                    {
                        scannedDocument && expectedDocument && !loadDocumentQuery.isFetching && <>
                            <DocumentScanDetailCard
                                readonly={false}
                                data={scannedDocument}
                                fields={processedDocumentFields}
                                gridColumns={processedDocumentGridColumns}
                                postUrl={DocScanEndpoints.Document(documentId)}
                                onSuccess={() => toast.success(t('ChangesSaved'))}
                                header={t('DocumentScan.ProcessedData')}
                                formTitle={t('DocumentScan.ProcessedData.QualityData.Title')}
                                formDescription={t('DocumentScan.ProcessedData.QualityData.Description')}
                                documentDetailsTitle={t('DocumentScan.ProcessedData.QuantityData.Title')}
                                documentDetailsDescription={t('DocumentScan.ProcessedData.QuantityData.Description')}
                            />
        
                            <DocumentScanDetailCard
                                readonly={true}
                                data={expectedDocument}
                                fields={expectedDocumentFields}
                                gridColumns={expectedDocumentGridColumns}
                                header={t('DocumentScan.ExpectedData')}
                                formTitle={t('DocumentScan.ExpectedData.QualityData.Title')}
                                formDescription={t('DocumentScan.ExpectedData.QualityData.Description')}
                                documentDetailsTitle={t('DocumentScan.ExpectedData.QuantityData.Title')}
                                documentDetailsDescription={t('DocumentScan.ExpectedData.QuantityData.Description')}
                            />
                        
                            <PlattixCard header={t('DocumentScan.Scan')} collapsible className={'content-column-1_-1'}>        
                                <IframeResizer
                                    src={scannedDocument.iFrameUrl}
                                    id={`Rossum`}
                                    name={'Rossum'}
                                    heightCalculationMethod="bodyOffset"
                                    autoResize={true}
                                    scrolling={true}
                                    frameBorder="0"
                                    className="iframe-docScan"
                                    inPageLinks={true}
                                    checkOrigin={false}
                                />        
                            </PlattixCard>
                        </>
                    }
                    {
                        (!scannedDocument || loadDocumentQuery.isFetching) && <Spinner/>
                    }
                </PlattixCardContainer>                        
            }
        </div>
    );
}

export default DocumentScanDetail;