import {store} from "PlattixUI/PlattixReactCore/store";

export const Dutch: Language = {
    code: "nl",
    translationCode: "Language.Dutch",
    name: "Nederlands",
    locale: "nl-be",
    thousandSeparator: ",",
    decimalSeparator: "."
}
export const English: Language = {
    code: "en",
    translationCode: "Language.English",
    name: "English",
    locale: "en",
    thousandSeparator: ".",
    decimalSeparator: ","
}
export const French: Language = {
    code: "fr",
    translationCode: "Language.French",
    name: "Français",
    locale: "fr",
    thousandSeparator: ",",
    decimalSeparator: "."
}
export const German: Language = {
    code: "de",
    translationCode: "Language.German",
    name: "Deutsch",
    locale: "de",
    thousandSeparator: ",",
    decimalSeparator: "."
}

export type Language = {
    code: SupportedLanguageCode,
    translationCode: string
    name: string,
    locale: string,
    thousandSeparator: string,
    decimalSeparator: string,
}

export const SupportedLanguages: Language[] = [Dutch, French, German, English]
export type SupportedLanguageCode = 'nl' | 'fr' | 'de' | 'en'

export function getActiveLanguage(){
    const userLang = store.getState()?.user?.language.toLowerCase() ?? 'nl'
    return SupportedLanguages.find(lang => lang.code === userLang)
}

export function getThousandSeparator(){
    return getActiveLanguage()?.thousandSeparator
}

export function getDecimalSeparator(){
    return getActiveLanguage()?.decimalSeparator
}